import React, { useState } from 'react';
import EmailEditor from '../EmailEditor';
import { Button, Form, Input, Select, Typography, Divider, message } from 'antd';
import ExclamationTooltip from '../../ExclamationTooltip';

const MailPromo = () => {
  const [remise, setRemise] = useState(5);

  const handleRemiseChange = (event) => {
    setRemise(event.target.value);
  };
  const handleSave = ()=>{
    
  }

  return (
    <div style={{ marginTop: '10%',}}>
      <h3>Faites la promotion de votre produit</h3>
      <EmailEditor variables={['nom_du_client',]} />
        <div style={{display:'flex', flexDirection:'row'}} >
            Remise : <input
            type='number'
            value={remise}
            onChange={handleRemiseChange}
            placeholder="Saisissez le temps d'attente en remise..."
            style={{ padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px', width:'10%' }}
          /> %
          <div style={{marginLeft:'2%'}}>
            <ExclamationTooltip text={"Ajouter une remise ainsi lors de la commande"} />
          </div>
        </div>
      <div>
      <button onClick={()=>handleSave()}>Enregistrer</button>
      </div>
    </div>
  );
};

export default MailPromo;
