import React, { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SliderImage = styled(motion.img)`
  width: 100%;
  height: auto;
  max-height: 400px;
  position: absolute;
`;

const Button = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  padding: 1rem;
  cursor: pointer;
  z-index: 2;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
  }
`;

const PrevButton = styled(Button)`
  left: 10px;
`;

const NextButton = styled(Button)`
  right: 10px;
`;

const images = [
  'https://via.placeholder.com/800x400/FF0000/FFFFFF?text=Slide+1',
  'https://via.placeholder.com/800x400/00FF00/FFFFFF?text=Slide+2',
  'https://via.placeholder.com/800x400/0000FF/FFFFFF?text=Slide+3',
  'https://via.placeholder.com/800x400/FFFF00/FFFFFF?text=Slide+4',
];

const ImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const intervalRef = useRef(null);

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const paginate = (newDirection) => {
    setDirection(newDirection);
    setCurrentIndex((prevIndex) => (prevIndex + newDirection + images.length) % images.length);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      paginate(1);
    }, 3000);

    return () => clearInterval(intervalRef.current);
  }, []);

  return (
    <SliderContainer>
      <AnimatePresence initial={false} custom={direction}>
        <SliderImage
          key={currentIndex}
          src={images[currentIndex]}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
        />
      </AnimatePresence>
      <PrevButton onClick={() => paginate(-1)}>◀</PrevButton>
      <NextButton onClick={() => paginate(1)}>▶</NextButton>
    </SliderContainer>
  );
};

export default ImageSlider;
