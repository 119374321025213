import React, { useState } from 'react';
import './Register.css';
import { IconButton, InputAdornment, TextField, CircularProgress, Backdrop } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import client from '../../api/client';
import { accessCode, generateId } from '../../actions/generateId';
import socket from '../../api/socket';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { countryCodes } from '../../Data/Data';
const Register = () => {
  const navigate = useNavigate();
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [phone_number, setphone_number] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [countryCode, setCountryCode] = useState(''); // New state for country code
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateForm = () => {
    let isValid = true;
    let error = '';

     if (lastname.length === 0 || lastname.length < 3) {
      isValid = false;
      error = 'Le nom invalide.';
    }
    else if (firstname.length === 0 || firstname.length <= 3) {
      isValid = false;
      error = 'Le prénom invalide.';
    }  else if (!/^0[1-9]\d{8}$/.test(phone_number)) {
      isValid = false;
      error = 'Numéro de téléphone invalide.';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      isValid = false;
      error = 'L\'email doit être conforme.';
    } else if (password.length < 6) {
      isValid = false;
      error = 'Le mot de passe doit avoir au moins 6 caractères.';
    } else if (!acceptTerms) {
      isValid = false;
      error = 'Veuillez accepter les conditions d\'utilisation.';
    }

    if (!isValid) {
      setErrorMessage(error);
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }

    return isValid;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); // Afficher la barre de chargement
      const identifier = generateId();
      const access_code = accessCode();
      console.log('acces code: ',access_code)
      try {
        await client
          .post('/create-manager', { lastname, firstname, email, phone_number: countryCode+''+ phone_number , password, identifier, access_code })
          .then(async (res) => {
            if (res.data.success) {
              console.log("données ",res.data.manager)
              localStorage.setItem("token", JSON.stringify(res.data.token))
              localStorage.setItem("manager", JSON.stringify(res.data.manager))
              localStorage.setItem("newUser", "true")
              socket.connect()
              console.log(res.data);
              navigate('/all-components', {socket, token:res.data.token, navigate});
            }else{
              setErrorMessage(res.data.error);
              setTimeout(() => {
                setErrorMessage('');
              
              }, 3000);
            }
          });        
      } catch (error) {
        
      }finally {
        setLoading(false); // Cacher la barre de chargement
      }

    }
  };

  return (
    <form className="glass-form-container" style={{ maxHeight: '80vh', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center',  boxShadow: '0 4px 8px 0 rgba(36, 45, 73, 0.2), 0 6px 20px 0 rgba(36, 45, 73, 0.5)', // Box shadow modifié pour utiliser la couleur #17174F
    borderRadius: '50px',
    overflowY:'auto',
     }}>
      <div style={{ fontSize: 30, fontWeight: 800,  marginTop: '5%',color:"#17174F",  }}>Inscription</div>
      <div style={{ height: '70vh', width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom:'5%' }}>
  <div style={{marginTop: '10%',}} >{errorMessage && (
    <div style={{
      color: 'red', // Change la couleur du texte en rouge
      textAlign: 'center', // Centre le texte horizontalement
      width: '100%', // Assure que la div prend toute la largeur pour permettre un centrage correct
    }}>
      {errorMessage}
    </div>
  )}</div>
    <TextField
      id="standard-multiline-flexible"
      label="Prénom(s)"
    
      sx={{ marginBottom: '20px', width: '100%','& label.Mui-focused': {
      color: '#17174F',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#17174F',
      },
    }, }}
      value={firstname}
      onChange={(e) => setfirstname(e.target.value)}
    />
    <TextField
      id="standard-multiline-flexible"
      label="Nom"
    
      sx={{ marginBottom: '20px', width: '100%','& label.Mui-focused': {
      color: '#17174F',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#17174F',
      },
    }, }}
      value={lastname}
      onChange={(e) => setlastname(e.target.value)}
    />
  <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '20px' }}>
    <Select
      label="Country Code"
      value={countryCode}
      onChange={(e) => setCountryCode(e.target.value)}
      sx={{ fontSize: '0.50rem', color: 'black', marginRight: '1px', width: '27%','& label.Mui-focused': {
      color: '#17174F',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#17174F',
      },
    }, }}
    >
     {countryCodes.map(country => (
          <MenuItem key={country.code} value={country.code} sx={{ fontSize: '0.75rem', color: 'black' }}>
            {`${country.code} (${country.name})`}
          </MenuItem>
        ))}
    </Select>
    <TextField
      id="standard-multiline-flexible"
      label="Numéro de téléphone"
      sx={{ marginLeft: '20px', flex: 1,'& label.Mui-focused': {
      color: '#17174F',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#17174F',
      },
    }, }}  // Utilisation de flex pour ajuster la largeur
      value={phone_number}
      onChange={(e) => setphone_number(e.target.value)}
    />
  </div>
  <TextField
    id="standard-multiline-flexible"
    label="E-mail"  
    sx={{ marginBottom: '20px', width: '100%', '& label.Mui-focused': {
      color: '#17174F',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#17174F',
      },
    }, }}
    value={email}
    onChange={(e) => setEmail(e.target.value)}
  />
    <TextField
  id="standard-multiline-flexible"
  label="Mot de passe"
  type={showPassword ? 'text' : 'password'}
  sx={{ marginBottom: '20px', width: '100%','& label.Mui-focused': {
      color: '#17174F',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#17174F',
      },
    },  }}
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  }}
/>
<div style={{ padding: '20px', paddingLeft: '40px', width: '100%' }}>
  <FormControlLabel
    control={
      <Checkbox
        defaultChecked={acceptTerms}
        onChange={(e) => setAcceptTerms(e.target.checked)}
        sx={{
          color: '#17174F', // Couleur par défaut (non cochée)
          '&.Mui-checked': {
            color: '#17174F', // Couleur lorsque le Checkbox est coché
          },
        }}
      />
    }
    label="J'accepte les conditions d'utilisation"
    required
  />
</div>

      <Button
      onClick={handleSubmit}
      sx={{
        marginBottom:'5%',
        background: "#17174F",
        ":hover": { backgroundColor: "#1E90FF" },
        alignSelf: 'center',  // Centre le bouton verticalement dans le conteneur
      }}
      size="small"
      variant="contained"
      disableElevation
    >
      S'inscrire
    </Button>

      </div>
      <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default Register;
