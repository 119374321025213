import React, { useState } from "react";
import "./Card.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { motion, AnimateSharedLayout } from "framer-motion";
import { UilTimes } from "@iconscout/react-unicons";
import Chart from "react-apexcharts";

// parent Card

const Card = (props) => {
  return (
        <CompactCard param={props}/>
  );
};
function CompactCard({ param, setExpanded, }) {
  const Png = param.png;
  return (
    <div
      className="CompactCard"
      style={{
        background: "rgb(43, 48, 123, 0.1)",
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Ajuster les valeurs pour obtenir l'effet d'ombre souhaité
        marginTop: '20%',
        marginBottom: '10%',
      }}
      layoutId="expandableCard"
      onClick={setExpanded}
    >
      <div className="radialBar">
      <span style={{color:"#17174F", fontSize:'90%'}}>{param.value}</span>
        <span style={{color:"#17174F", fontSize:'90%'}}>{param.title}</span>
      </div>
      <div className="detail">
        <Png color='#17174F'/>
        <span style={{fontSize:10, }}>Auj</span>
      </div>
    </div>
  );
}

// Expanded Card
function ExpandedCard({ param, setExpanded }) {
  const data = {
    options: {
      chart: {
        type: "area",
        height: "auto",
      },

      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },

      fill: {
        colors: ["#fff"],
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["white"],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
      grid: {
        show: true,
      },
      xaxis: {
        type: "category",
        categories: param.categories,
        labels: {
          formatter: function (value) {
            // Format the date to "24/02/24 14H44"
            const date = new Date(value);
            const formattedDate = `${("0" + date.getDate()).slice(-2)}/${
              ("0" + (date.getMonth() + 1)).slice(-2)
            }/${date
              .getFullYear()
              .toString()
              .slice(-2)} ${("0" + date.getHours()).slice(-2)}H${
              ("0" + date.getMinutes()).slice(-2)
            }`;
  
            return formattedDate;
          },
        },
      },
    },
  };

  return (
    <motion.div
      className="ExpandedCard"
      style={{
        background: param.color.backGround,
        boxShadow: param.color.boxShadow,
      }}
      layoutId="expandableCard"
    >
      <div style={{ alignSelf: "flex-end", cursor: "pointer", color: "white" }}>
        <UilTimes onClick={setExpanded} />
      </div>
        <span>{param.title}</span>
      <div className="chartContainer">
        <Chart options={data.options} series={param.series} type="area" />
      </div>
      <span>Derniers jours</span>
    </motion.div>
  );
}

export default Card;
