import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import { currencies } from '../../../actions/currencies';
export default function CardPercent({ name, value, percentage }) {
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
    <Card invertedColors
    sx={{
        width:isScreenSmall()?'77%':null,
        background: "rgb(43, 48, 123, 0.1)",
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', 
      }}
      >
      <CardContent orientation="horizontal">
      <CircularProgress size="lg" determinate value={percentage} sx={{
            '&.MuiCircularProgress-colorPrimary': {
              color: '#9449FF !important',
            },
         }}     
          >
          {percentage > 0 ? (
            <SvgIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#9449FF"
                >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  stroke="#9449FF"
                  d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
                />
              </svg>
            </SvgIcon>
          ) : (
            <SvgIcon>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#9449FF"
                             >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6L9 12l4.306-4.307a11.95 11.95 0 015.814 5.519l2.74 1.22m0 0l-5.94 2.28m5.94-2.28l-2.28-5.941"
                  stroke="#9449FF"
                />
              </svg>
            </SvgIcon>
          )}
        </CircularProgress>
        <CardContent>
          <Typography level="body-md"  sx={{color:'#17174F'}}>{name}</Typography>
          <Typography level="h2"  sx={{color:'#17174F'}}>{value} {currencies().symbol}  </Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
}
