import * as React from 'react';
import Box from '@mui/joy/Box';
import Chip from '@mui/joy/Chip';
import Card from '@mui/joy/Card';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import Typography from '@mui/joy/Typography';
import Divider from '@mui/joy/Divider';

export default function RushHours({ rushHours=[] }) {
  const formatHour = (hour) => {
    return `${hour.start}-${hour.end}`;
  };
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
    <Box
      sx={{
        width:isScreenSmall()?'85%':null,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(min(100%, 300px), 1fr))',
        gap: 2,
      }}
    >
      <Card size="lg" variant="outlined"
      sx={{
        backgroundColor:'rgb(43, 48, 123, 0.3)' ,
        
      }}
      >
        <Chip size="sm" variant="outlined" color="neutral"
        sx={{
        backgroundColor:'rgb(43, 48, 123, 0.3)',
       color:'#17174F',
       //marginLeft:isScreenSmall()?'40%':null,
       alignSelf :isScreenSmall()?'center':null
      }}
        >
          Heures de rush
        </Chip>
        <List size="sm" sx={{ mx: 'calc(-1 * var(--ListItem-paddingX))',        alignSelf :isScreenSmall()?'center':null
 }}>
          {rushHours.map((hour, index) => (
            <React.Fragment key={index}>
              <ListItem disablePadding>
                <Typography sx={{fontSize:'100%', color:'#17174F'}} >{hour}</Typography>
              </ListItem>
              {index !== rushHours.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Card>
    </Box>
  );
}
