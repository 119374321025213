import React from 'react';
import { Card, CardContent, Typography, List, ListItem, ListItemIcon, ListItemText, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const services = [
    "Gestion des commandes via QR code",
    "Gestion des commandes via le service à table",
    "Suivi des commandes en cuisine et au comptoir",
    "Analyse des performances",
  "Gestion des retours clients",
  "E-mail marketing clients",
  "Augmentation des avis clients",
];

const Pricing = () => {
  return (
    <Card sx={{ maxWidth: 345, margin: 'auto', textAlign: 'center', padding: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          <Box fontWeight="fontWeightBold">
            Débuter
          </Box>
        </Typography>
        <List>
          {services.map((service, index) => (
            <ListItem key={index}>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText primary={service} />
            </ListItem>
          ))}
        </List>
        <Typography variant="h4" component="div" gutterBottom>
          <Box fontWeight="fontWeightBold">
            {/**Prix*/}
          </Box>
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Pricing;
