import React, { useState } from 'react';
import './Register.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import client from '../../api/client';
import socket from '../../api/socket';
import { fetchProducts } from '../../actions/productActions';
import { useDispatch } from 'react-redux';
import { IconButton, InputAdornment, TextField, Typography, Modal, Box , CircularProgress, Backdrop} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
const SignIn = ({navigate}) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetEmailError, setResetEmailError] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const [loading, setLoading] = useState(false);


  const validateForm = () => {
    let isValid = true;

    // Email validation
    if (!email) {
      setEmailError('Email ne peut pas être vide');
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError('Format d\'email non valide');
      isValid = false;
    } else {
      setEmailError('');
    }

    // Password validation
    if (!password) {
      setPasswordError('Mot de passe ne peut pas être vide');
      isValid = false;
    }
     else {
      setPasswordError('');
    }

    return isValid;
  };

  const handleSubmit = async() => {
    if (validateForm()) {
      try {
        console.log("email....", email)
        setLoading(true); // Afficher la barre de chargement
        await client.post('/sign-in', { email, password },{
          headers: {
              Accept: 'application/json',
              //'content-Type': 'multipart/form-data',
            }
          } 
        ).then((res)=>{
          if (res.data.success) {
            localStorage.setItem("token", JSON.stringify(res.data.token));
            localStorage.setItem("manager", JSON.stringify(res.data.manager));
            if(res.data.manager.is_restaurant){
            socket.connect();
            localStorage.setItem("mainCardsData", JSON.stringify(res.data.mainCardsData));
            localStorage.setItem("mainTodayOrders", JSON.stringify(res.data.mainTodayOrders));
            localStorage.setItem("mainTodayReviews", JSON.stringify(res.data.mainTodayReviews));
            localStorage.setItem("orders", JSON.stringify(res.data.orders));
            localStorage.setItem("clients", JSON.stringify(res.data.clients));
            localStorage.setItem("restaurant", JSON.stringify(res.data.restaurant));
            localStorage.setItem("data", JSON.stringify(res.data.data));
            localStorage.setItem("refresh", JSON.stringify(false));
            const fetchData = () => {
              try {
                dispatch(fetchProducts(res.data.token,client));
              } catch (error) {
                console.error('Error fetching products:', error.message);
              }
            };
            fetchData()
            }
            console.log("data sign In ", res.data.manager.is_restaurant)
            navigate('/all-components', {socket});
          } else {
            console.log("res data", res.data.error)
            setError(res.data.error);
            setTimeout(() => {
              setError('');
            }, 3000);
          }          
        })
      

      }catch (error) {
          console.log("error ", error);
          setError(error.response.data.error || 'Une erreur inattendue s\'est produite.');
          setTimeout(() => {
            setError('');
          }, 3000); 
        }finally {
          setLoading(false); // Cacher la barre de chargement
        }
  
    } else {
      // If the form is not valid, show an error message for 3 seconds
      setTimeout(() => {
        setEmailError('');
        setPasswordError('');
      }, 3000);
    }
  };
  const handleForgotPassword = async () => {
    if (!resetEmail) {
      setResetEmailError('Email ne peut pas être vide');
      return;
    } else if (!/\S+@\S+\.\S+/.test(resetEmail)) {
      setResetEmailError('Format d\'email non valide');
      return;
    } else {
      try {
        console.log("forgot password??..",resetEmail)
        setLoading(true); // Afficher la barre de chargement
        await client.post('/forgot-password', { email: resetEmail },{
          headers: {
              Accept: 'application/json',
              //'content-Type': 'multipart/form-data',
            }
          } 
        ).then(
          (res)=>{
            
              console.log("forgot password data??..",res.data )
              if (res.data.success) {
                      setResetSuccess(true);
                      setResetEmailError('');
                      setTimeout(()=>{
                        setResetSuccess(false);
                      }, 5000)
                    } else {
                      setResetEmailError(res.data.error);
                    }
        })        
      } catch (error) {
        
      }finally {
        setLoading(false); // Cacher la barre de chargement
      }

      
  }
  };
  
  return (
    
    <form className="glass-form-container" style={{height: '50vh',width: '80%',display: 'flex', flexDirection: 'column', alignItems: 'center',  boxShadow: '0 4px 8px 0 rgba(36, 45, 73, 0.2), 0 6px 20px 0 rgba(36, 45, 73, 0.5)', // Box shadow modifié pour utiliser la couleur #242d49
    borderRadius: '50px',}}>
      <div style={{ fontSize: 30, fontWeight: 800,  marginTop: '8%', paddingBottom:'10%', color:"#17174F" }}>Connexion</div>
    <div style={{height: '60vh', width: '80%', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      {error && <div style={{ color: 'red', textAlign: 'center', paddingBottom:'1%' }}>{error}</div>}
        <TextField
          id="standard-multiline-flexible"
          label="E-mail"
          sx={{ 
            marginBottom: '20px', width: '100%',color: '#fff',
             '& label.Mui-focused': {
            color: '#17174F',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              borderColor: '#17174F',
            },
          }, }}
          onChange={(e) => setEmail(e.target.value)}
          error={Boolean(emailError)}
          helperText={emailError}
        />
      <TextField
  id="standard-multiline-flexible"
  label="Mot de passe"
  type={showPassword ? 'text' : 'password'}
  sx={{ marginBottom: '20px', width: '100%','& label.Mui-focused': {
      color: '#17174F',
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: '#17174F',
      },
    },  }}
  value={password}
  onChange={(e) => setPassword(e.target.value)}
  InputProps={{
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    ),
  }}
/>
      <div style={{ alignSelf: 'center' }}>
        <Button
          onClick={handleSubmit}
          sx={{ background: "#17174F", ":hover": { backgroundColor: "#1E90FF" } }}
          size="small"
          variant="contained"
          disableElevation
        >
          Se connecter
        </Button>
        <Typography
          variant="body2"
          color="primary"
          onClick={() => setForgotPasswordModalOpen(true)}
          style={{ cursor: 'pointer', marginTop: '10px' }}
        >
          Mot de passe oublié ?
        </Typography>
      </div>
      
    </div>
    <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    <Modal
        open={forgotPasswordModalOpen}
        onClose={() => setForgotPasswordModalOpen(false)}
        aria-labelledby="forgot-password-modal"
        aria-describedby="forgot-password-description"
      >
        <Box sx={{
          position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
          width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: 2
        }}>
          {!resetSuccess ? (
            <>
              <Typography sx={{color:'black'}} id="forgot-password-modal" variant="h6" component="h2">
                Réinitialiser le mot de passe
              </Typography>
              <TextField
                fullWidth
                id="reset-email"
                label="E-mail"
                type="email"
                value={resetEmail}
                onChange={(e) => setResetEmail(e.target.value)}
                sx={{ mt: 2 }}
                error={Boolean(resetEmailError)}
                helperText={resetEmailError}
              />
              <Button onClick={handleForgotPassword} sx={{ mt: 2 }} variant="contained" color="primary">
                Soumettre
              </Button>
            </>
          ) : (
            <Typography sx={{color:'black'}} id="forgot-password-description" variant="body1">
              Un lien vous a été envoyé dans votre mail pour modifier votre mot de passe.
            </Typography>
          )}
        </Box>
    </Modal>
    </form>
  );
};

export default SignIn;
