import React, { useState } from 'react';
import { CardContent, Typography, Grid, TextField, Button } from '@mui/material';
import client from '../../../api/client';
const FormPasswordReset = () => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const handleChangePassword = async () => {
    if (newPassword !== confirmNewPassword) {
      alert("Les nouveaux mots de passe ne correspondent pas");
      return;
    }

    const token = JSON.parse(localStorage.getItem("token"));

    try {
      await client.post(
        "/change-password",
        { oldPassword, newPassword },
        {
          headers: {
            Accept: 'application/json',
            Authorization: `JWT ${token}`,
          },
        }
      ).then((res)=>{
        if(res.data.success){
          alert("Mot de passe modifié avec succès");   
        }else{
          alert(res.data.error); 
        }
      })
    } catch (error) {
      console.error("Erreur lors du changement de mot de passe:", error);
      alert("Une erreur est survenue lors du changement de mot de passe");
    }
  };

  return (
    <div>
      <Typography variant="h6">Mot de passe</Typography>
      {/* Formulaire pour modifier le mot de passe */}
      <form>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Ancien mot de passe"
              type="password"
              fullWidth
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Nouveau mot de passe"
              type="password"
              fullWidth
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirmer le nouveau mot de passe"
              type="password"
              fullWidth
              value={confirmNewPassword}
              onChange={(e) => setConfirmNewPassword(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button 
          variant="outlined" 
          sx={{ mt: 2 }} 
          onClick={handleChangePassword}
        >
          Modifier le mot de passe
        </Button>
      </form>
    </div>
  );
};

export default FormPasswordReset;
