import React,{useState,useEffect} from 'react';
import './MainOrder.css'; // Fichier CSS pour les styles du composant
import OrderBigScreen from './Components/OrderBigScreen';
import OrderSmallScreen from './Components/OrderSmallScreen';
import SearchBar from './Components/SearchBar';
import CachedIcon from '@mui/icons-material/Cached';
import SettingsIcon from '@mui/icons-material/Settings';
import OrderPayments from './Components/OrdersPayments';
import client from '../../api/client';
import { useNavigate } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import DeleteConfirmationModal from './Components/DeleteConfirmationModal';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsModal from './Components/SettingsModal';
import { useLocation } from 'react-router-dom';
import PaymentsBigScreen from './Components/PaymentsBigScreen';
import { Button, Modal, Typography,IconButton } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CreateOrderModal from './Components/CreateOrderModal';
import audio from './notification.mp3';
import {CircularProgress, Backdrop } from '@mui/material';
import RefreshButton from '../../RefreshButton';

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};
/**
 * 

 */
const MainOrder = ({socket}) => {

  
  
  const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    const navigateLogin = (location) => {
        if (!location.state) {
            console.log('location null.... ', location);
            navigate('/comptoir/connexion');
        }
    };

    useEffect(() => {
        navigateLogin(location);
    }, [location]);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);

    const handleCreateOrder = ()=>{
      console.log("truetruetruetrue")
      setCreateModalOpen(true)
    }
const handleSaveOrder=()=>{

}
    const { orderRestorer } = location.state || { orderRestorer: [] };
    const currenciesJson = localStorage.getItem("currencies")
  const restaurantIdJson = localStorage.getItem("restaurantId")
  const [currencies,setCurrencies]  = useState(currenciesJson?JSON.parse(currenciesJson):{code:'',})
  const [restaurantId,setRestaurantId]  = useState(restaurantIdJson?JSON.parse(restaurantIdJson):null)
  const [orders, setOrders] = useState(orderRestorer.filter(order => order.status === 'En attente'));
    const [ordersPayment, setOrdersPayment] = useState(orderRestorer.filter(order => order.type === 'Non payée'));
    const [AllOrders, setAllOrders] = useState(orderRestorer);
    const [refresh, setRefresh] = useState(false);
    let [color, setColor] = useState("#000000");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [orderIdToDelete, setOrderIdToDelete] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    console.log("check token...............",currencies)    
    const [selectedSection, setSelectedSection] = useState("commandes")
    const types=["Commandes","Paiements"]
    const selectedStyle = {
      textDecoration: 'underline',
      color: '#17174F', // Remplacez par la couleur souhaitée
    };
    const handleOpenModal = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
    useEffect(() => {
      socketConnexion()
    }, []);
    const fetchSetOrders = async (event) => {
      //event.preventDefault();
      try {
        const identifier = localStorage.getItem("identifier")
      const access_code = localStorage.getItem("access_code")
      await client.post('/signRestorer', { restaurantId,identifier, access_code },{
        headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
            //authorization : `JWT ${token}`,
          }
        }).then((res) => {
       if (res.data.success) {
          setOrders(res.data.orderRestorer.filter(order => order.status === 'En attente'));
          setOrdersPayment(res.data.orderRestorer.filter(order => order.type === 'Non payée'));
          setAllOrders(res.data.orderRestorer);
          socketConnexion()
        } else {
          console.log("error....", res.data)
        }
      })
      } catch (error) {
        
      }finally{
        setLoading(false); // Mettre isLoading à false une fois le chargement terminé
      };
    };
    /*useEffect(() => {
      
      if (refresh) {
        fetchSetOrders();
        setRefresh(false)
      }
    }, [refresh]);*/
    const socketConnexion = async () => {
        // Convertir la chaîne JSON en objet JavaScript
        socket.connect()
        const managerRoom = `manager-${restaurantId}`;
        socket.emit('joinRoom', managerRoom);       
    }
    
  
    const handleSearch = (numberOfTable) => {    // Filtrer localement les commandes en fonction du numéro de table
      console.log('handleSearch..', numberOfTable)
      if (numberOfTable > 0) {
        const filteredOrders = AllOrders.filter(order => order.numberOfTable === numberOfTable);
        // Séparer les commandes en attente et non payées
        const ordersEnAttente = filteredOrders.filter(order => order.status === 'En attente');
        const ordersNonPayees = filteredOrders.filter(order => order.type === 'Non payée');
        setOrders(ordersEnAttente);
        setOrdersPayment(ordersNonPayees);
        console.log("parseInt(numberOfTable).....", numberOfTable, ordersNonPayees)
  
      } else {
        const ordersEnAttente = AllOrders.filter(order => order.status === 'En attente');
        const ordersNonPayees = AllOrders.filter(order => order.type === 'Non payée');
        setOrders(ordersEnAttente);
        setOrdersPayment(ordersNonPayees);
      }
    };
    useEffect(() => {
      const canPlaySound = ()=>{
        const soundSetting = localStorage.getItem('isSoundOn');
        return soundSetting !== null ? JSON.parse(soundSetting) : true;
      }
      const playAudio = () => {
        if(canPlaySound){
          new Audio(audio).play();
        }
      }
      socket.on('newOrderRestorer', (newOrder) => {
        setOrders((prevOrders) => [...prevOrders, newOrder]);
        setOrdersPayment((prevOrders) => [...prevOrders, newOrder]);
        setAllOrders((prevOrders) => [...prevOrders, newOrder]);
        playAudio();
      });
  
      // Nettoyer l'écouteur lorsque le composant est démonté
      return () => {
        socket.off('newOrderRestorer');
      };
    }, []);
    const onDeleteItem2 = async (orderId) => {
      console.log('onDeleteItem2......',);
      // Mettez à jour l'état des commandes
      setOrders(orders.filter(order => order.orderId !== orderId));
      const foundOrder = ordersPayment.find(order => order.orderId === orderId);
      if (foundOrder) {
        console.log('founder order......', foundOrder);
      } else {
        setAllOrders(AllOrders.filter((anOrder) => anOrder.orderId !== orderId));
        //await saveObject("orderRestorer", AllOrders)
      }
      //await fetchSetOrders()
    };
  
    const onDeleteItemPayment = async (orderId, newType) => {
      //await fetchSetOrders()
      setOrdersPayment(ordersPayment.filter(order => order.orderId !== orderId))
      // Mettez à jour l'état des commandes
      const foundOrder = orders.find(order => order.orderId === orderId);
      if (foundOrder) {
        console.log('founder payment order....', foundOrder);
      } else {
        setAllOrders(AllOrders.filter((anOrder) => anOrder.orderId !== orderId));
        //await saveObject("orderRestorer", AllOrders)
      }
    };
  
    const editOrder=()=>{
      fetchSetOrders()
    }
    const handleDeleteOrder = async (orderId,reason)=>{
      try {
          const token = JSON.parse((localStorage.getItem("tokenRestorer")))
          if(token){
            await client.post('/validateOrNotOrder', { orderId, newStatus: "Refusée", paymentTypes: "Non payée",reason }, {
            headers: {
              Accept: 'application/json',
              //'content-Type': 'multipart/form-data',
              Authorization: `JWT ${token}`,
            }
          }).then(async (res) => {
            if (!res?.data?.success) {
              navigate('/comptoir/connexion');
            }
            if (res?.data?.success) {
              const updatedOrders = orders.filter(order => order.orderId !== orderId);
              const updatedOrdersPayment = ordersPayment.filter(order => order.orderId !== orderId);
              const updatedAllOrders = AllOrders.filter(order => order.orderId !== orderId);
              // Mettez à jour l'état des commandes
              setAllOrders(updatedAllOrders);
              setOrders(updatedOrders);
              setOrdersPayment(updatedOrdersPayment);
              //await saveObject("orderRestorer", AllOrders)
            }
          })
          }else {
            navigate('/comptoir/connexion');
          }        
      } catch (error) {
        
      }finally {
        setLoading(false); // Cacher la barre de chargement
      }

        
    }
    const setModalFalse=()=>{
      setShowDeleteModal(false)
    }
    const onDeleteItem = async (orderId) => {
      setShowDeleteModal(true);
      setOrderIdToDelete(orderId)
    };
    const handleRefresh = () => {
      setRefresh(!refresh)
    }
    const isScreenSmall = () => {
        return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
      };
    const navigateOrdersHistory = ()=>{
      navigate('/comptoir/historique-commandes', { state:{orderRestorer:AllOrders}});
    }
    
      useEffect(() => {
      }, [orders, ordersPayment]);
      const [isHovered, setIsHovered] = useState(false);

      return (
        <div style={{ padding: '0', backgroundColor: '#F4F4FD', height: '100vh' }}>
      <div className="main-order-container" style={{ backgroundColor: 'white', minHeight: '100vh', padding: '2rem', boxSizing: 'border-box' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          {/* Conteneur pour le SearchBar */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <SearchBar handleSearch={handleSearch} />
          </div>

          {/* Conteneur pour les icônes */}
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <RefreshButton onMouseLeave={() => setIsHovered(false)}
              style={{ marginRight: '10px', cursor: 'pointer', boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none', transition: 'box-shadow 0.3s ease-in-out' }} onClick={fetchSetOrders} />
            <SettingsIcon onClick={handleOpenModal} onMouseLeave={() => setIsHovered(false)}
              style={{  cursor: 'pointer', boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none', transition: 'box-shadow 0.3s ease-in-out' }} />
            <AssignmentIcon onClick={navigateOrdersHistory} onMouseLeave={() => setIsHovered(false)}
              style={{ marginLeft: '10px', cursor: 'pointer', boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none', transition: 'box-shadow 0.3s ease-in-out' }} />
          </div>
        </div>
        <div style={{ marginTop: isScreenSmall() ?'2rem':'0' }}>
        <div onClick={handleCreateOrder}  >
              <IconButton
            style={{ position: 'relative',  right: '0%' }}
            
          >
            <BorderColorIcon />
          </IconButton>
            </div>
            
          <div style={{color:'#4d4d54' , display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
            <div onClick={() => setSelectedSection("commandes")} style={{ margin: '0 10px', cursor: 'pointer' }}>
              <span style={{ fontSize: isScreenSmall() ? '16px' : '18px', fontWeight: '700', ...(selectedSection === "commandes" ? selectedStyle : {}) }}>{orders?.length} Commandes</span>
            </div>
            <div onClick={() => setSelectedSection("paiements")} style={{ margin: '0 10px', cursor: 'pointer' }}>
              <span style={{color:'#4d4d54' ,fontSize: isScreenSmall() ? '16px' : '18px', fontWeight: '700', ...(selectedSection === "paiements" ? selectedStyle : {}) }}>{ordersPayment?.length} Paiements</span>
            </div>
          </div>

          {selectedSection === "commandes" && (
            <div style={{ marginTop: '2rem' }}>
              {isScreenSmall() ? (
                <OrderSmallScreen orderRestorer={orders} currencies={currencies} onDeleteItem={onDeleteItem} onDeleteItem2={onDeleteItem2} restaurantId={restaurantId} editOrder={editOrder} />
              ) : (
                <OrderBigScreen orderRestorer={orders} currencies={currencies} onDeleteItem={onDeleteItem} onDeleteItem2={onDeleteItem2} restaurantId={restaurantId} editOrder={editOrder}/>
              )}
            </div>
          )}

        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
        </Backdrop>

          {selectedSection === "paiements" && (
            <div style={{ marginTop: '2rem' }}>
              {isScreenSmall() ? (
                <OrderPayments orderRestorer={ordersPayment} onDeleteItemPayment={onDeleteItemPayment} currencies={currencies} restaurantId={restaurantId} />
              ) : (
                <PaymentsBigScreen orderRestorer={ordersPayment} onDeleteItemPayment={onDeleteItemPayment} currencies={currencies} restaurantId={restaurantId} />
              )}
            </div>
          )}
        </div>

        {showDeleteModal && (
          <DeleteConfirmationModal orderId={orderIdToDelete} onDeleteConfirmed={handleDeleteOrder} setModalFalse={setModalFalse} />
        )}
        <SettingsModal isVisible={isModalOpen} onClose={handleCloseModal} />
      </div>
      <div style={{ top: 0, left: 0, width: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'center',}}>
      <CreateOrderModal
        open={isCreateModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSave={editOrder}
        currencies={currencies}
      />
      </div>
    </div>
      );
};
export default MainOrder;