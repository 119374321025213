import React,{useState, useEffect} from "react";
import CustomerReview from "../CustomerReview/CustomerReview";
import Updates from "../Updates/Updates";
import client from "../../api/client";
import "./RightSide.css";
import socket from "../../api/socket";
import Chart from "react-apexcharts";

const formatDate = () => {
  const date = new Date()
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
function getTodayISOString() {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00.000Z`;
}

const RightSide = () => {

  const [reviews, setReviews] = useState([{name:'', review:'', rating:0}])
  const [param, setParam] = useState([[],[]])
  const [categories, setCategories] = useState([])
  useEffect(()=>{
    const fetchData = async()=>{
      try {
        const id_restaurant = JSON.parse(localStorage.getItem("restaurant"))._id;
        const token = JSON.parse(localStorage.getItem("token"));
        console.log("fecth.........")
        await client.post('/get-reviews', {id_restaurant,date:getTodayISOString()},{
          headers: {
            Accept: 'application/json',
            authorization: `JWT ${token}`,
          },
        }).then((res)=>{
          if(res.data.success){
            if(res.data.reviews.length > 0) {
              setReviews(res.data.reviews);
            }
            setParam(res.data.ordersCurves)
              const newCategories = res.data.ordersCurves.hours.map(hour => {
                const [hourStr, minStr] = hour.split(':');
                const time = new Date(2000, 0, 1, hourStr, minStr);
                return time;
              });
              setCategories(newCategories)
              console.log("fetch res.........",newCategories)
          }
        })
      } catch (error) {
        
      }
    }
    fetchData()
  }, [])
  useEffect(()=>{
    socket.on('review', (data)=>{
      const newReviews = [...reviews, data]
      setReviews(newReviews)

    })
    return () => {
      socket.off()
    };
  }, [reviews])
  useEffect(()=>{
    console.log("param rightSide.........")
  },[param, categories, reviews])
  const data = {
    series: [
      {
        name: "Commandes",
        data: param?.ordersNumber,
      },
    ],
    options: {
      chart: {
        type: "area",
        height: "auto",
      },

      fill: {
        colors: ["#fff"],
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#ff929f"],
      },
      tooltip: {
        x: {
          format: "HH:mm",
        },
      },
      grid: {
        show: false,
      },
      xaxis: {
        type: "datetime",
        categories: categories,
      },
      yaxis: {
        show: false
      },
      toolbar: {
        show: false
      }
    },
  };

  return (
    <div className="RightSide">
      <div>
        <h3 style={{ marginTop:'50%' }} >Nouveaux Avis</h3>
        <Updates reviews={reviews}/>
        <h3>Évolution du restaurant</h3>
        <div className="CustomerReview">
        <CustomerReview param={param}/>
    </div>
      </div>
    </div>
  );
};

export default RightSide;
