import React, { useState, useEffect, useRef } from 'react';
import NavBar from './NavBar';
import DynamicTable from './DynamicTable';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { connect } from 'react-redux';
import { addProduct, deleteProduct, editProduct, editCategory, deleteCategory, addCategory, } from '../../actions/productActions';
import DialogSection from './DialogSection';


const MainProducts = ({data,addProduct, deleteProduct, editProduct, editCategory, deleteCategory, addCategory }) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const restaurantJson = localStorage.getItem("restaurant")
  const [restaurant,setRestaurant]  = useState(restaurantJson?JSON.parse(restaurantJson):null)

  const menuRef = useRef();
    
  useEffect(() => {
    const types = data?.map((item) => item.type);
    const uniqueTypes = Array.from(new Set(types));
    setUniqueTypes(uniqueTypes);
    localStorage.setItem("data", JSON.stringify(data))
    if (data?.length > 0 && !selectedSection) {
      setSelectedSection(data[0]?.type);
    }

    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeContextMenu();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [data, selectedSection]);

  const handleSelectSection = (section) => {
    setSelectedSection(section);
  };

  const handleContextMenu = (event, section) => {
    event.preventDefault();
    setContextMenu({ section, x: event.clientX, y: event.clientY });
  };

  const closeContextMenu = () => {
    setContextMenu(null);
  };

  const handleContextMenuAction = (action, section) => {
    
    // Trouver les informations du type en fonction de la section
    const selectedTypeInfo = data.find((item) => item.type === section);

    // Stocker les informations du type dans l'état
    setSelectedType(selectedTypeInfo);

    // Ouvrir le DialogSection
    closeContextMenu();
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className='MainDash'>
      <h1 style={{ position: 'fixed', top: '10%', }}>Section Culinaire</h1>      
        <NavBar
        types={uniqueTypes}
        onSelectSection={handleSelectSection}
        selectedSection={selectedSection}
        onContextMenu={handleContextMenu}
        handleOpenModal={handleOpenModal}
      />
      
      
      <DynamicTable
      style={{ position: 'fixed', top: '30%',

      
       }}
        data={selectedSection ? data.find((item) => item?.type === selectedSection)?.data : []}
        type={selectedSection ? selectedSection : data[0]?.type}
        addProduct={addProduct}
        deleteProduct={deleteProduct}
        editProduct={editProduct}
        editCategory={editCategory}
        addCategory={addCategory}
        deleteCategory={deleteCategory}
        id_type = {selectedSection ? data.find(item => item.type === selectedSection)?.id : data[0]?.id}
      />

{contextMenu && (
        <div
          className='ContextMenu'
          style={{
            position: 'absolute',
            top: contextMenu.y,
            left: contextMenu.x,
            width: '5%',
            height: '3%',
            background: '#fff',
            border: '1px solid #ccc',
            padding: '8px',
            borderRadius: '4px',
          }}
          ref={menuRef}
        >
          <div
            onClick={() => handleContextMenuAction('Modifier', contextMenu.section)}
            style={{
              cursor: 'pointer',
              padding: '8px',
            }}
          >
            Modifier
          </div>
        </div>
      )}
      {selectedType && ( // Conditionner l'affichage de DialogSection sur selectedType
        <DialogSection
          open={{}}
          onClose={() => setSelectedType(null)}
          newType={selectedType.type}
          newDescription={selectedType.description}
          idType={selectedType.id}
          displayType={selectedType.display}
          image={selectedType?.image}
        />
      )}
      <Dialog open={isModalOpen} onClose={handleCloseModal} >
        <DialogContent style={{ padding: 0, margin: 0, overflow: 'auto' }}>
          <iframe 
            src={`https://yumz-client.com/restaurant/${restaurant._id}/0`}
            width="350"
            height="800" 
            style={{ border: 'none', overflow:'auto' }} 
            title="Prévisualiser"
            scrolling="yes"
          ></iframe>
        </DialogContent>
      </Dialog>
      <div className='Overlay' onClick={closeContextMenu} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.product.data,
});

export default connect(mapStateToProps, { addProduct, deleteProduct, editProduct, editCategory, deleteCategory, addCategory })(MainProducts);