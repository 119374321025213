
const countriesData = [
    { code: 'FR', name: 'France', currency: { code: 'EUR', name: 'Euro', symbol: '€' } },
    { code: 'DE', name: 'Germany', currency: { code: 'EUR', name: 'Euro', symbol: '€' } },
    { code: 'IT', name: 'Italy', currency: { code: 'EUR', name: 'Euro', symbol: '€' } },
    { code: 'ES', name: 'Spain', currency: { code: 'EUR', name: 'Euro', symbol: '€' } },
    // ... Ajoutez d'autres pays d'Europe utilisant l'Euro
  
    { code: 'SN', name: 'Senegal', currency: { code: 'XOF', name: 'CFA Franc', symbol: 'XOF' } },
    { code: 'CI', name: "Côte d'Ivoire", currency: { code: 'XOF', name: 'CFA Franc', symbol: 'XOF' } },
    { code: 'BF', name: 'Burkina Faso', currency: { code: 'XOF', name: 'CFA Franc', symbol: 'XOF' } },
    { code: 'TG', name: 'Togo', currency: { code: 'XOF', name: 'CFA Franc', symbol: 'XOF' } },
    // ... Ajoutez d'autres pays d'Afrique de l'Ouest utilisant le CFA
  
    { code: 'US', name: 'United States', currency: { code: 'USD', name: 'US Dollar', symbol: '$' } },
    { code: 'CA', name: 'Canada', currency: { code: 'CAD', name: 'Canadian Dollar', symbol: 'CA$' } },
    // ... Ajoutez d'autres pays utilisant le dollar
  ].sort((a, b) => a.name.localeCompare(b.name));
  
  export default countriesData;
  