import React, { useEffect, useState, useRef } from 'react';
import client from '../../../api/client';
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import ExpandedCard from './ordersCurve';
import CardPercent from './card';
import CardStatic from './cardStatic';
import TopProducts from './TopProducts';
import RushHours from './RushHours';
import { currencies } from '../../../actions/currencies';
import BarChartComponent from './BarChartComponent';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import SelectProducts from './SelectProducts';
import './AnalysisOrders.css'
const moment = extendMoment(originalMoment);

const convertValueToMoment = (value) => {
  if (!value ||!value.start ||!value.end) {
    return null; // Retourne null si value ou les propriétés start/end sont absentes
  }

  // Convertit les dates start et end en objets Moment
  const start = moment(value.start, 'YYYY-MM-DD');
  const end = moment(value.end, 'YYYY-MM-DD');

  return moment.range(start, end); // Return an array of moment objects
};
const AnalysisOrders = () => {
    const [isOpen, setIsOpen] = useState(false);
    const storedValue = localStorage.getItem('selectedDateRangeOrder');

  const [value, setValue] = useState(storedValue ?convertValueToMoment(JSON.parse(storedValue)):moment.range(moment().clone().subtract(7, "days"), moment().clone()));
  const [dataCard, setDataCard] = useState({name:'Prix moyen', value:'0.00', percentage:0})
  const [totalOrder, setTotalOrder] = useState({name:'Nombre commandes', value:''})
  const [totalPrice, setTotalPrice] = useState({name:'Prix Total', value:''})
  const [dataCardAveg, setDataCardAveg] = useState({name:'Prix moyen', value:'', percentage:0})
  const [rushHours, setRushHours] = useState([])
  const [plats, setPlats] = useState([])
  const [defaultProduct, setDefaultProduct] = useState(localStorage.getItem("defaultPlat")?localStorage.getItem("defaultPlat"):'Tout')
  const [defaultIndex, setDefaultIndex] = useState(localStorage.getItem("defaultIndex") !== 'undefined'?JSON.parse(localStorage.getItem("defaultIndex")):0)
  let initialStartDate = '';
    let initialEndDate = '';
    console.log("value.....", value, convertValueToMoment(JSON.parse(storedValue)), storedValue)
    if (value && value.start && typeof value.start.format === 'function') {
        console.log("value.start.format.....", value.start.format)
        initialStartDate = value.start.format('YYYY-MM-DD');
    } else {
        // Set a default start date if value.start is not valid
        initialStartDate = moment().clone().subtract(7, 'days').format('YYYY-MM-DD');
    }

    if (value && value.end && typeof value.end.format === 'function') {
        initialEndDate = value.end.format('YYYY-MM-DD');
    } else {
        // Set a default end date if value.end is not valid
        initialEndDate = moment().format('YYYY-MM-DD');
    }

const [date, setDate] = useState(storedValue ? JSON.parse(storedValue) :{ start: initialStartDate, end: initialEndDate });
const [products, setProducts] = useState([])
const [idProducts, setIdProducts] = useState(localStorage.getItem("idProducts") !== 'undefined'?JSON.parse(localStorage.getItem("idProducts")):[])
const [topProducts, setTopProducts] = useState([])
const pickerRef = useRef();

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
const saveSelectedDateRange = (start, end) => {
  localStorage.setItem(
    'selectedDateRangeOrder',
    JSON.stringify({ start:start, end:end })
  );
};  
const onSelect = (value, states) => {
    setValue(value);
    const dateStart = new Date(value.start._d);
    const dateEnd = new Date(value.end._d);
    const formattedDate = `${dateStart.getFullYear()}-${(dateStart.getMonth() + 1).toString().padStart(2, '0')}-${dateStart.getDate().toString().padStart(2, '0')}`;
    const formattedDateEnd = `${dateEnd.getFullYear()}-${(dateEnd.getMonth() + 1).toString().padStart(2, '0')}-${dateEnd.getDate().toString().padStart(2, '0')}`;
    setDate({start:formattedDate, end:formattedDateEnd})
    saveSelectedDateRange(formattedDate, formattedDateEnd);
  };
  const [data, setData] = useState([])
  const [dates, setDates] = useState([])
  const [barChartData, setBarChartData] = useState([])


  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    console.log("open....",isOpen)
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("defaultProduct.....", idProducts&&idProducts[defaultIndex]?idProducts[defaultIndex]:'Tout', defaultIndex)
        const selectProduct = idProducts&&idProducts[defaultIndex]?idProducts[defaultIndex]:'Tout'
        const id_restaurant = JSON.parse(localStorage.getItem("restaurant"))._id;
        const token = JSON.parse(localStorage.getItem("token"));
    await client.post('/analysis-orders', { id_restaurant, dates:{start:date.start, end:date.end}, defaultProduct:selectProduct}, {
          headers: {
            Accept: 'application/json',
            authorization: `JWT ${token}`,
          },
        }).then((res)=>{
            if(res.data.success){
                console.log("res.data.products.........",res.data)
                setData(res.data.lineChartes.data)
                setDates(res.data.lineChartes.dates)
                const meanPrices = res.data?.ordersStatistics.totalOrders <= 0 ?{averageOrderPrice:'0.00', percentageAverageOrderPrice:0.00, totalOrders:0}:res.data?.ordersStatistics
                const newDataCard = {name:'Prix moyen', value:meanPrices 
                .averageOrderPrice, percentage:res.data.average.percentageChange}
                setDataCard(newDataCard)
                setTopProducts(res.data.ordersRanking)
                setTotalOrder({name:'Nombre commandes', value:res.data.ordersStatistics.totalOrders})
                setTotalPrice({name:'Prix Total', value:res.data.totalPrice})
                setRushHours(res.data.rushHours)
                setBarChartData(res.data.barChartData)
                if(products.length <= 0){
                setProducts(['Tout',...res.data.products])
                setIdProducts(['Tout', ...res.data.Idproducts])
                localStorage.setItem("idProducts",JSON.stringify(['Tout', ...res.data.Idproducts]) )
                }
                
            }
        })

      } catch (error) {
        console.error("Erreur lors de la récupération des données d'analyse des commandes", error);
      }
    };
    fetchData();
  }, [value,defaultProduct]);
  const handleSetDefaultValue = (value, index)=>{
    setDefaultProduct(value)
    setDefaultIndex(index)
    localStorage.setItem('defaultIndex', JSON.stringify(index))
    localStorage.setItem('defaultPlat',value )
  }
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  /*useEffect(()=>{
    console.log('date.......', dataCard)
  },[dates, products])*/
  return (
    <>
      {
        isScreenSmall()?(
          <div class="container">
          <div class="component">
            <h1>Commandes</h1>
          </div>
            <div class="component">
              <SelectProducts plats={products} idProducts={idProducts} handleSetValue={handleSetDefaultValue} />
            </div>
            <div class="component">
              <input type="button" value={`${date.start} - ${date.end}`} onClick={onToggle} />
            </div>

  {isOpen && (
    <div class="component" ref={pickerRef} style={{zIndex: 9999,}}>
      <DateRangePicker
        value={value}
        onSelect={onSelect}
        singleDateRange={true}
      />
    </div>
  )}

  <div class="component">
    <CardStatic name={totalPrice.name} value={`${totalPrice.value} ${currencies().symbol}`} />
  </div>
  <div class="component">
    <CardPercent name={dataCard.name} value={dataCard.value} percentage={dataCard.percentage} />
  </div>
  <div class="component">
    <CardStatic name={totalOrder.name} value={totalOrder.value} />
  </div>

  <div class="component">
    <Card elevation={3}>
      <CardContent>
        <ExpandedCard data={data} dates={dates} />
      </CardContent>
    </Card>
  </div>
  <div class="component">
    <TopProducts products={topProducts} />
  </div>
  <div class="component">
    <Card elevation={3}>
      <CardContent>
        <BarChartComponent data={barChartData} />
      </CardContent>
    </Card>
  </div>
  <div class="component">
    <RushHours rushHours={rushHours} />
  </div>
          </div>
          ):
        (
    <div className='ordersAnalysis' style={{ overflow: 'auto',
      scrollbarWidth: 'none', /* Pour Firefox */
  msOverflowStyle: 'none', /* Pour Internet Explorer et Edge */
  '&::-webkit-scrollbar': { display: 'none' }, /* Pour Chrome, Safari et Opera */
    
    }}>
        <h1 style={{marginTop:'6%'}}>Commandes</h1>
    <div style={{ position: 'relative',display: 'flex', justifyContent: 'flex-end', marginBottom:'5%'  }}>
    <div style={{ position: 'absolute', marginTop:'-2%', right: 150,}}>
    <SelectProducts  plats={products} idProducts={idProducts} handleSetValue={handleSetDefaultValue}/>
    <div className='componentButton' >
      <input 
      style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgb(43, 48, 123, 0.05)' }}
      type="button" value={`${date.start} - ${date.end}`} onClick={onToggle} />
    </div>

  
  </div>

    {isOpen && (
      <div ref={pickerRef} style={{ position: 'absolute',marginTop:'2%', right: 90, zIndex: 9999, backgroundColor: 'white' }}>
        <DateRangePicker
          value={value}
          onSelect={onSelect}
          singleDateRange={true}
        />
      </div>
    )}
  </div>

    <div style={{ display: 'flex', flexDirection: 'row',marginBottom: '3%',  }}>
        <CardStatic name={totalPrice.name} value={`${totalPrice.value} ${currencies().symbol}`} />
        <div style={{ padding: '2%' }}></div>
        <CardPercent name={dataCard.name} value={dataCard.value} percentage={dataCard.percentage} />
        <div style={{ padding: '2%' }}></div>
        <CardStatic name={totalOrder.name} value={totalOrder.value} />
        <div style={{ padding: '2%' }}></div>
        <div style={{ padding: '2%' }}></div>
        <div style={{ padding: '1%' }}></div>
      </div>

  <div style={{ display: 'flex', flexDirection: 'column',}}>
    <div style={{ display: 'flex', flexDirection: 'row',}}>
      <Card elevation={3} style={{ marginBottom: '10px', width:'55%',backgroundColor:'rgb(43, 48, 123, 0.3)' }}>
      <CardContent >
        <ExpandedCard data={data} dates={dates} />
      </CardContent>
    </Card>
    <div style={{ padding: '2%' }}></div>
    <div style={{ display: 'flex', flexDirection: 'column' }}>
    <TopProducts products={topProducts} />
  </div>

    </div>
    <div style={{ display: 'flex', flexDirection: 'row',marginBottom: '10%',}}>
    <Card elevation={3} style={{ width:'55%', backgroundColor:'rgb(43, 48, 123, 0.3)' }}>
      <CardContent>
        <BarChartComponent data={barChartData} />
      </CardContent>
    </Card>
    <div style={{ padding: '2%' }}></div>
    <RushHours rushHours={rushHours} />
  </div>
    
  </div>

    


    </div>          
        )
      }
    </>
    
  );
};

export default AnalysisOrders;
