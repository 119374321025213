import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid } from 'recharts';

const BarChartComponent = ({ data=[] }) => {
  // Vérifier si le tableau de données est vide ou non défini
  if (data.length === 0) {
    return <div>Pas de données pour l'instant</div>;
  }

  // Générer des couleurs aléatoires pour chaque barre
  const generateRandomColor = () => {
    return '#' + Math.floor(Math.random() * 16777215).toString(16);
  };
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
    <BarChart
      width={isScreenSmall()?300:600}
      height={isScreenSmall()?200:400}
      data={data}
      margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="_id" />
      <YAxis />
      <Tooltip />
      <Legend />
      {Object?.keys(data[0]).map((key, index) => {
        if (key !== '_id') {
          return (
            <Bar key={key} dataKey={key} fill={'#17174F'} />
          );
        }
        return null;
      })}
    </BarChart>
  );
};

export default BarChartComponent;
