export const ADD_PRODUCT = 'ADD_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_CATEGORY = 'EDIT_CATEGORY';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';
export const EDIT_TYPE = 'EDIT_TYPE';
export const DELETE_TYPE = 'DELETE_TYPE';
export const ADD_TYPE = 'ADD_TYPE';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';

export const fetchProducts = (data) => {
  return {
    type: FETCH_PRODUCTS_SUCCESS,
    payload: { products: data },
  };
};
export const addProduct = (type, nom, id, updatedNewData) => ({
  type: ADD_PRODUCT,
  payload: { type, nom, id, updatedNewData},
});

export const deleteProduct = (type, nom, id) => ({
  type: DELETE_PRODUCT,
  payload: { type, nom, id },
});

export const editProduct = (type, nom, id, updatedNewData) => ({
  type: EDIT_PRODUCT,
  payload: { type, nom, id, updatedNewData },
});


export const addCategory = (type,id, nom, displayType) => ({
  type: ADD_CATEGORY,
  payload: { type,id, nom, displayType},
});

export const deleteCategory = (type,id) => ({
  type: DELETE_CATEGORY,
  payload: { type, id,},
});

export const editCategory = (type, id, nom, displayType) => ({
  type: EDIT_CATEGORY,
  payload: { type, nom,id, displayType },
});

export const addType = (type,id, description) => ({
  type: ADD_TYPE,
  payload: { type,id, description},
});

export const deleteType = (type,id) => ({
  type: DELETE_TYPE,
  payload: { type, id},
});

export const editType = (type, id, description) => ({
  type: EDIT_TYPE,
  payload: { type, id, description },
});