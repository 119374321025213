import React, { useState } from "react";
import "./Sidebar.css";
import Logo from "../imgs/logo.png";
import { UilSignOutAlt, UilSetting,UilClipboard, UilClipboardAlt, UilClipboardBlank, UilClipboardNotes } from "@iconscout/react-unicons";
import { SidebarData } from "../Data/Data";
import { UilBars } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import { Modal, Box, Typography, Button, Select, MenuItem, ListItem, ListItemIcon, ListItemText, List } from "@mui/material";
import Parameter from './Paremeter/Parameter'
import { useNavigate } from 'react-router-dom';
import { removeItems } from "../actions/deleteItems";
import client from "../api/client";
import logo from './HomePage/logo.png'

import { UilRestaurant, UilArrowDown,UilArrowUp, UilChart  } from "@iconscout/react-unicons";

const Sidebar = ({ onMenuClick, socket}) => {
  const [selected, setSelected] = useState(0);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [expanded, setExpaned] = useState(true)
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const signOut = async () => {
    const token = JSON.parse((localStorage.getItem("token")));
    await client.post("/sign-out", { token }, {
      headers: {
        Accept: 'application/json',
        authorization: `JWT ${token}`,
      }
    }).then((res) => {
      if (res.data.success) {
        console.log("click...........sign out");
        removeItems();
        navigate('/');
      } else {

      }
    });
  };
  const sidebarVariants = {
    true: {
      left : '0'
    },
    false:{
      left : '-60%'
    }
  }
  const handleExpand = (index) => {
    console.log("expanded......", index)
    // Mettre à jour l'index de l'élément de menu actuellement étendu
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const handleSubmenuClick = (submenu) => {
    onMenuClick(submenu);
    setSelected(4)
  };
  /*const handleComptoirClick = ()=>{
    navigate('/comptoir/connexion');
  }*/
  const handleComptoirClick = () => {
    window.open('/comptoir/connexion', '_blank'); // Ouvre une nouvelle fenêtre/onglet
  };
  return (
    <>
      <div className="bars" style={expanded?{left: '60%'}:{left: '5%'}} onClick={()=>setExpaned(!expanded)}>
        <UilBars />
      </div>
      <motion.div className='sidebar'
      variants={sidebarVariants}
      animate={window.innerWidth<=768?`${expanded}`:''}
       style={{ backgroundColor: '#17174F' }}>
        {/* logo */}
        <div className="logo">
          <img  src={logo} alt="Logo"
          style={{width:'60%'}}
          />
          <span style={{ fontSize: "150%", verticalAlign: "sub", marginTop: "10%", marginLeft: '-30%', color: "#FFF" }}>umz</span>
        </div>

        <div className="menu">
          {SidebarData.map((item, index) => (
            <div key={index} onClick={() => {
              setSelected(index);
              if (item.list) {
                handleExpand(index);
              } else {
                onMenuClick(item.heading);
              }
            }} className={selected === index ? "menuItem active" : "menuItem"}>
              <item.icon />
              <span>{item.heading}</span>
              {item.list && (expandedIndex === index ? <UilArrowDown /> : <UilArrowUp />)}
            </div>
          ))}
        <List  component="div" disablePadding sx={{marginTop:'-10%'}}>
          {expandedIndex !== null && SidebarData[expandedIndex].list.map((submenu, index) => (
            <ListItem 
            key={index} 
            onClick={() => handleSubmenuClick(submenu.heading)}
            style={{ cursor: 'pointer' }}
            sx={{
              display: 'flex',
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              },
              '@media (max-width: 1200px) and (min-width: 768px)': {
                '.submenu-text': {
                  display: 'none',
                }
              }
            }}
          >
            <ListItemIcon>
              {React.createElement(submenu.icon, { style: { color: '#FFF', width: 16, marginLeft: 30 } })}
            </ListItemIcon>
            <ListItemText
              primary={submenu.heading}
              primaryTypographyProps={{ 
                style: { 
                  color: '#FFF', 
                  fontSize: 14 
                },
                className: 'submenu-text'
              }}
            />
          </ListItem>

          ))}
        </List> 
           {/* signoutIcon */}
           <div className="menuItemIcon">
            <UilSignOutAlt className="icon" onClick={signOut} />
            <UilSetting className="icon" onClick={handleOpenModal} />
            <UilClipboardAlt className="icon" onClick={handleComptoirClick} />
          </div>

        </div>

        {/* Submenu */}
        


      </motion.div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: '80%', bgcolor: "background.paper", boxShadow: 24, p: 4 }}>
          <Typography id="modal-modal-title" variant="h6" component="h3" sx={{ paddingBottom: '10px', color:'#17174F', fontWeight:'bold' }}>
            Paramètres
          </Typography>
          <Parameter />
        </Box>
      </Modal>
    </>
  );
};

export default Sidebar;
