import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import client from '../../api/client';
import { useNavigate } from 'react-router-dom';
import {CircularProgress, Backdrop } from '@mui/material';
import img from './caissiere.jpg'
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://yum-z.com/">
        Yumz
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// Thème par défaut (vous pouvez personnaliser le thème selon vos besoins)
const defaultTheme = createTheme();

export default function Login({socket}) {
    const [error, setError] = React.useState('')
    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const identifier = data.get('identifier');
    const access_code = data.get('access_code');
    try {
      setLoading(true); // Afficher la barre de chargement
        await client.post('/signRestorer', {identifier, access_code},{
          headers: {
              Accept: 'application/json',
              //'content-Type': 'multipart/form-data',
              //authorization : `JWT ${token}`,
            }
          }).then(async (res)=>{
          console.log(" res ",res.data)
          if(res.data.success){
            localStorage.setItem("identifier", identifier)
            localStorage.setItem("access_code", access_code)
            
            localStorage.setItem("tokenRestorer", JSON.stringify(res.data.token))
            localStorage.setItem("currencies", JSON.stringify(res.data.currencies))
            localStorage.setItem("restaurantId", JSON.stringify(res.data.restaurantId))
            const tablesNumbers = res.data.tablesNumber || 18
            const watingTime = res.data.watingTime || 15
            localStorage.setItem('tablesNumber', tablesNumbers)
            localStorage.setItem('watingTime', watingTime)
            navigate("/comptoir/commandes", {state: {
              orderRestorer: res.data.orderRestorer,
            }})
          }else{
            setError(res.data.error);
              // Clear the error message after 5 seconds
              setTimeout(() => {
                setError('');
              }, 3000);
          }
        })
    } catch (error) {
      
    }finally {
      setLoading(false); // Cacher la barre de chargement
    }
    
    
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(${img})`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Connexion
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="identifier"
                label="Identifiant" // Nouveau libellé pour le champ identifiant
                name="identifier" // Nouveau nom pour le champ identifiant
                autoComplete="identifier"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="access_code" // Nouveau nom pour le champ code d'accès
                label="Code d'accès" // Nouveau libellé pour le champ code d'accès
                type="password"
                id="access_code" // Id du champ code d'accès
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Se connecter
              </Button>
              {error&&error.length>0?(<div>
                {error}
              </div>):null}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </ThemeProvider>
  );
}
