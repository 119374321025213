import React, {useEffect, useState} from "react";
import Cards from "./Cards";
import Table from "./Table";
import {
    UilRefresh
} from "@iconscout/react-unicons";
import './Clients.css'
import client from "../../api/client";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { removeItems } from "../../actions/deleteItems";
import Filter from './Filter'
import {CircularProgress, Backdrop } from '@mui/material';


import './Clients.css'
import RefreshButton from "../../RefreshButton";
const Clients = ({socket}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const storedClients = localStorage.getItem("clients");
  const parsedClients = storedClients ? JSON.parse(storedClients) : null;

const clients = parsedClients && Object.keys(parsedClients).length > 0
  ? parsedClients
  : [{
    id: '',
    name: '',
    contact: '',
    visits: 0,
    totalSpend: 0,
    restaurantRating: 0,
    reviews: { date: '', hour: '', orderAmount: '', rating: '', comment: '' }
  }];
  const [rows, setRows] = React.useState(clients);
  const [filteredRows, setFilteredRows] = React.useState(clients);
  useEffect(() => {
    const handleRowClient = (data) => {
      // Assuming data contains client details, including the id
      const updatedClients = rows.map(client => {
        if (client.id === data.id) {
          // If the client id matches, update the client
          return {
            id: data.id,
            name: data.nom_client,
            contact: data.email || data.phoneNumber,
            visits: data.visits,
            totalSpend: data.totalSpent||0,
            restaurantRating: data.restaurantRating||0,
            reviews: data.ordersInformations||[],
          };
        }
        return client; // Return the original client if id doesn't match
      });

      setRows(updatedClients);
      localStorage.setItem("clients", JSON.stringify(updatedClients));
    };

    socket.on('client', handleRowClient);
    // Clean up the socket listener when the component unmounts
    return () => {
      socket.off('client', handleRowClient);
    };
  }, []);

  const refresh = async()=>{
    const token = JSON.parse((localStorage.getItem("token")))
    await client.post("/get-clients",{token},{
      headers: {
          Accept: 'application/json',
          //'content-Type': 'multipart/form-data',
          authorization : `JWT ${token}`,
        }
      }).then((res)=>{
        if(res.data.success){
          console.log("rowsRefresh....",res.data)
          setRows(res.data.rows)
          setFilteredRows(res.data.rows)
          localStorage.setItem("clients", JSON.stringify(res.data.rows))
        }else{
          removeItems()
          navigate("/")
        }
      })
  }
  
  const handleFiltersChange = (date, decimalValue) => {
    // Filter rows based on date and decimalValue
    let filteredData= []
     filteredData = rows.filter((row) => {
      console.log("row ........", row)

      if(row.id.length > 0&&date !== null){
        return row.reviews.some((review) => {
            // Extraire la partie date (jj/mm/aaaa) de review.date
            const reviewDate = review.date.split(' ')[0];
            // Comparer la date fournie et la date extraite de l'avis
            const isDateMatch = !date || date === reviewDate;
            const isDecimalMatch = decimalValue === 0 || parseFloat(review.rating) === decimalValue;
            return isDateMatch && isDecimalMatch;
        });        
      }
      else{
        return []
      }

  });
    console.log("filtre date, decimalValue ........", date, decimalValue, filteredData)

    setFilteredRows(filteredData);
  };
  useEffect(()=>{
    const decimalValue = localStorage.getItem('ratingClients')?parseFloat(JSON.parse(localStorage.getItem('ratingClients'))):0;
    const selectedDate = localStorage.getItem('dateClients') ? new Date(JSON.parse(localStorage.getItem('dateClients'))) : null;
    handleFiltersChange(selectedDate, decimalValue)
  }, [rows])
  return (
    <div className="Clients">
      <h1 style={{}}>Clients</h1>
       <div className="setting">
          <div className="filter">
            <Filter onFiltersChange={handleFiltersChange}/> 
          </div>
          <div>
          <RefreshButton onClick={refresh}/>
          </div>
       </div>
    <div>
        <Table rows={filteredRows}/>
    </div>
    </div>
  );
};



export default Clients;
