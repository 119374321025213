import React, { useState, useRef } from 'react';
import { Button, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CardCommandSmall from './CardCommandSmall';

const OrderSmallScreen = ({ orderRestorer=[], onDeleteItem2,onDeleteItem, currencies, restaurandId }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const containerRef = useRef(null);

  const handleCardClick = (item) => {
    if (item.note && item.note.length > 0) {
      console.log('Afficher la note :', item.note);
    }
  };

  return (
    <div style={{ position: 'relative', overflow: 'hidden', height: '90vh',overflowY: 'auto', /* Pour Chrome, Safari et Opera */
    width:'100%'
  }}>
     
        {orderRestorer.map((order) => (
          <CardCommandSmall order={order} currencies={currencies} onDeleteItem2={onDeleteItem2} onDeleteItem={onDeleteItem} restaurandId={restaurandId} />
        ))}
    </div>
  );
};

export default OrderSmallScreen;
