import React, { useEffect, useState, useRef } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import Table from "./Table";
import client from "../../../api/client";
import Cards from "./Cards";
import originalMoment from "moment";
import { extendMoment } from "moment-range";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import './Reviews.css'
import ChartComponent from "./ChartComponent";
const moment = extendMoment(originalMoment);
const formatDate = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};
const convertValueToMoment = (value) => {
  if (!value ||!value.start ||!value.end) {
    return null; // Retourne null si value ou les propriétés start/end sont absentes
  }

  // Convertit les dates start et end en objets Moment
  const start = moment(value.start, 'YYYY-MM-DD');
  const end = moment(value.end, 'YYYY-MM-DD');

  return moment.range(start, end); // Return an array of moment objects
};
const Reviews = () => {
  const [isOpen, setIsOpen] = useState(false);
  const storedValue = localStorage.getItem('selectedDateRange');
  const initialDateRange = {
    start: moment().clone().subtract(7, "days"),
    end: moment(),
  };

  const [value, setValue] = useState(storedValue ?convertValueToMoment(JSON.parse(storedValue)):moment.range(moment().clone().subtract(7, "days"), moment().clone()));


  // Initialisation des dates de début et de fin au format YYYY-MM-DD
  const initialStartDate = `${value.start.format("YYYY-MM-DD")}`;
  const initialEndDate = `${value.end.format("YYYY-MM-DD")}`;

  const [date, setDate] = useState(storedValue ? JSON.parse(storedValue) : { start: initialStartDate, end: initialEndDate });
  const [numberOfReviews, setNumberOfReviews] = useState(0)
  const [rating, setRating] = useState(0)
  const [reviews, setReviews] = useState([])
  const [pieChartData, setpieChartData] = useState({})
  const [barChartData, setbarChartData] = useState({})
  const saveSelectedDateRange = (start, end) => {
    localStorage.setItem(
      'selectedDateRange',
      JSON.stringify({start, end})
    );
  };
  const pickerRef = useRef();

  const handleClickOutside = (event) => {
    if (pickerRef.current && !pickerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  const onSelect = (values, states) => {
    setValue(values);
    const dateStart = new Date(values.start._d);
    const dateEnd = new Date(values.end._d);
  
    // Conversion des dates en format DD/MM/AAAA
    const formattedDate = `${dateStart.getFullYear()}-${(dateStart.getMonth() + 1).toString().padStart(2, '0')}-${dateStart.getDate().toString().padStart(2, '0')}`;
    const formattedDateEnd = `${dateEnd.getFullYear()}-${(dateEnd.getMonth() + 1).toString().padStart(2, '0')}-${dateEnd.getDate().toString().padStart(2, '0')}`;
    setDate({ start: formattedDate, end: formattedDateEnd });
    saveSelectedDateRange(formattedDate, formattedDateEnd);

  };

  const onToggle = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    console.log("open....",isOpen)
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);
  useEffect(() => {
    const fetch = async () => {
      const token = JSON.parse((localStorage.getItem("token")))
      if (token) {
        await client.post('/get-AllReviews', { token, date: { start: date.start, end: date.end } }, {
          headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
            authorization: `JWT ${token}`,
          }
        }).then((res) => {
          if (res.data.success) {
            setNumberOfReviews(res.data.numberOfReviews)
            setRating(res.data.rating)
            setReviews(res.data.reviews)
            setpieChartData(res.data.pieChartData)
            setbarChartData(res.data.barChartData)
          }
        })
      }
    }
    fetch()
  }, [value])

  useEffect(() => {

  }, [numberOfReviews, rating, reviews, date])
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };

  return (
    <>
      {isScreenSmall()?
        <div>
          <h1>Avis</h1>
          <Cards numberOfReviews={numberOfReviews} rating={rating} />
          <div stle={{display:'flex', justifyContent:'center', alignItems:'center', alignSelf:'center'}}>
            <input 
              type="button" 
              value={`${date.start} - ${date.end}`} 
              onClick={onToggle}
            />
            {isOpen && (
              <div ref={pickerRef} >
                <DateRangePicker
                  value={value}
                  onSelect={onSelect}
                  singleDateRange={true}
                />
              </div>
            )}
          </div>
          <ChartComponent pieChartData={pieChartData} barChartData={barChartData} />
          <Table data={reviews} />
      </div>
      :
        <div className="reviews" style={{ overflowY: 'auto', /* Pour Chrome, Safari et Opera */ maxHeight: '100vh',
         /* Pour Chrome, Safari et Opera */
         }}>
      <h1 style={{marginTop:'6%'}}>Avis</h1>
      <Cards numberOfReviews={numberOfReviews} rating={rating} />
      <div style={{ marginLeft: '70%' }}>
        
        <div>
          <input type="button" value={`${date.start} - ${date.end}`} onClick={onToggle}
            style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', backgroundColor: 'rgb(43, 48, 123, 0.05)' }}
           />
        </div>

        {isOpen && (
            <div ref={pickerRef} style={{ position: 'absolute', zIndex: 9999, backgroundColor: 'white', }}>
            <DateRangePicker
              value={value}
              onSelect={onSelect}
              singleDateRange={true}
            />
          </div>
          
        )}
      </div>
      <ChartComponent pieChartData={pieChartData} barChartData={barChartData} />
      <Table data={reviews}/>
    </div>
      }
    </>
    
  );
};

export default Reviews;
