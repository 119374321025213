import React, { useState, useEffect } from 'react';
import { Modal, Typography, TextField,MenuItem, IconButton, Box, Card, CardMedia, CardContent, Button, Grid, Paper, Dialog, CircularProgress, Backdrop, Select, FormControl, InputLabel } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import client from '../../../api/client';
import ProductCard from './ProductCard';
import { useNavigate } from 'react-router-dom';

const CreateOrderModal = ({ open, onClose, onSave, currencies }) => {
  const [searchText, setSearchText] = useState('');
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const restaurantIdJson = localStorage.getItem("restaurantId");
  const [restaurantId, setRestaurantId] = useState(restaurantIdJson ? JSON.parse(restaurantIdJson) : null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [error, setError] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    email: '',
    tableNumber: '',
    option:'Sur place'
  });
  const [loading, setLoading] = useState(false);
  const tablesNumber = parseInt(localStorage.getItem('tablesNumber') !== 'undefined' ? localStorage.getItem('tablesNumber') : 18)
  console.log("tablesNumber....",tablesNumber)
  const handleOptionChange = (e) => {
    setCustomerInfo({ ...customerInfo, option: e.target.value });
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (restaurantId) {
      fetchRestaurantInfo(restaurantId);
    }
  }, []);

  useEffect(() => {
    if (open && selectedType) {
      setProducts(selectedType.data.flatMap(subcategory => subcategory.dataProduct));
    }
  }, [open, selectedType]);

  const fetchRestaurantInfo = async (restaurantId) => {
    try {
      const token = JSON.parse(localStorage.getItem("tokenRestorer"));
      if (token) {
        setLoading(true); // Afficher la barre de chargement
        await client.post('/getProducts', { restaurantId }, {
          headers: {
            Accept: 'application/json',
            Authorization: `JWT ${token}`,
          }
        }).then((res) => {
          console.log("fecth restaurant....", res.data)
          if (res.data.success) {
            setTypes(res.data.data);
            if (res.data.data.length > 0) {
              setSelectedType(res.data.data[0]);
              setProducts(res.data.data[0].data.flatMap(subcategory => subcategory.dataProduct));
            }
          }
        })
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des informations du restaurant :', error);
    }finally {
      setLoading(false); // Cacher la barre de chargement
    }
  };

  const fetchProducts = async (query) => {
    try {
      const token = JSON.parse(localStorage.getItem("tokenRestorer"));
      if (token && restaurantId) {
        const res = await client.post('/getProduct', { restaurantId, productName: query }, {
          headers: {
            Accept: 'application/json',
            Authorization: `JWT ${token}`,
          }
        });
        if (res.data.success) {
          setProducts(res.data.products);
        } else {
          navigate('/comptoir/connexion');
        }
      } else {
        navigate('/comptoir/connexion');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des produits :', error);
    }
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...items];
    updatedItems[index].quantity = newQuantity;
    setItems(updatedItems);
    updateTotalPrice(updatedItems);
  };

  const handleAddProduct = (product) => {
    if (product.uniqueSelected.length === 0 && product.multipleSelected.length === 0 && product.supplement.length === 0) {
      const existingProductIndex = items.findIndex(item => item.id_product === product.id);
      if (existingProductIndex >= 0) {
        handleQuantityChange(existingProductIndex, items[existingProductIndex].quantity + 1);
      }
    }
    setSelectedProduct(product);
  };

  const updateTotalPrice = (items) => {
    const newTotal = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
    setTotalPrice(newTotal);
  };

  const handleSave = async () => {
    const { name, email, tableNumber } = customerInfo;
    if (name.length > 2 && /\S+@\S+\.\S+/.test(email) && !isNaN(tableNumber)&&/^\d*$/.test(tableNumber)) {
      const token = JSON.parse(localStorage.getItem("tokenRestorer"));
      if (token && restaurantId) {
        try {
  await client.post('/createClientAndOrder',{cartItems:items, nom_client:customerInfo.name,email:customerInfo.email,id_restaurant: restaurantId, token, tableNumber:customerInfo.tableNumber, price:totalPrice, onPlace:customerInfo.option=='Sur place'?true:false},{
            headers: {
                Accept: 'application/json',
                //'content-Type': 'multipart/form-data',
                authorization : `JWT ${token}`,
              }
            }).then((res)=>{
                if(res.data.success){
                  setCartItems([])
                  setItems([])
                  setCustomerInfo({
                    name: '',
                    email: '',
                    tableNumber: '',
                    option:'Sur place'
                  })
                  //onSave();
                  onClose();
                  setShowConfirmationModal(false)
                  setTotalPrice(0)
                  setSelectedProduct(null)
                }else{
                  
                }
            })          
        } catch (error) {
          
        }
        
      }
      /**
       * 
       */
      
    } else {
      let errorMessage = '';
      if (name.length <= 2) errorMessage += 'Le nom doit contenir plus de 2 caractères. ';
      if (!/\S+@\S+\.\S+/.test(email)) errorMessage += 'Email invalide. ';
      if (isNaN(tableNumber)) errorMessage += 'Le numéro de table doit être un chiffre. ';
      else{
        errorMessage += 'Le numéro de table doit être valide ';
      }
      setError(errorMessage);
      setTimeout(()=>{setError(null)}, 3500)
    }
  };

  useEffect(() => {
    if (searchText.trim()) {
      fetchProducts(searchText);
    } else if (selectedType) {
      setProducts(selectedType.data.flatMap(subcategory => subcategory.dataProduct));
    } else {
      setProducts([]);
    }
  }, [searchText, selectedType]);

  const handleRemoveProduct = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    updateTotalPrice(updatedItems);
  };

  useEffect(() => {
    console.log('Type.....', types)
  }, [types]);

  const handleSaveProduct = (cartItem) => {
    const updatedItems = [...items, cartItem];
    updateTotalPrice(updatedItems)
    setItems(updatedItems);
  };

  const handleGetCustomerInformation = () => {
    setShowConfirmationModal(true);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ padding: '20px', color: 'black', backgroundColor: 'white', margin: '10% auto', width: '80%', borderRadius: '8px', maxHeight: '70%', overflowY: 'auto', /* Pour Chrome, Safari et Opera */
     /* Pour Chrome, Safari et Opera */ }}>
        <Typography variant="h6" align="center">Créer une commande</Typography>
        <TextField
          label="Rechercher un produit"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          fullWidth
          margin="normal"
        />
        <Box sx={{ display: 'flex', overflowX: 'auto', margin: '20px 0' }}>
          {types.map((type) => (
            <Typography
              key={type.id}
              onClick={() => setSelectedType(type)}
              sx={{
                cursor: 'pointer',
                padding: '10px',
                textDecoration: selectedType?.id === type.id ? 'underline' : 'none',
                color: selectedType?.id === type.id ? 'blue' : 'black',
                marginRight: '10px'
              }}
            >
              {type.type}
            </Typography>
          ))}
        </Box>
        <Grid container spacing={2}>
          {products.map((product) => (
            <Grid item xs={12} sm={6} md={4} key={product.id}>
              <Card>
                <CardMedia
                  component="img"
                  height="140"
                  image={product.image}
                  alt={product.name}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {product.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product.price} {currencies.symbol}
                  </Typography>
                  <IconButton onClick={() => handleAddProduct(product)}>
                    <AddCircleIcon />
                  </IconButton>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Paper elevation={3} sx={{ padding: '10px', marginTop: '20px' }}>
          <Typography variant="h6">Commande</Typography>
          {items.map((item, index) => (
            <Box key={item.id} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '10px 0' }}>
              <Typography>{item.name}</Typography>
              <Typography>{item.quantity} x {item.price} {currencies.symbol}</Typography>
              <Typography>Total: {item.quantity * item.price} {currencies.symbol}</Typography>
              <div>
                <IconButton onClick={() => handleQuantityChange(index, item.quantity - 1)}>
                  <RemoveCircleIcon />
                </IconButton>
                <TextField value={item.quantity} onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))} style={{ width: '50px' }} />
                <IconButton onClick={() => handleQuantityChange(index, item.quantity + 1)}>
                  <AddCircleIcon />
                </IconButton>
                <IconButton onClick={() => handleRemoveProduct(index)}>
                  <RemoveCircleIcon color="secondary" />
                </IconButton>
              </div>
            </Box>
          ))}
          <Typography variant="h6">Prix Total : {totalPrice} {currencies.symbol}  </Typography>
        </Paper>
        {items.length > 0 ?
          <Button onClick={handleGetCustomerInformation} variant="contained" color="primary" fullWidth sx={{ marginTop: '20px' }}>Enregistrer</Button> : null
        }
        {selectedProduct && (
          <Dialog open={!!selectedProduct} onClose={() => setSelectedProduct(null)}>
            <ProductCard product={selectedProduct} onAddToCart={handleSaveProduct} onClose={() => setSelectedProduct(null)} currencies={currencies} />
          </Dialog>
        )}
        {showConfirmationModal && (
          <Dialog open={showConfirmationModal} onClose={() => setShowConfirmationModal(false)}>
            <Box sx={{ padding: '20px', width: '300px' }}>
              <Typography variant="h6" align="center">Informations du client</Typography>
              <TextField
                label="Nom"
                value={customerInfo.name}
                onChange={(e) => setCustomerInfo({ ...customerInfo, name: e.target.value })}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Email"
                value={customerInfo.email}
                onChange={(e) => setCustomerInfo({ ...customerInfo, email: e.target.value })}
                fullWidth
                margin="normal"
              />
              <FormControl fullWidth margin="normal">
      <InputLabel id="table-number-label">Numéro de table</InputLabel>
      <Select
        labelId="table-number-label"
        id="table-number"
        value={customerInfo.tableNumber}
        onChange={(e) => setCustomerInfo({ ...customerInfo, tableNumber: e.target.value })}
        label="Numéro de table"
      >
        {[...Array(tablesNumber).keys()].map((number) => (
          <MenuItem key={number + 1} value={number + 1}>
            {number + 1}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
              <TextField
                select
                label="Option"
                value={customerInfo.option}
                onChange={handleOptionChange}
                fullWidth
                margin="normal"
              >
            <MenuItem value="Emporter">Emporter</MenuItem>
            <MenuItem value="Sur place">Sur place</MenuItem>
          </TextField>
              {error && <Typography color="error">{error}</Typography>}
              <Button onClick={handleSave} variant="contained" color="primary" fullWidth sx={{ marginTop: '20px' }}>Valider la commande</Button>
            </Box>
          </Dialog>
        )}
        <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      </Box>
      
    </Modal>
  );
};

export default CreateOrderModal;
