export const conversionData = (newData) => {
    const sections = [];

    if (newData && newData.uniqueSelected) {
        console.log("type data 1",  newData.uniqueSelected)
      newData.uniqueSelected.map((unique) => {
        console.log("data ",unique)
        sections.push({
          type: 'unique',
          nameCat: unique.nameCat || '',
          required:unique?.required ? unique?.required:false,
          elements: unique.data.map(item => ({ name: item.name, price: item.price || 0 })),
        });
      });
    }
  
    if (newData && newData.multipleSelected) {
      newData.multipleSelected.map((multiple) => {
        sections.push({
          type: 'multiple',
          nameCat: multiple.nameCat || '',
          numSelect: multiple.numSelect || 0,
          required:multiple?.required ? multiple?.required:false,
          elements: multiple.data.map(item => ({ name: item.name, price: item.price || 0 })),
        });
      });
    }
  
    if (newData && newData.supplement) {
      newData.supplement.map((supplement) => {
        sections.push({
          type: 'supplement',
          nameCat: supplement.nameCat || '',
          required:supplement?.required ? supplement?.required:false,
          elements: supplement.data.map(item => ({ name: item.name, price: item.price || 0 })),
        });
      });
    }
  
    return sections;
  };