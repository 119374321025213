import React, { useState,useEffect } from "react";
import Cards from "../Cards/Cards";
import Table from "../Table/Table";
import "./MainDash.css";
import RestaurantRegister from "./RestaurantRegister";
import ValidEmail from "./ValidEmail";
import { Modal } from "@mui/material"; // Assurez-vous d'importer le composant Modal de Material-UI
import { useNavigate } from 'react-router-dom';
import { removeItems } from "../../actions/deleteItems";

import {CircularProgress, Backdrop } from '@mui/material';
import {
  UilRefresh
} from "@iconscout/react-unicons";
import client from "../../api/client";
import RefreshButton from "../../RefreshButton";


function setCurrentDate(){
  return new Date(Date.now()).getUTCDate();
}

const MainDash = ({socket}) => {
  // Vérifier si l'email est valide
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);

  const manager = JSON.parse(localStorage.getItem("manager"))

  const token = JSON.parse(localStorage.getItem("token"))

  const [isEmailValid, setIsEmailValid] = useState(manager?.is_valid_email||false)
  const [isRestaurant, setIsRestaurant] = useState(manager?.is_restaurant||false)

  const [values, setValues] = useState([0,0,0])
  const [rows, setRows] = useState([])
  const [rating, setRating] = useState(0)
  const [refresh, setRefresh] = useState(true)

  const updateEmail = ()=>{setIsEmailValid(true)}

  const updateRestaurant = ()=>{setIsRestaurant(true)}
  // Afficher le composant ValidEmail dans un Modal si l'email n'est pas valide
  const emailModalContent = isEmailValid ? null : <ValidEmail manager={manager} token={token} updateEmail={updateEmail}/>;
  const emailModal = isEmailValid ? null : (
    <Modal open={!isEmailValid} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <Modal open={!isEmailValid} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ width: '40%', height: '40%', backgroundColor: 'white', borderRadius: '15px', padding: '20px' }}>
          {emailModalContent}
        </div>
      </Modal>

    </Modal>
  );
  // Afficher le composant RestaurantRegister dans un Modal si le manager est un restaurant
  const restaurantModalContent = isRestaurant ?null: <RestaurantRegister manager={manager} token={token} updateRestaurant={updateRestaurant} socket={socket} />;
  const restaurantModal = isRestaurant ?null:(
    <Modal open={!isRestaurant} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <div style={{ width: '50%', height: '90%', borderRadius: '15px',}}>
        {restaurantModalContent}
      </div>
    </Modal>
  );

  const filterRows = () => {
    const currentTime = new Date().getTime(); // Get current timestamp
  
    const filteredRows = rows.filter((row) => {
      const rowTimeStr = `${new Date().toDateString()}T${row.hour}:00`;
      const rowTime = new Date(rowTimeStr).getTime();
  
      // Calculate time difference in milliseconds
      const timeDifference = currentTime - rowTime;
  
      console.log("current time:", currentTime, "row time:", rowTime, "time difference:", timeDifference);
  
      // Keep rows that are less than or equal to 24 hours old
      return timeDifference <= 24 * 60 * 60 * 1000;
    });
  
    setRows(filteredRows);
  };
  
  const socketConnexion = async () => {
    // Convertir la chaîne JSON en objet JavaScript
    socket.connect()
    const restaurant = JSON.parse(localStorage.getItem("restaurant"))
    const managerRoom = `manager-${restaurant._id}`;
    socket.emit('joinRoom', managerRoom);       
}
  useEffect(() => {
    const incrementFirstValue = (price) => {
      setValues(prevValues => {
        const newValues = [...prevValues];
        // Vérifier si newValues[0] est déjà un nombre flottant
        const currentValue = parseFloat(newValues[0]);
        if (!isNaN(currentValue)) { // Vérifier si la conversion en float est réussie
          // Si newValues[0] est déjà un nombre flottant, incrémentez-le
          newValues[0] = (currentValue + parseFloat(price)).toFixed(2);
        } else {
          // Si newValues[0] n'est pas un nombre flottant, initialisez-le avec le prix
          newValues[0] = parseFloat(price).toFixed(2);
        }
        return newValues;
      });      
    };    
    const incrementSecondValue = () => {
      setValues(prevValues => {
        const newValues = [...prevValues];
        newValues[1]++;
        return newValues;
      });
    };
    const incrementThirdValue = () => {
      setValues(prevValues => {
        const newValues = [...prevValues];
        newValues[2]++;
        return newValues;
      });
    };
            
    socket.on('newClient', (data) =>{
      //incrementSecondValue()
    })
    // Listen for new information in the room
    socket.on('newOrder',(data) => {
      const {orderData, newClient} = data
      console.log("Une nouvelle commande ",orderData.clientId)
      setRows((prevRows) => [...prevRows, orderData]);
      const clientIdExists = rows.some(row => row.clientId === orderData.clientId);
        if (!clientIdExists) {
          incrementSecondValue()
        }
    })
    
    socket.on('orderStatusUpdate', (data) => {
      const { orderId, newStatus, time } = data;
      console.log("socket new order ",data)
      rows.map((row) => {
        if (row.orderId === orderId) {
          console.log("orderId.................incrementLastValueInLastArray ", orderId)
          incrementThirdValue()
        }
      });

      // Update the rows array
      const updatedRows = rows.map((row) => {
        if (row.orderId === orderId) {
          console.log("orderId.................orderStatusUpdate 2", orderId)
          return {
            ...row,
            status: newStatus,
            time: time,
          };
        }
        return row; // Return the original row if orderId doesn't match
      });
      console.log("orderId.................orderStatusUpdate 2", updatedRows)

      // Update the state with the new array
      setRows(updatedRows);
    });

    socket.on('orderTypePaymentUpdate', (data) => {
      const { orderId, typePayment,newStatus } = data;

      // Update the rows array
      const updatedRows = rows.map((row) => {
        if (row.orderId === orderId) {
          return {
            ...row,
            status: newStatus,
            type: typePayment,
          };
        }
        return row;
      });

      // Update the state with the new array
      setRows(updatedRows);
      rows.map((row) => {
        if (row.orderId === orderId) {
          incrementFirstValue(row.price)
          // If the orderId matches, update type
        }
  });
    });
    return () => {
      socket.off()
    };
  }, [rows]);
  
  useEffect(
    () => {
    const refresh = async()=>{
      try {
        const token = JSON.parse((localStorage.getItem("token")))
      setLoading(true); // Afficher la barre de chargement

        if(token){

            await client.post('/get-mainData',{token},{
            headers: {
                Accept: 'application/json',
                //'content-Type': 'multipart/form-data',
                authorization : `JWT ${token}`,
              }
            }).then((res)=>{
              if(res.data.success){
                console.log("token......", res.data)
                socketConnexion()
                setRows(res.data.todayOrders)
                setValues(res.data.mainCardsData)
                setRating(res.data.rating)
              }else{
                removeItems()
                navigate("/")
              }
            })
        }
      } catch (error) {
        
      }finally {
        setLoading(false); // Cacher la barre de chargement
      }
      
      
      }
      
    refresh()
  }, []);
  /*useEffect(() => {
    const refresh = async()=>{
      const token = JSON.parse((localStorage.getItem("token")))
      if(token){
            await client.post('/get-mainData',{token},{
            headers: {
                Accept: 'application/json',
                //'content-Type': 'multipart/form-data',
                authorization : `JWT ${token}`,
              }
            }).then((res)=>{
              if(res.data.success){
                console.log("token......", res.data)
                setRows(res.data.todayOrders)
                setValues(res.data.mainCardsData)
                setRating(res.data.rating)
                socketConnexion()
              }else{
                removeItems()
                navigate("/")
              }
            })
        }
      }
      
    refresh()
  }, [refresh]);*/
  const handleSetRefresh = async()=>{
    try {
      const token = JSON.parse((localStorage.getItem("token")))
      setLoading(true); // Afficher la barre de chargement
      if(token){
            await client.post('/get-mainData',{token},{
            headers: {
                Accept: 'application/json',
                //'content-Type': 'multipart/form-data',
                authorization : `JWT ${token}`,
              }
            }).then((res)=>{
              if(res.data.success){
                console.log("token......", res.data)
                setRows(res.data.todayOrders)
                setValues(res.data.mainCardsData)
                setRating(res.data.rating)
                socketConnexion()
              }else{
                removeItems()
                navigate("/")
              }
            })
        }
    } catch (error) {
      
    }finally {
      setLoading(false); // Cacher la barre de chargement
    }
      
  }


  return (
    <div className="MainDash">
      <h1 style={{top:'15%'}}>Tableau de bord </h1>
    <div className="cards" style={{ width: '80%',}}>
    <Cards value={values} socket={socket} style={{ marginRight: '10px' }} />
    </div>
    {/*<div style={{ width: '20%' }}>
    <Rating rating={rating} />
    </div>*/}
    <h3 style={{ display: 'flex', alignItems: 'center' }}>
    Commandes récentes
    <RefreshButton onClick={handleSetRefresh} />
  </h3>

    <div className="tableDash" >
        <Table rows={rows} sokcet={socket}  />
    </div>
      {emailModal}
      {restaurantModal}
    </div>
  );
};
const styles = {
  button: {
  },
};

export default MainDash;
