import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './Banner.css';

const Banner = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className="banner">
      <p>Yumz est en version bêta. L'utilisation est gratuite 🎉 pendant 1 mois sans carte de crédit en échange de votre feedback pour améliorer nos services.</p>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        style={{
          position: 'absolute',
          right: '10px',
          color: 'white',
        }}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default Banner;
