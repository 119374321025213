import React, { useRef } from 'react';
import { Button } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import CardCommandPayment from './CardCommandPayment';

const PaymentsBigScreen = ({ orderRestorer, onDeleteItem2, onDeleteItem, restaurantId, currencies,onDeleteItemPayment }) => {
  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -200,
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 200,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div style={{ position: 'relative', overflow: 'hidden', width: '100%', maxWidth: '100%' }}>
      <Button
        onClick={scrollLeft}
        style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
          zIndex: 2,
        }}
      >
        <ArrowLeft sx={{ fontSize: 36 }} />
      </Button>
      <Button
        onClick={scrollRight}
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)',
          zIndex: 2,
        }}
      >
        <ArrowRight sx={{ fontSize: 36 }} />
      </Button>

      <div
        ref={containerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          maxWidth: 'calc(100vw - 100px)', // Utilisation de 100vw pour spécifier la largeur de la fenêtre visible
          scrollBehavior: 'smooth',
          padding: '1rem 0',
          margin: '0 50px', // Ajout de marges pour laisser de la place aux boutons
        }}
      >
        {orderRestorer.map((order) => (
          <CardCommandPayment
            key={order.id}
            order={order}
            currencies={currencies}
            onDeleteItemPayment={onDeleteItemPayment}
            restaurantId={restaurantId}
          />
        ))}
      </div>
    </div>
  );
};

export default PaymentsBigScreen;
