import React, { useState, useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Modal, Typography } from '@mui/material';
import client from '../../../api/client';
const CardCommandSmallPayments = (  { order, currencies }
  ) => {
const [elapsedMinutes, setElapsedMinutes] = useState(0);

  const [paymentType, setPaymentType] = useState('');

  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value);
  };
  return (
    <div
            key={order.orderId}
            style={{
              maxWidth: '100%', // La carte prend la largeur complète de son conteneur
              marginRight: '20px',
              marginBottom: '20px',
              flexDirection: 'column',
              display: 'flex',
              borderRadius: '10px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
              overflow: 'hidden',
              height: '100%',
              padding: '15px',
              backgroundColor : order.status !== 'En attente' ? 'rgba(0, 128, 0, 0.3)' : 'white'
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: '10px', textDecoration: 'underline' }}>
        <Typography variant="h9">{order.nom_client} - {`Tab. ${order.numberOfTable}`}</Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: '10px' }}>
        <Typography variant="body2">{order.onPlace ? 'Sur Place' : 'Emporter'}</Typography>
      </div>
      <div style={{ marginBottom: '10px' }}>
        {order.order.map((item, itemIndex) => (
          <div key={itemIndex} style={{ display: 'flex', flexDirection: 'column', marginBottom: '3%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight="bold">
                {item.name}
              </Typography>
              <div style={{ flex: 1, borderBottom: '1px dotted black', margin: '0 10px' }}></div>
              <Typography variant="body2" fontWeight="bold">
                {`x${item.quantity}`}
              </Typography>
            </div>
            {item.unique_selections && item.unique_selections.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                {item.unique_selections.map((sel) => sel.name).join(', ')}
              </Typography>
            )}
            {item.multiple_selections && item.multiple_selections.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                {item.multiple_selections.map((sel) => sel.name).join(', ')}
              </Typography>
            )}
            {item.supplements && item.supplements.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '5px', fontWeight: 400 }}>
                {item.supplements.map((sup) => `${sup.quantity}x ${sup.name}`).join(', ')}
              </Typography>
            )}
            {item.note && item.note.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                Note : {item.note}
              </Typography>
            )}
          </div>
        ))}
      </div>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
        <Typography variant="body2" fontWeight="bold">Prix Total : {`${order.price} ${currencies.symbol}`}</Typography>
      </div>
    </div>
  );
};

export default CardCommandSmallPayments;
