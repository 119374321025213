import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import Divider from '@mui/joy/Divider';
import CardOverflow from '@mui/joy/CardOverflow';
import Typography from '@mui/joy/Typography';
import { AspectRatio } from '@mui/joy';
import { currencies } from '../../../actions/currencies';
import {
    UilUsersAlt, 
} from "@iconscout/react-unicons";
export default function TopProducts({ products=[] }) {
  return (
    <Card sx={{ borderRadius: 0, width: 300, maxWidth: '100%',         background: "rgb(43, 48, 123, 0.1)",
  }}>
      <CardContent>
        <Typography level="title-lg" sx={{color:'#17174F'}}>Top 3 produits</Typography>
      </CardContent>
      {products?.map((product, index) => (
        <React.Fragment key={index}>
          <Card
            orientation="horizontal"
            size="sm"
            sx={{ bgcolor: 'background.surface',background: "rgb(43, 48, 123, 0.1)"
, borderRadius: 0, mb: 1 }}
          >
            <CardOverflow>
              <AspectRatio
                ratio="1"
                sx={{ minWidth: 70, '& img[data-first-child]': { p: 1.5 }, }}
              >
                <img
                  src={product.image}
                  loading="lazy"
                  alt=""
                  style={{backgroundColor:'rgb(43, 48, 123, 0.5)', height:'100%' }}
                />
              </AspectRatio>
            </CardOverflow>
            <CardContent>
              <Typography level="title-md" sx={{color:'#17174F'}}>{`${product.position}. ${product.name}`}</Typography>
              <Typography level="body-sm" sx={{color:'#17174F'}}>{`Gain: ${product.totalGain.toFixed(2)} ${currencies().symbol}`} <UilUsersAlt size={15} /> :  {`${product.numberClients}`}</Typography>
            </CardContent>
          </Card>
          {index !== products.length - 1 && <Divider />}
        </React.Fragment>
      ))}
    </Card>
  );
}
