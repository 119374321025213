import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  EDIT_PRODUCT,
  ADD_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  ADD_TYPE,
  EDIT_TYPE,
  DELETE_TYPE,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCTS_SUCCESS
} from "../actions/productActions";

// initialState data =  [{type,description data:[{nom,description(optionnel), dataCat{horizontal,vertical,dataProduct:[]}}]}]

const storedData = localStorage.getItem("data");
const parsedData = storedData ? JSON.parse(storedData) : null;
export const initialState = {
  data:[] 
};

export const productReducer =(state = initialState, action) => {
  
  console.log("dans le reducer",action.type, action.payload);
  
  switch (action.type) {
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.payload.products,
        error: null,
      };
    case FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    case ADD_PRODUCT:
      
    console.log("Handling ADD_PRODUCT");
    const existingCategoryIndex = state.data.findIndex(
      (category) => category.id === action.payload.type.id_type
    );

    const existingSubcategoryIndex = state.data[existingCategoryIndex].data.findIndex(
      (subcategory) => subcategory.id === action.payload.type.id_subcategory
    );

    if (existingSubcategoryIndex !== -1) {
      console.log("Updating existing subcategory");
      const updatedState = {
        ...state,
        data: state.data.map((category, categoryIndex) => {
          if (categoryIndex === existingCategoryIndex) {
            const updatedCategory = {
              ...category,
              data: category.data.map((subcategory, subcategoryIndex) => {
                if (subcategoryIndex === existingSubcategoryIndex) {
                  const updatedSubcategory = {
                    ...subcategory,
                    dataProduct: [
                      ...subcategory.dataProduct,
                      action.payload.type.updatedNewData,
                    ],
                  };
                  return updatedSubcategory;
                } else {
                  return subcategory;
                }
              }),
            };
            return updatedCategory;
          } else {
            return category;
          }
        }),
      };
      return updatedState;
    }

    console.log("Adding new category or subcategory");
    const newState = {
      ...state,
      data: [
        ...state.data,
        {
          id: action.payload.type.id_type,
          data: [
            {
              id:action.payload.type.id_subcategory,
              nom: action.payload.type.nom,
              dataProduct: [action.payload.type.updatedNewData],
            },
          ],
        },
      ],
    };

  
    return newState;

    case DELETE_PRODUCT:
      console.log("Handling DELETE_PRODUCT");
      const updatedStateAfterDelete = {
        ...state,
        data: state.data.map((category) =>
          category.id === action.payload.type.id_type
            ? {
                ...category,
                data: category.data.map((subcategory) =>
                  subcategory.id === action.payload.type.id_subcategory
                    ? {
                        ...subcategory,
                        dataProduct: subcategory.dataProduct.filter(
                          (product) => product.id !== action.payload.type.id
                        ),
                      }
                    : subcategory
                ),
              }
            : category
        ),
      };


  return updatedStateAfterDelete;

  case EDIT_PRODUCT:
    console.log("Handling EDIT_PRODUCT");
    
    const updatedStateAfterEdit = {
      ...state,
      data: state.data.map((category) =>
        category.id === action.payload.type.id_type
          ? {
              ...category,
              data: category.data.map((subcategory) =>
                subcategory.id === action.payload.type.id_subcategory
                  ? {
                      ...subcategory,
                      dataProduct: subcategory.dataProduct.map((product) =>
                        product.id === action.payload.type.id
                          ? { ...product, ...action.payload.type.updatedNewData }
                          : product
                      ),
                    }
                  : subcategory
              ),
            }
          : category
      ),
    };
    return updatedStateAfterEdit;
  
    case ADD_CATEGORY:
      console.log("Handling ADD_CATEGORY");
      
      const updatedStateAfterAddCategory = {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.type.idType) {
            const updatedItem = {
              ...item,
              data: [
                ...item.data,
                {
                  id: action.payload.type.id,
                  nom: action.payload.type.nom,
                  display: action.payload.type.display,
                  position: action.payload.type.displayType,
                  dataProduct: [],
                },
              ],
            };
    
            
    
            return updatedItem;
          }
          return item;
        }),
      };
      
    
      return updatedStateAfterAddCategory;
    
      case DELETE_CATEGORY:
        return {
          ...state,
          data: state.data.map((item) => {
            if (item.id === action.payload.type.id_type) {
              const updatedData = item.data.filter((cat) => cat.id !== action.payload.type.id);
              console.log("Filtered data after deletion:", updatedData);

              return {
                ...item,
                data: updatedData,
              };
            }
            return item;
          }),
        };

      case EDIT_CATEGORY:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.type.idType) {
            console.log(
              action.payload.type.display,
              action.payload.type.displayType
            );
            const updatedState = {
              ...item,
              data: item.data.map((cat) => {
                if (cat.id === action.payload.type.id) {
                  const updatedCategory = {
                    ...cat,
                    nom: action.payload.type.nom,
                    display: action.payload.type.display,
                    dataCat: {
                      position: action.payload.type.displayType,
                      dataProduct: cat.dataProduct,
                    },
                  };
                  console.log("Updated category:", updatedCategory);

                  return updatedCategory;
                }
                return cat;
              }),
            };
          
            return updatedState;
          }
          return item;
        }),
      };

      case ADD_TYPE:
      return {
        ...state,
        data: [
          ...state?.data,
          {
            image:action.payload.type.image,
            type: action.payload.type.type,
            id: action.payload.type?.id,
            description: action.payload.type.description,
            data: [],
          },
        ],
      };

      case DELETE_TYPE:
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.type.id),
      };

      case EDIT_TYPE:
      return {
        ...state,
        data: state.data.map((item) => {
          if (item.id === action.payload.type.id) {
            const updatedType = {
              ...item,
              image:action.payload.type.image,
              type: action.payload.type.type,
              id: action.payload.type.id,
              display:action.payload.type.showCategory,
              data: item.data,
            };
            return updatedType;
          }
          return item;
        }),
      };

    default:
      return state;
  }
};
