

export const removeItems = ()=>{
        localStorage.removeItem("token")
          localStorage.removeItem("manager")
          localStorage.removeItem("mainCardsData")
          localStorage.removeItem("mainTodayOrders")
          localStorage.removeItem("mainTodayReviews")
          localStorage.removeItem("orders")
          localStorage.removeItem("clients")
          localStorage.removeItem("restaurant")
          localStorage.removeItem("data")
}