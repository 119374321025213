import React from "react";
import "./Updates.css";
import { UpdatesData } from "../../Data/Data";
import StarRating from "../StarRating/StarRating";

const Updates = ({ reviews }) => {
  return (
    <div className="Updates" style={{ maxHeight: "30%", overflowY: "auto",}}>
      {reviews.map((update, index) => {
        return (
          <div key={index} className="update">
            <div className="noti">
              <div style={{ marginBottom: "0.5rem" }}>
                <span>{update.name}</span>
                <span> {update.review}</span>
              </div>
              <span style={{ fontSize: "12px", paddingRight: "5%" }}>{update.hour}</span>
              <StarRating rating={update.rating} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Updates;
