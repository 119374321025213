import React from "react";
import Chart from "react-apexcharts";

// parent Card


function ExpandedCard({ dates, data=[0,0,0,0]}) {
  if (data.length === 0) {
    return <div>Pas de données pour l'instant</div>;
  }
  const dataChart = {
    options: {
      chart: {
        type: "area",
        height: "auto",
      },

      dropShadow: {
        enabled: false,
        enabledOnSeries: undefined,
        top: 0,
        left: 0,
        blur: 3,
        color: "#000",
        opacity: 0.35,
      },

      fill: {
        colors: ["#fff"],
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["white"],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy",
        },
      },
      grid: {
        show: true,
      },
      xaxis: {
        type: "category",
        categories: dates,
        labels: {
          formatter: function (value) {
            // Format the date to "24/02/24 14H44"
            const date = new Date(value);
            const formattedDate = `${("0" + date.getDate()).slice(-2)}/${
              ("0" + (date.getMonth() + 1)).slice(-2)
            }/${date
              .getFullYear()
              .toString()
            }`;
  
            return formattedDate;
          },
        },
      },
    },
  };
  console.log('date in Curve.......', dates, data)
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
  <Chart
  width={isScreenSmall()?300:600}
  height={isScreenSmall()?200:400}
   options={dataChart.options} series={[{ name: "Commandes", data }]} type="area" />
  );
}

export default ExpandedCard;
