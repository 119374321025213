// Sidebar imports
import {
  UilEstate,
    UilClipboardAlt,
    UilUsersAlt,
    UilPackage,
    UilChart,
    UilSignOutAlt,
    UilReceipt,
    UilRestaurant,
    UilStar,
    UilComment,
    UilCt,
    UilRefresh,UilMailbox,UilMailboxAl,UilFastMail
} from "@iconscout/react-unicons";
import image4 from '../public/image4.jpg'
import image2 from '../public/image2.jpg'
import image1 from '../public/image1.jpg'
import image3 from '../public/image3.jpg'
import cocktail1 from '../public/cocktail1.jpg'
import cocktail2 from '../public/cocktail2.jpg'
import drink1 from '../public/drink1.jpg'
import drink2 from '../public/drink2.jpg'
import coffee1 from '../public/coffee1.jpg'
import coffee2 from '../public/coffee2.jpg'
import coffee3 from '../public/coffee3.jpg'
// Analytics Cards imports
import { UilUsdSquare, UilMoneyWithdrawal } from "@iconscout/react-unicons";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

// Recent Card Imports
import img1 from "../imgs/img1.png";
import img2 from "../imgs/img2.png";
import img3 from "../imgs/img3.png";

export const imagesType = {
  /*"Plat": {
    searctTerms: ["plat", "bouffe"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711746782/icons8-fruit-de-mer-64_jpkn9e.png"
  },*/
  "FastFood": {
    searctTerms: ["burger", "bouffe", "fastFood"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711746782/icons8-fast-food-48_ynq9ij.png"
  },
  /*"Boissons": {
    searctTerms: ["boissons", "vins", "jus"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711746782/icons8-drinks-64_fbtech.png"
  },*/
  "Autres": {
    searctTerms: ["autres"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711746782/icons8-bar-alimentaire-48_kr75s1.png"
  },
  "Vins": {
    searctTerms: ["vins", "vin rouge", "vin blanc"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656720/vins.png"
  },
  "Pâtes": {
    searctTerms: ["pâtes", "italien", "pasta"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656703/P%C3%A2tes.png"
  },
  "Desserts": {
    searctTerms: ["desserts", "sucreries", "gâteaux"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656702/Desserts.png"
  },
  "Brunchs": {
    searctTerms: ["brunchs", "déjeuner", "petit-déjeuner"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656701/Brunchs.png"
  },
  "Champagnes": {
    searctTerms: ["champagnes", "bulles", "célébration"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656701/Champagnes.png"
  },
  "Sushis": {
    searctTerms: ["sushis", "japonais", "poisson cru"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656695/Sushis.png"
  },
  "PokeBowls": {
    searctTerms: ["poke bowls", "santé", "bols"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656679/Poke%20bowls.png"
  },
  "Smoothies": {
    searctTerms: ["smoothies", "boissons santé", "fruit"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656647/Smoothies.png"
  },
  "Pizzas": {
    searctTerms: ["pizzas", "italien", "cheese"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656634/Pizzas.png"
  },
  "NourrituresSaines": {
    searctTerms: ["nourritures saines", "santé", "équilibré"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656634/Nourritures%20saines.png"
  },
  "Poulets": {
    searctTerms: ["poulets", "volaille", "viande blanche"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656634/Poulets.png"
  },
  "BubbleTea": {
    searctTerms: ["bubble tea", "boissons", "thé"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656633/Bubble%20tea.png"
  },
  "Bières": {
    searctTerms: ["bières", "alcool", "brew"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656633/Bi%C3%A8res.png"
  },
  "Barbecues": {
    searctTerms: ["barbecues", "grillades", "bbq"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656633/Barbecues.png"
  },
  "Nouilles": {
    searctTerms: ["nouilles", "asiatique", "pâtes"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656633/Nouilles.png"
  },
  "GlacesRafraichissements": {
    searctTerms: ["glaces", "rafraîchissements", "desserts glacés"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656633/Glaces%20-%20rafra%C3%AEchissements.png"
  },
  "Ramen": {
    searctTerms: ["ramen", "soupe", "japonais"],
    url: "https://res.cloudinary.com/switchercloudpics/image/upload/v1716656633/Ramen.png"
  }
}

export const dataTest = [
  {
      display : true,
      nom: 'Jus de fruits frais',
      dataCat: {
        position:'horizontal',        
        dataProduct: [
          {
            id: 1,
            display : true,
                      display : true,

            image: drink2,
            name: 'Jus de Fruits Mélangés',
            price: 5.99,
            description: 'Un mélange rafraîchissant de fruits frais pressés.',
            rating: 4.7,
            supplement:[
              {name:"riz",price:5.5},
              {name:"Tetris", price:2.99}
            ],
            reviews: [
              { user: 'Laura', date: '2022-02-28', rating: 5, review: 'Délicieux et sain!' },
              { user: 'Alex', date: '2022-03-01', rating: 4, review: "Parfait pour l'été." },
            ],
            uniqueSelected: [
              
            ],
            multipleSelected: [
            ],
          },
          {
              id: 1,
                        display : true,

              image: drink2,
              display : true,
              name: '7UP',
              price: 5.99,
              description: 'Un mélange rafraîchissant de fruits frais pressés.',
              rating: 4.7,
              reviews: [
                { user: 'Laura', date: '2022-02-28', rating: 5, review: 'Délicieux et sain!' },
                { user: 'Alex', date: '2022-03-01', rating: 4, review: "Parfait pour l'été." },
              ],
              uniqueSelected: [
              ],
              multipleSelected: [
              ],
            },
            {
              id: 3,
              display : true,
                        display : true,

              image: drink2,
              name: 'Sprite',
              price: 5.99,
              description: 'Un mélange rafraîchissant de fruits frais pressés.',
              rating: 4.7,
              reviews: [
                { user: 'Laura', date: '2022-02-28', rating: 5, review: 'Délicieux et sain!' },
                { user: 'Alex', date: '2022-03-01', rating: 4, review: "Parfait pour l'été." },
              ],
              uniqueSelected: [
              ],
              multipleSelected: [
              ],
            },
          // Ajoutez d'autres produits selon vos besoins
        ],
      },
    },
  {
    display : true,
    nom: 'Cocktails',
    dataCat: {
      position:'horizontal', 
      dataProduct: [
        {
          id: 1,
          display : true,
                    display : true,

          image: cocktail1,
          name: 'Margarita',
          price: 8.99,
          description: 'Un cocktail classique avec tequila, Cointreau et jus de citron.',
          rating: 4.8,
          reviews: [
            { user: 'Eva', date: '2022-02-20', rating: 5, review: 'Délicieux!' },
            { user: 'Frank', date: '2022-02-21', rating: 4, review: 'Très rafraîchissant.' },
          ],
          uniqueSelected: [
            { nameCat: 'Options de glace', data: { type: 'Glaçons concassés' } },
          ],
          multipleSelected: [
            { nameCat: 'Ingrédients supplémentaires', numSelect: 3, elements: ['Sel de bordure', 'Tranches de citron', 'Feuilles de menthe'] },
          ],
        },
        {
          id: 2,
          display : true,
                    display : true,

          image: cocktail2,
          name: 'Margarita',
          price: 8.99,
          description: 'Un cocktail classique avec tequila, Cointreau et jus de citron.',
          rating: 4.8,
          reviews: [
            { user: 'Eva', date: '2022-02-20', rating: 5, review: 'Délicieux!' },
            { user: 'Frank', date: '2022-02-21', rating: 4, review: 'Très rafraîchissant.' },
          ],
          uniqueSelected: [
            { nameCat: 'Options de glace', data: { type: 'Glaçons concassés' } },
          ],
          multipleSelected: [
            { nameCat: 'Ingrédients supplémentaires', numSelect: 3, elements: ['Sel de bordure', 'Tranches de citron', 'Feuilles de menthe'] },
          ],
        },
        {
          id: 3,
          display : true,
                    display : true,

          image: cocktail1,
          name: 'Margarita',
          price: 8.99,
          description: 'Un cocktail classique avec tequila, Cointreau et jus de citron.',
          rating: 4.8,
          reviews: [
            { user: 'Eva', date: '2022-02-20', rating: 5, review: 'Délicieux!' },
            { user: 'Frank', date: '2022-02-21', rating: 4, review: 'Très rafraîchissant.' },
          ],
          uniqueSelected: [
            { nameCat: 'Options de glace', data: { type: 'Glaçons concassés' } },
          ],
          multipleSelected: [
            { nameCat: 'Ingrédients supplémentaires', numSelect: 3, elements: ['Sel de bordure', 'Tranches de citron', 'Feuilles de menthe'] },
          ],
          supplements:[
            {nom:"Supplements 1",supplements:[{name:'test1', price:23},{name:'test2', price:4},{name:'test3', price:23},{name:'test4', price:4}]},
            {nom:"Supplements 2",supplements:[{name:'test1', price:23},{name:'test2', price:4},{name:'test3', price:23},{name:'test4', price:4}]}

          ]
        },
        {
          id: 4,
          display : true,
                    display : true,

          image: cocktail2,
          name: 'Margarita',
          price: 8.99,
          description: 'Un cocktail classique avec tequila, Cointreau et jus de citron.',
          rating: 4.8,
          reviews: [
            { user: 'Eva', date: '2022-02-20', rating: 5, review: 'Délicieux!' },
            { user: 'Frank', date: '2022-02-21', rating: 4, review: 'Très rafraîchissant.' },
          ],
          uniqueSelected: [
            { nameCat: 'Options de glace', data: { type: 'Glaçons concassés' } },
          ],
          multipleSelected: [
            { nameCat: 'Ingrédients supplémentaires', numSelect: 3, elements: ['Sel de bordure', 'Tranches de citron', 'Feuilles de menthe'] },
          ],
          supplements:[{nom:"Supplements 1",supplements:[{name:'test1', price:23},{name:'test2', price:4},{name:'test3', price:23},{name:'test4', price:4}]}
          ]
        },
        // Ajoutez d'autres produits selon vos besoins
      ],
    },
  },
  // Ajoutez d'autres sous-catégories de boissons si nécessaire
]
export const newData = [
  {
    type: 'Menus',
    data: [
      {
          display : true,
          nom: 'Menu spécial',
          dataCat: {
            position:'horizontal',                
            dataProduct: [
              {
                id: 1,
                          display : true,

                image: image1,
                name: 'Menu Gourmet',
                price: 49.99,
                description: 'Un menu exclusif avec entrée, plat principal, dessert et vin assorti.',
                rating: 4.9,
                reviews: [
                  { user: 'Sophie', date: '2022-02-25', rating: 5, review: 'Expérience gastronomique exceptionnelle!' },
                  { user: 'David', date: '2022-02-26', rating: 4.5, review: 'Vin parfaitement assorti.' },
                ],
                uniqueSelected: [
                  { nameCat: 'Boissons',required:true, data: [{ type: 'Vin rouge', millésime: 2015 }, { type: 'Vin blanc', millésime: 2015 } ]},
                  {nameCat: 'Dessert',required:true, data:[{type:'Gateau à la fraise'}, {type:'Gateau au cerise'}]}
                ],
                multipleSelected: [
                  { nameCat: 'Accompagnements1', numSelect: 2, elements: ['Truffes', 'Sauce aux champignons'] },
                  { nameCat: 'Accompagnements2', numSelect: 3, elements: ['Marmittes', 'champignons', 'sauce Moutarde', 'Sauces viennoises'] },

                ],
                supplements:[
                  {name:'test1', price:23},{name:'test2', price:4},{name:'test3', price:23},{name:'test4', price:4},
                ]
              },
              {
                  id: 2,
                            display : true,

                  image: image1,
                  name: 'Menu Délice 2',
                  price: 39.99,
                  description: 'Un menu succulent avec plat principal, dessert et boisson.',
                  rating: 4.5,
                  reviews: [
                    { user: 'Emma', date: '2022-03-05', rating: 4.8, review: 'Plat principal délicieux!' },
                    { user: 'Alex', date: '2022-03-06', rating: 4, review: 'Dessert incroyable.' },
                  ],
                  uniqueSelected: [
                    { nameCat: 'Boissons', data: { type: 'Eau minérale', size: 'Small' } },
                  ],
                  multipleSelected: [
                    { nameCat: 'Suppléments', numSelect: 1, elements: ['Salade verte'] },
                  ],
                },
                {
                  id: 3,
                            display : true,

                  image: image1,
                  name: 'Menu Délice 2',
                  price: 39.99,
                  description: 'Un menu succulent avec plat principal, dessert et boisson.',
                  rating: 4.5,
                  reviews: [
                    { user: 'Emma', date: '2022-03-05', rating: 4.8, review: 'Plat principal délicieux!' },
                    { user: 'Alex', date: '2022-03-06', rating: 4, review: 'Dessert incroyable.' },
                  ],
                  uniqueSelected: [
                    { nameCat: 'Boissons', data: { type: 'Eau minérale', size: 'Small' } },
                  ],
                  multipleSelected: [
                    { nameCat: 'Suppléments', numSelect: 1, elements: ['Salade verte'] },
                  ],
                },
              // Ajoutez d'autres produits selon vos besoins
            ],
          },
        },
      {
        nom: 'Menu principal',
        dataCat: {
          position:'horizontal',           
          dataProduct: [
            {
              id: 1,
                        display : true,

              image: image2,
              name: 'Menu Deluxe',
              price: 29.99,
              description: 'Un menu complet avec entrée, plat principal et dessert.',
              rating: 4.5,
              reviews: [
                { user: 'Alice', date: '2022-02-15', rating: 5, review: 'Excellent!' },
                { user: 'Bob', date: '2022-02-16', rating: 4, review: 'Très satisfait.' },
              ],
              uniqueSelected: [
                { nameCat: 'Boissons', data: { type: 'Soda', size: 'Medium' } },
              ],
              multipleSelected: [
                { nameCat: 'Suppléments', numSelect: 2, elements: ['Frites', 'Sauce spéciale'] },
              ],
            },
            // Ajoutez d'autres produits selon vos besoins
          ],
        },
      },      
    ],
  },
]
export const data = [
  {
    id: '',
    type: '',
    data: [
      {
          display : '',
          nom: '',
          id: '',
            position:'',                
            dataProduct: [
              {
                id: '',
                          display : '',

                image: '',
                name: '',
                price: '',
                description: '',
                rating: 4.9,
                reviews: [
                  {},
                ],
                uniqueSelected: [
                  {id: '', nameCat: '',required:'', data: [{ type: '', millésime: '' },  ]},
                ],
                multipleSelected: [
                  {id: '', nameCat: '', numSelect: '', data: ['', ''] },

                ],
                supplements:[
                  {id: '',nameCat:'', data:[{name:'test1', price:23},{name:'test2', price:4},{name:'test3', price:23},{name:'test4', price:4}]},
                ]
              },
            ],
          },     
    ],
  },
]

/*export const data = [
  {
    id: 1,
    type: 'Menus',
    display: true,
    description:'',
    data: [
      {
        id: 12,
        display: true,
        nom: 'Menu principal',
        position: 'horizontal',
        dataProduct: [
            {
              id: 1121,
              display: true,
              image: image2,
              name: 'Menu Deluxe',
              price: 29.99,
              description: 'Un menu complet avec entrée, plat principal et dessert.',
              rating: 4.5,
              reviews: [
                { user: 'Alice', date: '2022-02-15', rating: 5, review: 'Excellent!' },
                { user: 'Bob', date: '2022-02-16', rating: 4, review: 'Très satisfait.' },
              ],
              uniqueSelected: [
                { nameCat: 'Boissons', data: { type: 'Soda', size: 'Medium' } },
              ],
              multipleSelected: [
                { nameCat: 'Suppléments', numSelect: 2, elements: ['Frites', 'Sauce spéciale'] },
              ],
            },
            // ... autres produits
          ],
      },
      {
        id: 13,
        display: true,
        nom: 'Menu principal 2',
        position: 'horizontal',
        dataProduct: [
            {
              id: 1121,
              display: true,
              image: image2,
              name: 'Menu Deluxe',
              price: 29.99,
              description: 'Un menu complet avec entrée, plat principal et dessert.',
              rating: 4.5,
              reviews: [
                { user: 'Alice', date: '2022-02-15', rating: 5, review: 'Excellent!' },
                { user: 'Bob', date: '2022-02-16', rating: 4, review: 'Très satisfait.' },
              ],
              uniqueSelected: [
                { nameCat: 'Boissons', data: { type: 'Soda', size: 'Medium' } },
              ],
              multipleSelected: [
                { nameCat: 'Suppléments', numSelect: 2, elements: ['Frites', 'Sauce spéciale'] },
              ],
            },
            // ... autres produits
          ],
      },
      // ... autres sous-catégories
    ],
  },
  {
    id: 2,
    type: 'Boissons',
    display: true,
    description:'',
    data: [
      {
        id: 12,
        display: true,
        nom: 'Menu principal',
        position: 'horizontal',
        dataProduct: [
            {
              id: 1121,
              display: true,
              image: image2,
              name: 'Menu Deluxe',
              price: 29.99,
              description: 'Un menu complet avec entrée, plat principal et dessert.',
              rating: 4.5,
              reviews: [
                { user: 'Alice', date: '2022-02-15', rating: 5, review: 'Excellent!' },
                { user: 'Bob', date: '2022-02-16', rating: 4, review: 'Très satisfait.' },
              ],
              uniqueSelected: [
                { nameCat: 'Boissons', data: { type: 'Soda', size: 'Medium' } },
              ],
              multipleSelected: [
                { nameCat: 'Suppléments', numSelect: 2, elements: ['Frites', 'Sauce spéciale'] },
              ],
            },
            // ... autres produits
          ],
      },
      {
        id: 13,
        display: true,
        nom: 'Menu principal 2',
        position: 'horizontal',
        dataProduct: [
            {
              id: 1121,
              display: true,
              image: image2,
              name: 'Menu Deluxe',
              price: 29.99,
              description: 'Un menu complet avec entrée, plat principal et dessert.',
              rating: 4.5,
              reviews: [
                { user: 'Alice', date: '2022-02-15', rating: 5, review: 'Excellent!' },
                { user: 'Bob', date: '2022-02-16', rating: 4, review: 'Très satisfait.' },
              ],
              uniqueSelected: [
                { nameCat: 'Boissons', data: { type: 'Soda', size: 'Medium' } },
              ],
              multipleSelected: [
                { nameCat: 'Suppléments', numSelect: 2, elements: ['Frites', 'Sauce spéciale'] },
              ],
            },
            // ... autres produits
          ],
      },
      // ... autres sous-catégories
    ],
  }
];*/

// Sidebar Data
export const SidebarData = [
  {
    icon: UilEstate,
    heading: "Tableau de bord",
  },
  {
    icon: UilClipboardAlt,
    heading: "Commandes",
  },
  {
    icon: UilUsersAlt,
    heading: "Clients",
  },
  {
    icon: UilPackage,
    heading: 'Produits'
  },
  /*{
    icon: UilFastMail,
    heading: 'E-Marketing'
  },*/
  {
    icon: UilChart,
    heading: 'Analyses',
    list:[{
        icon: UilPackage,
        heading: 'Commande'
      },
      {
        icon: UilChart,
        heading: 'Avis'
      },
    ]
      
  },
];

// Analytics Cards Data
export const cardsData = [
  {
    title: "Gain Total",
    color: {
      backGround: "linear-gradient(180deg, #000000 100%, #FF4500 0%)",
      boxShadow: "0px 10px 20px 0px rgba(255, 69, 0, 0.5)", // Utilisation de rgba pour spécifier une couleur avec une opacité
    },
    colorText: {
      color: "#d68263",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
    barValue: 70,
    png: UilUsdSquare,
    series: [
      {
        name: "Commandes",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
    ],
  },
  {
    title: "Clients",
    /*color: {
      backGround: "linear-gradient(180deg,#FF4500 100%, #000000 0%)",
      boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 0.5)", // Utilisation de rgba pour spécifier une couleur avec une opacité
    },*/
    color: {
      backGround: "linear-gradient(180deg, #000000 100%, #FF4500 0%)",
      boxShadow: "0px 10px 20px 0px rgba(255, 69, 0, 0.5)", // Utilisation de rgba pour spécifier une couleur avec une opacité
    },
    colorText: {
      color: "#1E90FF",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
    barValue: 80,
    png: UilUsersAlt,
    series: [
      {
        name: "Clients",
        data: [10, 100, 50, 70, 80, 30, 40],
      },
    ],
  },
  {
    title: "Commandes",
    color: {
      backGround: "linear-gradient(180deg, #000000 100%, #FF4500 0%)",
      boxShadow: "0px 10px 20px 0px rgba(255, 69, 0, 0.5)", // Utilisation de rgba pour spécifier une couleur avec une opacité
    },
    colorText: {
      color: "#1E90FF",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
    barValue: 60,
    png: UilClipboardAlt,
    series: [
      {
        name: "Commandes",
        data: [10, 25, 15, 30, 12, 15, 20],
      },
    ],
  },
];
export const cardsClients = [
  {
    title: "Clients",
    color: {
      backGround: "linear-gradient(180deg, #bb67ff 0%, #c484f3 100%)",
      boxShadow: "0px 10px 20px 0px #e0c6f5",
    },
    barValue:"",
    value: "259",
    png: UilUsersAlt,
    series: [
      {
        name: "Clients",
        data: [31, 40, 28, 51, 42, 109, 100],
      },
    ],
  },
  {
    title: "Clients fréquents",
    color: {
      backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
    },
    barValue: 80,
    value: "140",
    png: UilUsersAlt,
    series: [
      {
        name: "Clients",
        data: [10, 100, 50, 70, 80, 30, 40],
      },
    ],
  },
  {
    title: "Dépense Moyenne",
    color: {
      backGround: "linear-gradient(180deg, #FF919D 0%, #FC929D 100%)",
      boxShadow: "0px 10px 20px 0px #FDC0C7",
    },
    barValue: 40,
    value: "14 €",
    png: UilUsersAlt,
    series: [
      {
        name: "dépenses",
        data: [10, 100, 20, 70, 40, 30, 40],
      },
    ],
  },
  {
    title: "Nombre d'avis",
    color: {
      backGround:
        "linear-gradient(rgb(248, 212, 154) -146.42%, rgb(255 202 113) -46.42%)",
      boxShadow: "0px 10px 20px 0px #F9D59B",
    },
    barValue: "",
    value: "50",
    png: UilUsdSquare,
    series: [
      {
        name: "Commandes",
        data: [10, 25, 15, 30, 12, 15, 20],
      },
      {
        name: "Plats",
        data: [15, 28, 18, 31, 12, 20, 20],
      },
    ],
  },
  
];

// Recent Update Card Data
export const UpdatesData = [
  {
    name: "Andrew Thomas",
    noti: "has ordered Apple smart watch 2500mh battery.",
    rating:5,
    time: "25 seconds ago",
  },
  {
    name: "James Bond",
    noti: "has received Samsung gadget for charging battery.",
    rating:3,
    time: "30 minutes ago",
  },
  {
    name: "Iron Man",
    noti: "has ordered Apple smart watch, samsung Gear 2500mh battery.",
    rating:4.5,
    time: "2 hours ago",
  },
];
export const countryCodes = [
  { name: "Albania", code: "+355" },
  { name: "Andorra", code: "+376" },
  { name: "Austria", code: "+43" },
  { name: "Belgium", code: "+32" },
  { name: "Benin", code: "+229" },
  { name: "Bosnia and Herzegovina", code: "+387" },
  { name: "Bulgaria", code: "+359" },
  { name: "Burkina Faso", code: "+226" },
  { name: "Cape Verde", code: "+238" },
  { name: "Croatia", code: "+385" },
  { name: "Cyprus", code: "+357" },
  { name: "Czech Republic", code: "+420" },
  { name: "Denmark", code: "+45" },
  { name: "Estonia", code: "+372" },
  { name: "Finland", code: "+358" },
  { name: "France", code: "+33" },
  { name: "Germany", code: "+49" },
  { name: "Gambia", code: "+220" },
  { name: "Ghana", code: "+233" },
  { name: "Greece", code: "+30" },
  { name: "Guinea", code: "+224" },
  { name: "Guinea-Bissau", code: "+245" },
  { name: "Hungary", code: "+36" },
  { name: "Iceland", code: "+354" },
  { name: "Ireland", code: "+353" },
  { name: "Italy", code: "+39" },
  { name: "Côte d'Ivoire", code: "+225" },
  { name: "Latvia", code: "+371" },
  { name: "Liberia", code: "+231" },
  { name: "Liechtenstein", code: "+423" },
  { name: "Lithuania", code: "+370" },
  { name: "Luxembourg", code: "+352" },
  { name: "Malta", code: "+356" },
  { name: "Mali", code: "+223" },
  { name: "Monaco", code: "+377" },
  { name: "Netherlands", code: "+31" },
  { name: "Niger", code: "+227" },
  { name: "Nigeria", code: "+234" },
  { name: "Norway", code: "+47" },
  { name: "Poland", code: "+48" },
  { name: "Portugal", code: "+351" },
  { name: "Romania", code: "+40" },
  { name: "San Marino", code: "+378" },
  { name: "Senegal", code: "+221" },
  { name: "Serbia", code: "+381" },
  { name: "Sierra Leone", code: "+232" },
  { name: "Slovakia", code: "+421" },
  { name: "Slovenia", code: "+386" },
  { name: "Spain", code: "+34" },
  { name: "Sweden", code: "+46" },
  { name: "Switzerland", code: "+41" },
  { name: "Togo", code: "+228" },
  { name: "Ukraine", code: "+380" },
  { name: "United Kingdom", code: "+44" },
  { name: "Vatican City", code: "+379" }
];



