import React, { useState, useRef,useEffect } from 'react';
import { Button, Modal, Typography } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material'; // Import des icônes de flèche
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CardCommand from './CardCommand';
const OrderBigScreen = ({ orderRestorer, onDeleteItem2,onDeleteItem, restaurandId, currencies,editOrder }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const containerRef = useRef(null);

  
  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: -200, // Ajustez la valeur selon la largeur des cartes
        behavior: 'smooth',
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: 200, // Ajustez la valeur selon la largeur des cartes
        behavior: 'smooth',
      });
    }
  };



const [ counter, setCounter ] = useState(0);

  useEffect(()=>{
    setInterval(()=>{
      setCounter(counter + 1);
    }, 1000);
  }, []);
  return (
    <div style={{ position: 'relative', overflow: 'hidden' }}>
      {/* Bouton Flèche Gauche */}
      <Button
        onClick={scrollLeft}
        style={{
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          color: 'white',
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', // Légère ombre
          zIndex: 1, // Assure que le bouton reste au-dessus du contenu
        }}
      >
        <ArrowLeft sx={{ fontSize: 36 }} />
      </Button>

      {/* Bouton Flèche Droite */}
      <Button
        onClick={scrollRight}
        style={{
          position: 'absolute',
          right: 0,
          top: '50%',
          transform: 'translateY(-50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: 'none',
          color: 'white',
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 0, 0, 0.3)',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          boxShadow: '0 2px 5px rgba(0, 0, 0, 0.5)', // Légère ombre
          zIndex: 1, // Assure que le bouton reste au-dessus du contenu
        }}
      >
        <ArrowRight sx={{ fontSize: 36 }} />
      </Button>
      <div
        ref={containerRef}
        style={{
          display: 'flex',
          overflowX: 'auto',
          maxWidth: 'calc(100vw - 100px)', // Utilisation de 100vw pour spécifier la largeur de la fenêtre visible
          scrollBehavior: 'smooth',
          padding: '1rem 0',
          margin: '0 50px',
        }}
      >
        {orderRestorer.map((order) => (
          <CardCommand order={order} currencies={currencies} onDeleteItem2={onDeleteItem2} onDeleteItem={onDeleteItem} restaurandId={restaurandId} editOrder={editOrder}/>
        ))}
      </div>
    </div>
  );
};

export default OrderBigScreen;
