import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { currencies } from "../../actions/currencies";

import "./Table.css";





const makeStyle = (status) => {
  if (status === 'Terminée') {
    return {
      background: 'rgba(145, 254, 159, 0.47)',
      color: 'green',
    }
  } else if (status === 'En cours') {
    return {
      background: 'rgba(255, 202, 113, 0.8)',
      color: 'white',
    }
  } else if (status === 'Refusée') {
    return {
      background: 'rgba(255, 173, 173, 0.56)',
      color: 'red',
    }
  } else {
    return {
      background: 'rgba(89, 191, 255, 0.7)',
      color: 'white',
    }
  }
}

export default function BasicTable({ rows, refresh }) {
  const reversedRows = rows.slice().reverse();
  
  const  getWaitingTime = (targetTime)=>{
    // Split the target time into hours and minutes
    const [targetHours, targetMinutes] = targetTime.split(':').map(Number);
    console.log("targetHours, targetMinutes...", targetHours, targetMinutes)

    // Get the current time
    const now = new Date();
    const currentHours = now.getHours();
    const currentMinutes = now.getMinutes();
    console.log("minutes...", currentHours, currentMinutes)

    // Convert target time and current time to total minutes from midnight
    const targetTotalMinutes = targetHours * 60 + targetMinutes;
    const currentTotalMinutes = currentHours * 60 + currentMinutes;
  
    // Calculate the difference in minutes
    const differenceInMinutes = targetTotalMinutes - currentTotalMinutes;
    return Math.abs(differenceInMinutes)
  }
console.log('rows....', reversedRows)
  return (
    <div style={{}} >
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "0px 13px 20px 0px #80808029",
            maxHeight: '40vh',
            overflowY:'auto'
          }}
        >
          <Table style={{}} stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: '#17174F' }}>Table</TableCell>
                <TableCell style={{ color: '#17174F' }} align="left">Commande</TableCell>
                <TableCell style={{ color: '#17174F' }} align="left">Heure</TableCell>
                <TableCell style={{ color: '#17174F' }} align="left">Status</TableCell>
                <TableCell style={{ color: '#17174F' }} align="left">Prix</TableCell>
                <TableCell style={{ color: '#17174F' }} align="left">Attente</TableCell>
                <TableCell style={{ color: '#17174F' }} align="left">Paiement</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ color: "white",}}>
              {reversedRows?.map((row, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.numberOfTable}
                  </TableCell>
                  <TableCell align="left">{row.order}</TableCell>
                  <TableCell align="left">{row.hour}</TableCell>
                  <TableCell align="left">
                    <span
                      className="status"
                      style={makeStyle(row.status)}
                    >
                      {row.status}
                    </span>
                  </TableCell>
                  <TableCell align="left">{row.price} {currencies().symbol}</TableCell>
                  <TableCell align="left">{row.time && row?.time?.length > 0 ? row.time : getWaitingTime(row.hour)} mins</TableCell>
                  <TableCell align="left">{row.type}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
    </div>
  );
}
