import React, {useState, useEffect} from "react";
import CreditCardForm from "./Payment";
import client from "../../../api/client";
import Subscribe from "./Subscribe";
import Abonnement from "./Abonnement";

function formatDate(timestamp) {
    if(timestamp){
        const date = new Date(timestamp);
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
    }else{
        return ''
    }
    
  }


export default function MainPayment (){
    const [isSubscribed, setIsSubscribe] = useState(false)
    const [price, setPrice] = useState(0)
    const [date, setDate] = useState(null)
    const [lastDate, setLastDate] = useState(null)
    const [payments, setPayments] = useState([])
    useEffect(()=>{
        const token = JSON.parse((localStorage.getItem("token")))
        const checkSubscription = async()=>{
            await client.post("/get-subscription",{},{
                headers: {
                    Accept: 'application/json',
                    //'content-Type': 'multipart/form-data',
                    authorization : `JWT ${token}`,
                  }
                }).then((res)=>{
                    console.log("payment...", res.data)
                    if(res.data.success){
                        console.log("payment...", res.data)
                        setIsSubscribe(res.data.is_subscribed)
                        setDate(formatDate(res.data.subscribe_date))
                        setPrice(res.data.subscribe_price)
                        setLastDate(res.data.lastSubscribe)
                        setPayments(formatDate(res.data.payments))
                    }
                })
        }
        checkSubscription()
    },[])
    useEffect(()=>{

    },[isSubscribed])
    return(
        <Subscribe price={price} date={date} />
    )
}
/**
 *         <Abonnement price={price} date={date} lastDate={lastDate} payments={payments} />       

 */