import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { Button, FormControl, InputLabel, MenuItem, Select, CircularProgress, Backdrop } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete'; // Import the delete icon
import { useNavigate } from 'react-router-dom';
import countriesData from '../../Data/CountriesData';
import client from '../../api/client';
import socket from '../../api/socket';

const RestaurantRegister = ({ manager, token, updateRestaurant,socket }) => {
  const [carouselImages, setCarouselImages] = useState(Array(6).fill(null));
  const [imageFiles, setImageFiles] = useState(Array(6).fill(null));
  const [loading, setLoading] = useState(false);
  const handleImageDelete = (index) => {
    setImageFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = null;
      return newFiles;
    });
    setCarouselImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = null;
      return newImages;
    });
  };
  const [formDataObject, setFormDataObject] = useState({
    name: '',
    id_manager: manager?._id,
    origine_cuisine: '',
    countryName: '',
    address: {
      street_number: '',
      street_name: '',
      zip_code: '',
      complement_address: '',
    },
    number_of_tables:'',
    tables: [],
    types: [],
    orders: [],
    currencies: {
      code: '', // Ajoutez la devise par défaut ici
      name: '',
      symbol: '',
    },
  });
  const handleImageChange = (index, file, imageUrl) => {
    setImageFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles[index] = file;
      return newFiles;
    });
    setCarouselImages((prevImages) => {
      const newImages = [...prevImages];
      newImages[index] = imageUrl;
      return newImages;
    })
  };
  

  const [error, setError] = useState('');

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    if (name === 'id_country') {
      const selectedCountry = countriesData.find((country) => country.code === value);
  
      setFormDataObject((prevData) => ({
        ...prevData,
        countryName: selectedCountry ? selectedCountry.name : '',
        id_country: value,
        currencies: selectedCountry ? selectedCountry.currency : {},
      }));
    } else {
      setFormDataObject((prevData) => ({
        ...prevData,
        [name]: value,
        address: {
          ...prevData.address,
          [name]: value,
        },
      }));
    }
  };
  
  

const handleSubmit = async () => {
    console.log(formDataObject.countryName, formDataObject.origine_cuisine)
    if (
      !formDataObject.name ||
      !formDataObject.origine_cuisine ||
      !formDataObject.countryName ||
      !formDataObject.address.street_number ||
      !formDataObject.address.street_name ||
      !formDataObject.address.zip_code
    ) {
        setError('Veuillez remplir tous les champs obligatoires.');
      setTimeout(() => {
        setError('');
      }, 3000);
      return;
    }else{
    // Validation
      const token = JSON.parse(localStorage.getItem('token'))
      if(token){
        console.log("formData.......", formDataObject)

    const formData = new FormData();
    formData.append('name', formDataObject.name);
    formData.append('origine_cuisine', formDataObject.origine_cuisine);
    formData.append('address_data', JSON.stringify(formDataObject.address));
    formData.append('number_of_tables', formDataObject.number_of_tables);
    formData.append('countryName', formDataObject.countryName);
    formData.append('currencies', JSON.stringify(formDataObject.currencies));
        // Côté client, en ajoutant des fichiers à FormData
    imageFiles.forEach((file) => {
      if (file) {
        formData.append('imageFiles', file); // Utilisez le même nom 'imageFiles' pour tous les fichiers
      }
    });

    try {
      setLoading(true); // Afficher la barre de chargement
      await client.post("/create-restaurant",formData,{
          headers: {
              Accept: 'application/json',
              //'content-Type': 'multipart/form-data',
              authorization : `JWT ${token}`,
            }
          } ).then((res)=>{
            if(res.data.success){              
              localStorage.setItem("manager", JSON.stringify(res.data.manager));
              if(res.data?.restaurant){
              socket.connect();
              localStorage.setItem("mainCardsData", JSON.stringify(res.data.mainCardsData));
              localStorage.setItem("mainTodayOrders", JSON.stringify(res.data.mainTodayOrders));
              localStorage.setItem("mainTodayReviews", JSON.stringify(res.data.mainTodayReviews));
              localStorage.setItem("orders", JSON.stringify(res.data.orders));
              localStorage.setItem("clients", JSON.stringify(res.data.clients));
              localStorage.setItem("restaurant", JSON.stringify(res.data.restaurant));
              localStorage.setItem("data", JSON.stringify(res.data.data));
              updateRestaurant()
              const managerRoom = `manager-${res.data.restaurant._id}`;
                      socket.emit('joinRoom', managerRoom);
                      // Clean up the socket listener when the component unmounts
                return () => {
                 socket.off('joinRoom');
                 };
              }
            } else {
              console.log("res data", res.data.error)
              setError(res.data.error);
              setTimeout(() => {
                setError('');
              }, 3000);
            }
          })
    } catch (error) {
      
    }finally{
      setLoading(false); // Cacher la barre de chargement
    }


      }
    }
   console.log("formDataObject............", formDataObject)
  };

  
  const handleCurrencyChange = (event) => {
    const { value } = event.target;
  
    setFormDataObject((prevData) => ({
      ...prevData,
      currencies: countriesData.find((country) => country.code === prevData.id_country)?.currency || {},
    }));
  };
  const [currentStep, setCurrentStep] = useState(1); // State variable to track the current step

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div className='firstComponent'>
            <div className='firstComponent'>
            <TextField
            variant='filled'
              label="Nom du restaurant"
              name="name"
              value={formDataObject.name}
              onChange={handleChange}
              style={{ width: '60%',marginLeft:'20%',marginBottom:'5%',color: '#242d49'  }}
              required
            />
          <div style={{ display: 'flex', gap: '1%', marginBottom:'5%',marginLeft:'20%' }}>
            <TextField
                type='number'
                variant='filled'
              label="Nombre de table"
              name="number_of_tables"
              value={formDataObject.number_of_tables >= 0 ? formDataObject.number_of_tables : 0}
              onChange={handleChange}
              style={{ width: '30%' }}
              required
            />

            <TextField
                variant='filled'
              label="Origine de la cuisine"
              name="origine_cuisine"
              value={formDataObject.origine_cuisine}
              onChange={handleChange}
              style={{ width: '45%' }}
              required
            />
          </div>

          

          <div style={{ display: 'flex', gap: '1%',marginBottom:'5%',marginLeft:'20%' }}>
            <TextField
              variant='filled'
              label="N°rue"
              name="street_number"
              value={formDataObject.address.street_number}
              onChange={handleChange}
              style={{ width: '15%' }}
              required
            />

            <TextField
                variant='filled'
              label="Nom de rue"
              name="street_name"
              value={formDataObject.address.street_name}
              onChange={handleChange}
              style={{ width: '30%' }}
              required
            />

            <TextField
                variant='filled'
              label="Code postal"
              name="zip_code"
              value={formDataObject.address.zip_code}
              onChange={handleChange}
              style={{ width: '30%' }}
              required
            />
          </div>

          <div style={{ display: 'flex', gap: '1%', marginBottom:'5%',marginLeft:'20%' }}>
            {/*<TextField
                variant='filled'
            label="Complément d'adresse"
            name="complement_address"
            value={formDataObject.address.complement_address}
            onChange={handleChange}
            style={{ width: '30%' }}
          />*/}
          <FormControl fullWidth required  style={{ width: '45%' }}>
            <InputLabel id="id_country_label">Pays</InputLabel>
            <Select
                labelId="id_country_label"
                id="id_country"
                name="id_country"
                value={formDataObject.id_country || ''}
                onChange={handleChange}
                >
                {countriesData.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                    {country.name}
                    </MenuItem>
                ))}
                </Select>
          </FormControl>
          <FormControl fullWidth required style={{ width: '30%' }}>
      <InputLabel id="id_currency_label">Devise</InputLabel>
      <Select
        labelId="id_currency_label"
        id="id_currency"
        name="id_currency"
        value={formDataObject.currencies.symbol || ''}
        onChange={handleCurrencyChange}
      >
        {/* Option de devise en fonction du pays sélectionné */}
        <MenuItem value={formDataObject.currencies.symbol}>
          {formDataObject.currencies.name} ({formDataObject.currencies.symbol})
        </MenuItem>
      </Select>
    </FormControl>

          
            </div>
          

          {/* Ajoutez d'autres champs si nécessaire, par exemple les tables, types, orders */}
          <Button variant="contained" onClick={handleNext} style={{marginLeft:'40%',background:"#242d49" ,":hover": { backgroundColor:  "hsla(354, 85%, 78%, 0.934)"}, alignSelf: 'center',}}>
              Suivant
            </Button>
          
        </div>
            
          </div>
        );
      case 2:
        return (
          <div className='nextComponent'>
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
              {carouselImages.map((image, index) => (
                <div key={index} style={{ width: '25%', height: '25%', margin: '10px', background: '#ccc', cursor: 'pointer', position: 'relative' }}>
                  <input
                    type="file"
                    accept="image/*"
                    id={`imageInput-${index}`}
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file) {
                        const imageUrl = URL.createObjectURL(file);
                        handleImageChange(index, file, imageUrl);
                      }
                    }}
                  />
                  <label htmlFor={`imageInput-${index}`} style={{ width: '100%', height: '100%', display: 'block' }}>
                    {image ? (
                      <img src={image} alt={`carousel-${index}`} style={{ width: '120px', height: '150px', objectFit: 'cover', marginLeft:'15%' }} />
                    ) : (
                      <div style={{ textAlign: 'center', lineHeight: '150px' }}>Ajouter une image</div>
                    )}
                  </label>
                  {image && (
                    <DeleteIcon
                      style={{
                        position: 'absolute',
                        top: '5px',
                        right: '5px',
                        color: 'red',
                        cursor: 'pointer'
                      }}
                      onClick={() => handleImageDelete(index)}
                    />
                  )}
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '10%', paddingTop: '5%' }}>
              <Button
                variant="contained"
                onClick={handlePrevious}
                style={{
                  background: '#242d49',
                  ':hover': { backgroundColor: 'hsla(354, 85%, 78%, 0.934)' },
                  alignSelf: 'center',
                }}
              >
                Précédent
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                style={{
                  background: '#242d49',
                  ':hover': { backgroundColor: 'hsla(354, 85%, 78%, 0.934)' },
                  alignSelf: 'center',
                }}
              >
                Enregistrer
              </Button>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div style={{ backgroundColor: 'white', borderRadius: '15px', width: '100%',maxHeight:'80%', paddingTop:'5%',overflowY:'auto',  }}>
      <div style={{fontSize:35, fontWeight:900, marginLeft:'35%', marginBottom:'5%', color:"#242d49"}}>Mon restaurant</div>
      {error && <div style={{ color: 'red', textAlign: 'center', paddingBottom:'1%' }}>{error}</div>}
      
      {renderStep()}
      <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
      
  );
};

export default RestaurantRegister;

