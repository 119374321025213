import React, { useState } from 'react';
import EmailEditor from '../EmailEditor';
import { Button, Form, Input, Select, Typography, Divider, message } from 'antd';

const Main = () => {
  const [minutes, setMinutes] = useState(5);

  const handleMinutesChange = (event) => {
    setMinutes(event.target.value);
  };
  const handleSave = ()=>{
    
  }

  return (
    <div style={{ marginTop: '10%',}}>
      <h3>Configuration d'E-mail Après Repas</h3>
      <EmailEditor variables={['nom_du_client','Lien_du_questionnaire']}/>
      <div style={{display: 'flex', flexDirection: 'column',}}>
        <div> Durée avant l'envoi du mail le statut de la commande passe en <span style={{ fontWeight: 'bold' }}>Terminée</span></div>
        <div>
            <input
            type='number'
            value={minutes}
            onChange={handleMinutesChange}
            placeholder="Saisissez le temps d'attente en minutes..."
            style={{ padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px', width:'10%' }}
          /> minutes
        </div>        
      </div>
      <div>
      <button onClick={()=>handleSave()}>Enregistrer</button>
      </div>
    </div>
  );
};

export default Main;
