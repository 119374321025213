import React from 'react';
import { Typography, Modal, Button } from '@mui/material';

const OrderDetails = ({ order, currencies }) => {
  const renderColorDot = (status) => {
    let color = '#000000'; // Couleur par défaut
    switch (status) {
      case 'En attente':
        color = '#8BD2FF'; // Jaune pour En attente
        break;
      case 'En cours':
        color = 'rgba(255, 202, 113, 0.8)'; // Bleu pour En cours
        break;
      case 'Terminé':
        color = 'rgba(145, 254, 159, 0.47)'; // Vert pour Terminé
        break;
      case 'Refusé':
        color = 'rgba(255, 173, 173, 0.56)'; // Rouge pour Refusé
        break;
      default:
        break;
    }
    return (
      <div style={{ width: '10px', height: '10px', borderRadius: '10px', backgroundColor: color, marginRight: '10px', marginTop: '12px' }} />
    );
  };

  return (
    <div>
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent:'center' }}>
          {renderColorDot(order.status)}
          <Typography sx={{color: 'black'}} variant="h5">{order.clientName} - </Typography>
          <Typography  sx={{color: 'black'}}  variant="h5">Table {order.numberOfTable}</Typography>
        </div>
        <div>
          <Typography sx={{color: 'black', display: 'flex', alignItems: 'center', justifyContent:'center'}} variant="body1">{order.order}</Typography>
          <Typography sx={{color: 'black'}} variant="body1">Prix : {order.price} {currencies.symbol}</Typography>
          <Typography sx={{color: 'black'}} variant="body1">Type de paiement : {order.type}</Typography>
          {order?.time&&order.time>0?(<Typography sx={{color: 'black'}} variant="body1">Temps de service : {order.time} mins</Typography>):null}
          <Typography sx={{color: 'black'}} variant="body1">Heure : {order.hour}</Typography>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
