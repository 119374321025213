import React from 'react';
import { PieChart, Pie, BarChart, Bar, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import './ChartComponent.css'
const ChartComponent = ({ pieChartData, barChartData }) => {
  const questions = [
    'Comment avez-vous entendu parler de nous ?',
    'De quelle tranche d\'âge faites-vous partie ?',
    'L’accueil reçu à votre arrivée était-il chaleureux et courtois?',
    'Le délai d’attente était-il adéquat?',
    'À combien estimez-vous votre satisfaction globale vis-à-vis de nos services?',
    'À combien estimez-vous vos chances de revenir à notre restaurant?',
  ];

  // Palette de couleurs
  const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#AF19FF', '#FF1919'];
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${payload[0].payload.name}: ${payload[0].value}`}</p>
                <p className="intro">{payload[0].payload.question}</p>
            </div>
        );
    }

    return null;
};
const CustomTooltipPercent = ({ active, payload }) => {
  if (active && payload && payload.length) {
      return (
          <div className="custom-tooltip">
              <p className="label">{`${payload[0].payload.name}: ${payload[0].value}%`}</p>
              <p className="intro">{payload[0].payload.question}</p>
          </div>
      );
  }

  return null;
};
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
    <>{
        pieChartData&&pieChartData[0]?(
          isScreenSmall()?
          (
            <div className="smallScreen">
      {questions.map((question, index) => {
        const pieDataIndex = index === 2 ? 3 : index;
        const barDataIndex = index === 2 ? 3 : index;
        return (
          <div key={index} className="card">
            <h5>{question}</h5>
            <div className="graphs">
              {pieChartData && pieChartData[pieDataIndex] && (
                <PieChart width={100} height={100}>
                  <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={Object.entries(pieChartData[pieDataIndex]).map(([name, value], idx) => ({ name, value, fill: colors[idx] }))}
                    cx="50%"
                    cy="50%"
                    outerRadius={30}
                    innerRadius={15}
                    startAngle={90}
                  />
                  <Tooltip />
                </PieChart>
              )}
              {barChartData && barChartData[barDataIndex] && (
                <BarChart width={100} height={100} data={Object.entries(barChartData[barDataIndex]).map(([name, value], idx) => ({ name, value, fill: colors[idx] }))}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="value" />
                </BarChart>
              )}
            </div>
            {pieChartData && pieChartData[pieDataIndex] && (
              <div className="responses">
                {Object.entries(pieChartData[pieDataIndex]).map(([name], idx) => (
                  <div key={idx} className="response-item">
                    <div className="color-box" style={{ backgroundColor: colors[idx] }} />
                    <span>{name}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </div>
    )
           :
    (
      <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
            gap: '20px',
            justifyContent: 'center'
        }}>
            {questions.map((question, index) => {
                const pieDataIndex = index === 2 ? 3 : index;
                const barDataIndex = index === 2 ? 3 : index;
                return (
                    <div key={index} className="card">
                        {/* Titre de la question */}
                        <h5>{question}</h5>

                        {/* Graphiques */}
                        <div className="graphs">
                            <div className="graph-container">
                                {pieChartData && pieChartData[pieDataIndex] && (
                                    <PieChart width={200} height={200}>
                                        <Pie
                                            dataKey="value"
                                            isAnimationActive={false}
                                            data={Object.entries(pieChartData[pieDataIndex]).map(([name, value], idx) => ({ name, value, fill: colors[idx] }))}
                                            cx="50%"
                                            cy="50%"
                                            outerRadius={30}
                                            innerRadius={15}
                                            startAngle={90}
                                        />
                                        <Tooltip content={<CustomTooltipPercent />} />
                                    </PieChart>
                                )}
                            </div>
                            <div className="graph-container">
                                {barChartData && barChartData[barDataIndex] && (
                                    <BarChart width={200} height={150} data={Object.entries(barChartData[barDataIndex]).map(([name, value], idx) => ({ name, value, fill: colors[idx] }))}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <YAxis />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar dataKey="value" />
                                    </BarChart>
                                )}
                            </div>
                        </div>

                        {/* Légende personnalisée pour le PieChart */}
                        {pieChartData && pieChartData[pieDataIndex] && (
                            <div className="responses">
                                {Object.entries(pieChartData[pieDataIndex]).map(([name], idx) => (
                                    <div key={idx} className="response-item">
                                        <div className="color-box" style={{ backgroundColor: colors[idx] }} />
                                        <span>{name}</span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}
        </div>)
        ):null
    }</>
    
  );
};

export default ChartComponent;
