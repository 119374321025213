import '../App.css'
import React, { useState, useEffect } from 'react';
import MainDash from './MainDash/MainDash';
import RightSide from './RigtSide/RightSide';
import Sidebar from './Sidebar';
import Order from './Orders/Orders';
import Clients from './Clients/Clients';
import MainProducts from './Products/MainProducts';
import RightSideProducts from './Products/RightSideProducts';
import client from '../api/client';
import { useNavigate } from 'react-router-dom';
import { fetchProducts } from '../actions/productActions';
import { useDispatch } from 'react-redux';
import { removeItems } from '../actions/deleteItems';
import AnalysisOrders from './Analysis/AnalysisOrders/AnalysisOrders';
import Reviews from './Analysis/Reviews/Reviews';
import Main from './EMarketing/Main';


function AllComponents({socket, data=[]}) {
    const dispatch = useDispatch();
    //console.log("data in all components", data)
    const navigate = useNavigate()
  const [activeSection, setActiveSection] = useState("Tableau de bord");
  
  const handleMenuClick = (section) => {
    console.log("active: ",activeSection)
    setActiveSection(section);
  };

  const [tokenValid, setToken] = useState(false)


useEffect(() => {
  console.log("check token...............",localStorage.getItem("token"))    
  const token = JSON.parse((localStorage.getItem("token")))
    
    const check = async() => {
      console.log("check token...............",token)  
        await client.post("/check-token",{token},{
            headers: {
                Accept: 'application/json',
                //'content-Type': 'multipart/form-data',
                authorization : `JWT ${token}`,
              }
            }).then((res)=>{
                console.log("check token ",res)
                if(res.data.success){
                  if(res.data.manager.is_restaurant){
                    const restaurant = JSON.parse(localStorage.getItem('restaurant'))
                    const fetchData = () => {
                            console.log("dans fetch")
                            dispatch(fetchProducts(res.data.data ));
                      }
                      fetchData()
                    localStorage.setItem("data", JSON.stringify(res.data.data))
                    console.log("normalement ",restaurant._id)
                    setToken(true)
                    if (restaurant) {
                      const managerRoom = `manager-${restaurant._id}`;
                      socket.emit('joinRoom', managerRoom);
                  
                      // Clean up the socket listener when the component unmounts
                      return () => {
                        socket.off('joinRoom');
                      };
                    }
                }else{
                  setToken(true)

                }
                    
              }else if(!res.data.success){
                    console.log("check token sessions expiré")
                    removeItems()  
                    navigate("/")
              }else{
                      console.log("check token sessions expiré")
                    }
            });
      
          
      }
      check();
    
}, []);
console.log("tokenValid.................", tokenValid)
  return ( 
    <>
       {tokenValid?(
    <div className="AppGlass">
      <Sidebar onMenuClick={handleMenuClick} />
        {activeSection === "Tableau de bord" && (
          <>
          <MainDash socket={socket}/>
          <RightSide socket={socket}/>
          </>
        )}
        {activeSection === "Commandes" && (
          <>
          <Order socket={socket}/>
          </>
        )}
        {activeSection === "Clients" && (
          <>
          <Clients socket={socket}/>
          </>
        )}
        {activeSection === "E-Marketing" && (
          <>
          <Main />
          </>
        )}
        {activeSection === "Produits" && (
          <>
          <MainProducts socket={socket} data={data}/>
          <RightSideProducts socket={socket}/>
          </>
        )}
        {activeSection === "Commande" && (
          <>
          <AnalysisOrders/>
          </>
        )}
        {activeSection === "Avis" && (
          <>
          <Reviews/>
          </>
        )}

    </div>):null} 
    </>
    
  );
}

export default AllComponents;
