import React, { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CreditCardForm from './Payments/MainPayment';
import AccesCode from './AccesCode';
import Restaurant from './Restaurant/Restaurant';
import QRCodeGenerator from './QRCode/QRCodeGenerator';
import FormPasswordReset from './Password/FormPasswordReset';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, height: '100%', overflowY: 'auto' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const Parameter = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            height: '80vh', // Taille fixe de 80vh
            maxHeight: '80vh',
            minHeight: '80vh',
            overflowY: 'auto',
  scrollbarWidth: 'none', /* Pour Firefox */
  msOverflowStyle: 'none', /* Pour Internet Explorer et Edge */
  '&::-webkit-scrollbar': { display: 'none' }, /* Pour Chrome, Safari et Opera */ // Ajout de défilement si nécessaire
          }}
        >
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider' }}
          >
            <Tab label="Mon restaurant" {...a11yProps(0)} />
            <Tab label="QR code" {...a11yProps(1)} />
            <Tab label="Code d'accès" {...a11yProps(2)} />
            <Tab label="Paiement" {...a11yProps(3)} />
            <Tab label="Mot de passe" {...a11yProps(4)} />
          </Tabs>
        </Box>
      </Grid>
      <Grid item xs={8}>
        <Box sx={{ height: '80vh', overflowY: 'auto' }}> {/* Ajout d'un conteneur fixe pour le contenu */}
          <TabPanel value={value} index={0}>
            <Card>
              <CardContent>
                <Restaurant />
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Card>
              <CardContent>
                <QRCodeGenerator />
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <Card>
              <CardContent>
                <AccesCode />
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Card>
              <CardContent>
                <CreditCardForm />
              </CardContent>
            </Card>
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Card>
              <CardContent>
                <FormPasswordReset />
              </CardContent>
            </Card>
          </TabPanel>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Parameter;
