import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { addType, deleteType, editType } from '../../actions/productActions';
import { v4 as uuidv4 } from 'uuid';
import client from '../../api/client';
import { useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import CardImage from './CardImage';
const DialogSection = ({ open, onClose, newType = '', newDescription='', idType, onAdd, onEdit, onDelete, displayType, image='https://res.cloudinary.com/switchercloudpics/image/upload/v1711746782/icons8-fruit-de-mer-64_jpkn9e.png' }) => {
    const [type, setType] = useState(newType);
    const [imageType, setImageType] = useState(image)
  const [description, setDescription] = useState(newDescription ||'Entrez une description ici');
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"))
  const [showCategory, setShowCategory] = useState(displayType||false);
  const [openImageDialog, setOpenImageDialog] = useState(false);


  const handleSave = async() => {
    if (newType.length > 0) {
      console.log("idType ", idType)
      await client.post("/edit-type", {
        image:imageType,
        id:idType,
        type: type||'',
        description:description||'',
        display:showCategory,
        id_restaurant:await JSON.parse(localStorage.getItem("restaurant"))?._id||'R.A.S',
        },
        {
          headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
            Authorization : `JWT ${token}`,
           }
        }
        ).then((res)=>{
          console.log("res?.data?", res?.data)
          if(!res?.data?.success){
            console.log("res?.data? dans creation de type", res?.data)
            //navigate('/')
          }else{
            onEdit({ type, image:imageType,id:res?.data?.type._id, description, showCategory});
            onClose();
            setType('');
            setDescription('')
          }
        })
      
    } else {
      await client.post("/create-type", {
        image:imageType,
        type: type||'',
        description:description||'',
        display:true,
        idRestaurant:await JSON.parse(localStorage.getItem("restaurant"))?._id||'R.A.S',
        },
        {
          headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
            Authorization : `JWT ${token}`,
           }
        }
        ).then((res)=>{
          if(!res.data.success){
            navigate('/')
          }
          else{
            console.log("type creation: ", res?.data?.type._id)
            onAdd({ type,image:imageType,id:res?.data?.type._id, description, display:true });
            onClose();
            setType('');
            setDescription('')
            console.log("res?.data?", res?.data)
          }
          
        })
    }
    
  };

  const handleDelete = async() => {
    const id = idType|| uuidv4();
    try {
      await client.post("/delete-type", {
      typeId:idType
      },
      {
        headers: {
          Accept: 'application/json',
          //'content-Type': 'multipart/form-data',
          Authorization : `JWT ${token}`,
         }
      }
      ).then((res)=>{
        console.log("out")
        if(!res?.data?.success){
          navigate('/')
        }else{
          onDelete({ type, id:idType });
        }
      })
    } catch (error) {
      //Popup
    }
    
      onClose();

  };
  const handleToggleCategoryForm = () => {
    setShowCategory(!showCategory);
  };
  const handleSelectImage = (url) => {
    setImageType(url);
    setOpenImageDialog(false);
};
  return (
    <Dialog open={open} onClose={onClose}>
      <Card>
        <CardContent sx={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
        <div onClick={() => setOpenImageDialog(true)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginRight: '10px', position: 'relative' }}>
  <div style={{ 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    width: '55px', 
    height: '55px', 
    borderRadius: '50%', 
    border: '1px solid black', 
    position: 'relative'
  }}>
    <img src={imageType} alt="Section Image" style={{ width: '50px', height: '50px' }} />
    <span style={{
      position: 'absolute',
      left: '4px',
      bottom: '4px',
      width: '0',
      height: '0',
      borderLeft: '6px solid transparent',
      borderRight: '6px solid transparent',
      borderTop: '6px solid black',
    }}></span>
  </div>
</div>


          <TextField
              sx={{ width: '69%', marginBottom: '3%' }}
              id="outlined-basic"
              label="Nom de la section"
              variant="outlined"
              defaultValue={type}
              onChange={(e) => setType(e.target.value)}
          />
      </div>
          <TextField
            id="filled-multiline-static"
            label="Description (optionnelle)"
            multiline
            rows={4}
            defaultValue={description}
            variant="filled"
            sx={{ width: '90%', marginBottom: '3%' }}
            onChange={(e) => setDescription(e.target.value)}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
          <h5>Publier :</h5>
          <Switch
            checked={showCategory}
            onChange={handleToggleCategoryForm}
            color="primary"
          />
        </div>
        <div style={{ display: 'flex', justifyContent: newType.length <= 0 ? 'flex-start' : 'center' }}>
        <Button sx={{backgroundColor:'#17174F', color:'white'}} variant="contained" onClick={handleSave}>
            Valider
        </Button>
        {newType.length > 0 && (
            <Button sx={{backgroundColor:'#17174F', color:'white',  marginLeft: '6%'}} variant="contained" onClick={handleDelete}>
                Supprimer
            </Button>
        )}
    </div>

        </CardContent>
      </Card>
      <CardImage open={openImageDialog} onClose={() => setOpenImageDialog(false)} handleSelectImage={handleSelectImage} />
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
    onDelete: (section) => dispatch(deleteType(section)),
    onEdit : (section) => dispatch(editType(section)),
    onAdd : (section) => dispatch(addType(section)),
  });
  
export default connect(null, mapDispatchToProps)(DialogSection);