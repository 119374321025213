import React from 'react';

const PrivacyPolicy = () => {
    return (
      <div style={{
        background: 'white',
        height: '80vh',
        overflowY: 'auto',
  scrollbarWidth: 'none', /* Pour Firefox */
  msOverflowStyle: 'none', /* Pour Internet Explorer et Edge */
  '&::-webkit-scrollbar': { display: 'none' }, /* Pour Chrome, Safari et Opera */ // Ajout pour activer le défilement vertical lorsque le contenu dépasse la hauteur spécifiée
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Effet d'élévation
        borderRadius: '20px', // Bordures arrondies
        padding: '20px' // Ajout pour un espacement interne
      }}>      
      <h2 style={{textAlign:'center'}}>Politique de Confidentialité de Yumz</h2>
      <p><strong>1. Introduction</strong></p>
      <p>La présente Politique de Confidentialité (ci-après dénommée la "Politique") décrit la manière dont Yumz (ci-après dénommée "Yumz" ou "nous") collecte, utilise et protège les informations personnelles des utilisateurs de notre plateforme logicielle en tant que service (SaaS), accessible via le site web yum-z.com (ci-après dénommé le "Site").</p>
      
      <p><strong>2. Collecte des Informations</strong></p>
      <p>2.1 Informations fournies par l'utilisateur : Lors de l'inscription sur notre plateforme, les utilisateurs peuvent être amenés à fournir des informations telles que leur nom, leur adresse e-mail, leur numéro de téléphone, et des informations relatives à leur restaurant.</p>
      <p>2.2 Données de navigation : Nous pouvons collecter des données de navigation, telles que l'adresse IP, le type de navigateur, les pages visitées, et les temps d'accès, à des fins d'analyse et d'amélioration de notre plateforme.</p>
      
      <p><strong>3. Utilisation des Informations</strong></p>
      <p>3.1 Fourniture des services : Les informations collectées sont utilisées pour fournir nos services aux utilisateurs, notamment pour gérer les comptes, traiter les paiements, et fournir un support technique.</p>
      <p>3.2 Amélioration de la plateforme : Nous utilisons les données collectées pour analyser l'utilisation de notre plateforme, identifier les tendances, et améliorer l'expérience utilisateur.</p>
      <p>3.3 Communication : Nous pouvons utiliser les informations fournies pour communiquer avec les utilisateurs concernant les mises à jour de la plateforme, les offres promotionnelles, et toute autre information jugée pertinente.</p>
      
      <p><strong>4. Protection des Informations</strong></p>
      <p>4.1 Sécurité : Nous mettons en place des mesures de sécurité techniques et organisationnelles pour protéger les informations personnelles contre tout accès non autorisé, utilisation abusive, ou divulgation.</p>
      <p>4.2 Conservation des données : Nous conservons les informations personnelles aussi longtemps que nécessaire pour remplir les finalités décrites dans la présente Politique, sauf si une période de conservation plus longue est requise ou autorisée par la loi.</p>
      
      <p><strong>5. Partage des Informations</strong></p>
      <p>Nous ne partageons pas les informations personnelles des utilisateurs avec des tiers, sauf dans les cas suivants :</p>
      <ul>
        <li>Avec le consentement de l'utilisateur.</li>
        <li>Pour se conformer à une obligation légale ou réglementaire.</li>
        <li>Pour protéger les droits, la propriété ou la sécurité de Yumz ou de ses utilisateurs.</li>
      </ul>
      
      <p><strong>6. Droits des Utilisateurs</strong></p>
      <p>Les utilisateurs ont le droit d'accéder, de rectifier, de supprimer ou de limiter l'utilisation de leurs informations personnelles. Ils peuvent exercer ces droits en nous contactant à l'adresse fournie ci-dessous.</p>
      
      <p><strong>7. Modifications de la Politique</strong></p>
      <p>Nous nous réservons le droit de modifier la présente Politique à tout moment. Les utilisateurs seront informés de toute modification via le Site ou par tout autre moyen jugé approprié.</p>
      
      <p><strong>8. Contact</strong></p>
      <p>Pour toute question concernant la présente Politique ou pour exercer les droits des utilisateurs en matière de protection des données, veuillez nous contacter à l'adresse suivante : contact@yum-z.com .</p>
      
      <p>En vous inscrivant, l'utilisateur reconnaît avoir lu, compris et accepté l'ensemble des dispositions qui y sont contenues dans la présente politique.</p>
    </div>
  );
}

export default PrivacyPolicy;
