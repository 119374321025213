import React, { useState } from 'react';
import { TextField, Modal, Checkbox, Typography, Button, FormControl, FormControlLabel } from '@mui/material';

const PaymentModal = ({ order, onClose, onPayment, currencies }) => {
  const [paymentTypes, setPaymentTypes] = useState({
    'Espèces': false,
    'Carte bancaire': false,
    'Ticket restaurant': false
  });
  const [paymentAmounts, setPaymentAmounts] = useState({
    'Espèces': order.price || '',
    'Carte bancaire': order.price || '',
    'Ticket restaurant': order.price || ''
  });
  const [error, setError] = useState(null);

  const handlePaymentTypeChange = (event) => {
    const { name, checked } = event.target;
    setPaymentTypes((prev) => ({ ...prev, [name]: checked }));
  };

  const handlePaymentAmountChange = (event) => {
    const { name, value } = event.target;
    setPaymentAmounts((prev) => ({ ...prev, [name]: value }));
  };

  const handlePay = () => {
    const selectedPaymentAmounts = Object.entries(paymentAmounts)
      .filter(([type, amount]) => paymentTypes[type] && amount)
      .map(([type, amount]) => ({ type, amount: parseFloat(amount) }));

    const totalPayment = selectedPaymentAmounts.reduce((sum, { amount }) => sum + amount, 0);

    if (totalPayment !== order.price) {
      setError("Le montant total des paiements doit correspondre au prix total de la commande.");
      setTimeout(() => setError(null), 3000);
      return;
    }

    let typePayment = selectedPaymentAmounts.length > 1 
      ? selectedPaymentAmounts.map(({ type, amount }) => `${amount} ${currencies.symbol} ${type}`).join(' / ') 
      : `${selectedPaymentAmounts[0].amount} ${currencies.symbol} ${selectedPaymentAmounts[0].type}`;

    onPayment(typePayment);
    setPaymentTypes({
      'Espèces': false,
      'Carte bancaire': false,
      'Ticket restaurant': false
    })
  };

  return (
    <Modal open onClose={onClose}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
      <div style={{color:'black', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: '10px', textDecoration: 'underline' }}>
        <Typography variant="h9">{order.nom_client} - {`Tab. ${order.numberOfTable}`}</Typography>
      </div>        
      <div style={{color:'black', display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: '10px' }}>
        <Typography variant="body2">{order.onPlace ? 'Sur Place' : 'Emporter'}</Typography>
      </div>
      <div style={{ marginBottom: '10px' }}>
        {order.order.map((item, itemIndex) => (
          <div key={itemIndex} style={{color:'black', display: 'flex', flexDirection: 'column', marginBottom: '3%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight="bold">
                {item.name}
              </Typography>
              <div style={{ flex: 1, borderBottom: '1px dotted black', margin: '0 10px' }}></div>
              <Typography variant="body2" fontWeight="bold">
                {`x${item.quantity}`}
              </Typography>
            </div>
            {item.unique_selections && item.unique_selections.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                {item.unique_selections.map((sel) => sel.name).join(', ')}
              </Typography>
            )}
            {item.multiple_selections && item.multiple_selections.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                {item.multiple_selections.map((sel) => sel.name).join(', ')}
              </Typography>
            )}
            {item.supplements && item.supplements.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '5px', fontWeight: 400 }}>
                {item.supplements.map((sup) => `${sup.quantity}x ${sup.name}`).join(', ')}
              </Typography>
            )}
            {item.note && item.note.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                Note : {item.note}
              </Typography>
            )}
          </div>
        ))}
      </div>
      <div style={{color:'black', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
        <Typography variant="body2" fontWeight="bold">Prix Total : {`${order.price} ${currencies.symbol}`}</Typography>
      </div>

        <FormControl component="fieldset" style={{ marginTop: '20px' }}>
          <Typography variant="body2" sx={{ color: 'red' }}>{error}</Typography>
          <Typography sx={{color:'black', fontSize: '13px' }} gutterBottom>Choisissez le mode de paiement :</Typography>
          {['Espèces', 'Carte bancaire', 'Ticket restaurant'].map((type) => (
            <div key={type} style={{color:'black', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
              <FormControlLabel
                control={<Checkbox checked={paymentTypes[type]} onChange={handlePaymentTypeChange} name={type} />}
                label={<Typography sx={{ fontSize: '13px' }}>{type}</Typography>}
              />
              {paymentTypes[type] && (
                <TextField
                  name={type}
                  label="Montant"
                  value={paymentAmounts[type]}
                  onChange={handlePaymentAmountChange}
                  size="small"
                  type="number"
                  style={{ marginLeft: '10px' }}
                />
              )}
            </div>
          ))}
        </FormControl>

        <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="outlined" style={{ marginRight: '10px' }} onClick={onClose}>
            Annuler
          </Button>
          <Button variant="contained" color="primary" onClick={handlePay}>
            Payer
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PaymentModal;
