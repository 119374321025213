import React from 'react';
import './NavBar.css';

const NavBar = ({ onSelectSection, selectedSection }) => {
  const types = ['Avis client', 'Promotion produit', 'Rappel & Promo'];

  return (
    <div className='NavBar' style={{ position: 'fixed', top: '20%' }}>
      {types.map((item, index) => (
        <div
          key={index}
          className='NavItem'
          style={{
            marginRight: '20px',
            fontWeight: 500,
            cursor: 'pointer',
            padding: '8px',
            display: 'inline-block',
            color: selectedSection === item ? '#3F48A8' : '#242d49',
            borderBottom: selectedSection === item ? '2px solid #B340FF' : 'none',
          }}
          onClick={() => onSelectSection(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default NavBar;
