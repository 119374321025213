import React, { useEffect, useState } from 'react';
import { Grid, Typography, Slider, TextField } from '@mui/material';
import { format } from 'date-fns';

const Filter = ({ onFiltersChange }) => {
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem('dateClients') ? new Date(JSON.parse(localStorage.getItem('dateClients'))) : null
  );
  const [decimalValue, setDecimalValue] = useState(localStorage.getItem('ratingClients')?parseFloat(JSON.parse(localStorage.getItem('ratingClients'))):0);

  useEffect(()=>{
    console.log("selectedDate, decimalValue...", selectedDate, decimalValue, JSON.parse(localStorage.getItem('date')))
    const formattedValue = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
    onFiltersChange(formattedValue, decimalValue)
  },[])
  const handleDateChange = (e) => {
    if (e.target.value === '') {
      onFiltersChange(null, decimalValue);
      setSelectedDate(null);
      localStorage.setItem('dateClients', '' )
    } else {
      const newDate = e.target.value ? new Date(e.target.value) : null;
      const formattedValue = newDate ? format(newDate, 'dd/MM/yyyy') : '';
      setSelectedDate(newDate);
      localStorage.setItem('dateClients', JSON.stringify(newDate) )
      newDate ? onFiltersChange(formattedValue, decimalValue) : onFiltersChange(decimalValue);
    }
  };

  const handleDecimalChange = (event, newValue) => {
    const formattedValue = selectedDate ? format(selectedDate, 'dd/MM/yyyy') : '';
    setDecimalValue(newValue);
    localStorage.setItem('ratingClients', JSON.stringify(newValue) )
    onFiltersChange(formattedValue, newValue);
  };

  return (
    <Grid container alignItems="center" spacing={2} style={{paddingTop:"3%"}}>
      {/* Date Filter */}
      <Grid item xs={4} sm={2}>
        <TextField
          id="date"
          type="date"
          value={selectedDate ? selectedDate.toISOString().split('T')[0] : ''}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>

      {/* Decimal Value Filter */}
      <Grid item xs={4} sm={2}>
        <Typography variant="subtitle1" align="center">
          Nombre d'étoiles
        </Typography>
        <Slider
          value={decimalValue}
          onChange={handleDecimalChange}
          min={0}
          max={5}
          step={0.1}
          valueLabelDisplay="auto"
          valueLabelFormat={(value) => value.toFixed(1)}
          sx={{color:'rgb(43, 48, 123, 0.5)'}}
        />
      </Grid>
    </Grid>
  );
};

export default Filter;
