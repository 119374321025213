import React, {useState} from "react";
import Chart from "react-apexcharts";


const CustomerReview = ({ param }) => {
  // Récupérer les données de paramètre
  const [hours, orderCounts] = param || [[], []];

  // Convertir les heures au format requis par ApexCharts
  const categories = hours.map(hour => {
    // Créer un objet Date à partir du timestamp de l'heure
    const date = new Date(hour);
    // Extraire l'heure et les minutes
    const formattedHour = date.getHours().toString().padStart(2, '0');
    const formattedMinutes = date.getMinutes().toString().padStart(2, '0');
    // Retourner la chaîne au format "HH:MM"
    return `${formattedHour}:${formattedMinutes}`;
  });
  console.log("orderCounts......", hours)
  const data = {
    series: [
      {
        name: "Commandes",
        data: orderCounts,
      },
    ],
    options: {
      chart: {
        type: "area",
        height: "auto",
      },

      fill: {
        colors: ["#fff"],
        type: "gradient",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        colors: ["#ff929f"],
      },
      tooltip: {
        x: {
          format: "HH:mm",
        },
      },
      grid: {
        show: false,
      },
      xaxis: {
        type: "category",
        categories: hours,
      },
      yaxis: {
        show: false
      },
      toolbar: {
        show: false
      }
    },
  };

  return (
    <div className="CustomerReview">
      <Chart options={data.options} series={data.series} type="area" />
    </div>
  );
};


export default CustomerReview;

