import React from 'react';
import Tooltip from 'react-tooltip-lite'; // Importer le composant Tooltip

const ExclamationTooltip = ({ text }) => {
  return (
    <Tooltip
      content={text} // Le texte à afficher dans l'info-bulle
      direction="top" // Direction de l'info-bulle (top, bottom, left, right)
      arrowSize={10} // Taille de la flèche de l'info-bulle
      background="#333" // Couleur de fond de l'info-bulle
      padding="8px" // Marge intérieure de l'info-bulle
    >
      <span style={{ cursor: 'pointer',width:'10px',height:'10px',borderRadius:'50%', }}> {/* Style du point d'exclamation avec curseur pointer */}
        !
      </span>
    </Tooltip>
  );
};

export default ExclamationTooltip;
