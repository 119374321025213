import React from "react";
import "./Cards.css";
import { cardsData } from "../../Data/Data";
import { currencies } from "../../actions/currencies";
import Card from "../Card/Card";

const Cards = ({value }) => {
  let i = -1
  return (
    <div className="Cards">
      {cardsData.map((card, id) => {
        i = i +1
        return (
          <div className="parentContainer" key={id}>
            <Card
              title={card.title}
              color={card.color}
              textColor={card.colorText.color}
              value={i==0?value[i]+" "+currencies()?.code:value[i]}
              png={card.png}
            />
          </div>
        );
      })}
    </div>
  );
};

export default Cards;
