import React, { useState, useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, Modal, Typography,IconButton } from '@mui/material';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { useNavigate } from 'react-router-dom';
import EditOrderModal from './EditOrderModal';
import client from '../../../api/client';
const CardCommandSmall = ({ 
    order = [], onDeleteItem,onDeleteItem2, navigation, currencies,editOrder }) => {
  const [elapsedMinutes, setElapsedMinutes] = useState(0);
  const navigate = useNavigate();
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const waitingTime = parseInt(localStorage.getItem('watingTime') !== undefined ? localStorage.getItem('watingTime') : 18)

// Utiliser useEffect pour mettre à jour le temps écoulé chaque minute
useEffect(() => {
  const calculateElapsedMinutes = () => {
    const [targetHours, targetMinutes] = order.hour.split(':').map(Number);
      // Get the current time
      const now = new Date();
      const currentHours = now.getHours();
      const currentMinutes = now.getMinutes();
      console.log("minutes...", currentHours, currentMinutes)
  
      // Convert target time and current time to total minutes from midnight
      const targetTotalMinutes = targetHours * 60 + targetMinutes;
      const currentTotalMinutes = currentHours * 60 + currentMinutes;
    
      // Calculate the difference in minutes
      const differenceInMinutes = targetTotalMinutes - currentTotalMinutes;
      return Math.abs(differenceInMinutes)
    };

  // Calculer le temps écoulé initial
  const initialElapsedMinutes = calculateElapsedMinutes();
  setElapsedMinutes(initialElapsedMinutes);

  // Mettre à jour le temps écoulé chaque minute
  const intervalId = setInterval(() => {
    const updatedElapsedMinutes = calculateElapsedMinutes();
    setElapsedMinutes(updatedElapsedMinutes);
  }, 60000); // Mettre à jour toutes les 60 secondes

  // Nettoyer l'intervalle lors du démontage du composant
  return () => clearInterval(intervalId);
}, [order.hour]);

const finishCommand = async (orderId) => {
  console.log('Commande validée...', orderId);
  // Logique pour valider la commande avec une requête API
  try {
    const token = JSON.parse((localStorage.getItem("tokenRestorer")))
    if(token){
      await client.post('/validateOrNotOrder',{orderId:order.orderId, newStatus:"En cours", paymentTypes:order.type},{
                headers: {
                  Accept: 'application/json',
                  //'content-Type': 'multipart/form-data',
                  Authorization : `JWT ${token}`,
                }
              }).then((res)=>{
                console.log("res.data...", res.data)
                if(!res?.data?.success){
                  navigate('/comptoir/connexion');
                }
                if(res?.data?.success){
                  onDeleteItem2(order.orderId);
                }
              })      
    }else {
      navigate('/comptoir/connexion');
    }
  } catch (error) {
    console.error('Erreur lors de la validation de la commande :', error);
  }
};
  const handleCardClick = (item) => {
    if (order.note && order.note.length > 0) {
      console.log('Afficher la note :', order.note);
    }
  };
  const handleEditClick = () => {
    setEditModalOpen(true);
  };
  const handleSaveOrder = (updatedOrder) => {
    // setCurrentOrder(updatedOrder);
     editOrder(updatedOrder)
   };
   const calculateBackgroundColor = () => {
    const ratio = Math.min(elapsedMinutes / (waitingTime*2), 1);
    const red = 255;
    const green = 255 - Math.floor(255 * ratio);
    const blue = 255 - Math.floor(255 * ratio);
    return `rgba(${red}, ${green}, ${blue}, 0.5)`;
  };
  return (
    <div
            key={order.orderId}
            style={{
              maxWidth: '100%', // La carte prend la largeur complète de son conteneur
              marginRight: '20px',
              marginBottom: '20px',
              flexDirection: 'column',
              display: 'flex',
              borderRadius: '10px',
              boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
              overflow: 'hidden',
              padding: '15px',
              backgroundColor:calculateBackgroundColor()
            }}
            onClick={() => handleCardClick(order)}
          >
          <IconButton
        style={{ position: 'relative', top: '10px', right: '0%' }}
        onClick={handleEditClick}
      >
        <BorderColorIcon />
      </IconButton>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: '10px', textDecoration: 'underline' }}>
        <Typography variant="h9">{order.nom_client} - {`Tab. ${order.numberOfTable}`}</Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: '10px' }}>
        <Typography variant="body2">{order.onPlace ? 'Sur Place' : 'Emporter'}</Typography>
      </div>
      <div style={{ marginBottom: '10px' }}>
        {order.order.map((item, itemIndex) => (
          <div key={itemIndex} style={{ display: 'flex', flexDirection: 'column', marginBottom: '3%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight="bold">
                {item.name}
              </Typography>
              <div style={{ flex: 1, borderBottom: '1px dotted black', margin: '0 10px' }}></div>
              <Typography variant="body2" fontWeight="bold">
                {`x${item.quantity}`}
              </Typography>
            </div>
            {item.unique_selections && item.unique_selections.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                {item.unique_selections.map((sel) => sel.name).join(', ')}
              </Typography>
            )}
            {item.multiple_selections && item.multiple_selections.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                {item.multiple_selections.map((sel) => sel.name).join(', ')}
              </Typography>
            )}
            {item.supplements && item.supplements.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '5px', fontWeight: 400 }}>
                {item.supplements.map((sup) => `${sup.quantity}x ${sup.name}`).join(', ')}
              </Typography>
            )}
            {item.note && item.note.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                Note : {item.note}
              </Typography>
            )}
          </div>
        ))}
      </div>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
        <Typography variant="body2" fontWeight="bold">Prix Total : {`${order.price} ${currencies.symbol}`}</Typography>
      </div>
            <div style={{ position: 'relative', bottom: '2%', marginLeft: '2%', display: 'flex', justifyContent: 'space-between', padding: '15px' }}>
              <Button onClick={() => onDeleteItem(order.orderId)}>
                <DeleteForeverIcon sx={{fontSize:'200%', color:'#17174F'}} />
              </Button>
              <Button onClick={() => finishCommand(order.orderId)} style={{ marginLeft: '10px' }}>
                <CheckCircleOutlineIcon sx={{fontSize:'200%', color:'#17174F'}} />
              </Button>
            </div>
            <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center', }}>
              <EditOrderModal
        open={isEditModalOpen}
        onClose={() => setEditModalOpen(false)}
        order={order}
        onSave={handleSaveOrder}
        currencies={currencies}
      />
            </div>
    </div>
  );
};

export default CardCommandSmall;
