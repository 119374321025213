import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DialogSection from './DialogSection';
import "./RightSideProducts.css";

const RightSideProducts = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateSection = () => {
    setAnchorEl(null);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="ProductsButton">
      <Fab color="extended" aria-label="add" onClick={handleClick}>
        <AddIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCreateSection}>Créer une section culinaire</MenuItem>
        <MenuItem onClick={handleClose}>Créer un Menu spécial</MenuItem>
        <div></div>
      </Menu>

      <DialogSection open={openDialog} onClose={handleCloseDialog} />
    </div>
  );
};

export default RightSideProducts;