import React, { useState, useEffect } from "react";
import { Button } from '@mui/material';
import client from "../../api/client";
import {CircularProgress, Backdrop } from '@mui/material';

const AccesCode = ({}) => {
    const [id, SetId] = useState('')
    const [accessCode, setAccessCode] = useState('')
    useEffect(()=>{
        const fetch = async()=>{
            const token = JSON.parse((localStorage.getItem("token")))
            await client.post('get-code',{token},{headers: {
                Accept: 'application/json',
                //'content-Type': 'multipart/form-data',
                authorization : `JWT ${token}`,
              }
            }).then((res)=>{
                if(res.data.success){
                    SetId(res.data.id)
                    setAccessCode(res.data.accessCode)
                }
              })}
              fetch()
      },[])
    
      useEffect(()=>{

    },[id, accessCode])
    const handleChangeCode = async()=>{
        const token = JSON.parse((localStorage.getItem("token")))
        await client.post('/set-code',{token},{headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
            authorization : `JWT ${token}`,
          }
        }).then((res)=>{
            if(res.data.success){
                SetId(res.data.id)
                setAccessCode(res.data.accessCode)
            }
          })
    }

  return (
    <div style={{ border: "1px solid #ccc", borderRadius: "5px", padding: "10px", marginBottom: "10px", width:'50%' }}>
      <p><span style={{fontWeight:'bold'}}>ID: </span>{id}</p>
      <p><span style={{fontWeight:'bold'}}>Code d'accès: </span>{accessCode}</p>
      <Button variant="outlined" onClick={() => handleChangeCode()}>Réinitialiser</Button>
    </div>
  );
};

export default AccesCode;
