import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    transition: 'box-shadow 0.3s',
    boxShadow: '0px 3px 10px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: '0px 6px 15px rgba(0, 0, 0, 0.3)',
    },
  },
  image: {
    width: 30,
    height: 30,
    cursor: 'pointer',
  },
}));

function extractDomainName(url) {
  // Ajouter le protocole si nécessaire
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    url = 'http://' + url;
  }

  const a = document.createElement('a');
  a.href = url;

  let hostname = a.hostname;

  if (hostname.startsWith('www.')) {
    hostname = hostname.slice(4);
  }

  const domainParts = hostname.split('.');
  const domainName = domainParts.length > 1 ? domainParts[domainParts.length - 2] : domainParts[0];

  return domainName;
}


const SocialNetwork = ({ network = '', handleSaveNetwork, deleteNetwork }) => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(network);
  const [deleteValue, setDeleteValue] = useState(network);
  
  const networks = {
    facebook: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711972886/5365678_fb_facebook_facebook_logo_icon_xbdjj4.png",
    snapchat: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711972886/5296508_snapchat_logo_ghost_snap_snapchat_social_media_icon_fihyja.png",
    twitter: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711972886/5296516_tweet_twitter_twitter_logo_icon_vlbqzj.png",
    instagram: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711972886/5296765_camera_instagram_instagram_logo_icon_me5egu.png",
    tiktok: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711972886/7024783_tiktok_social_media_icon_x6qfu7.png",
    default: "https://res.cloudinary.com/switchercloudpics/image/upload/v1711972886/5172949_browser_internet_page_security_web_icon_i7ba1p.png",
  };

  const handleClick = () => {
    setEditing(true);
  };

  const handleSave = () => {
    if (value.length > 0) {
      console.log("SOcila Network...",value,extractDomainName(value) )
      handleSaveNetwork({ icon: getImageUrl(value), url: value, name: extractDomainName(value) });
    } else {
      deleteNetwork(deleteValue);
    }
    setEditing(false);
  };

  const getImageUrl = (value) => {
    const networkLowerCase = value.toLowerCase();
    if (networkLowerCase.includes("facebook")) {
      return networks.facebook;
    } else if (networkLowerCase.includes("snapchat")) {
      return networks.snapchat;
    } else if (networkLowerCase.includes("twitter")) {
      return networks.twitter;
    } else if (networkLowerCase.includes("instagram")) {
      return networks.instagram;
    } else if (networkLowerCase.includes("tiktok")) {
      return networks.tiktok;
    } else {
      return networks.default;
    }
  };

  return (
    <div>
      {editing ? (
        <div>
          <TextField
            value={value}
            onChange={(e) => setValue(e.target.value)}
            variant="outlined"
            fullWidth
            autoFocus
          />
          <IconButton onClick={handleSave}>
            <SaveIcon />
          </IconButton>
        </div>
      ) : (
        <div>
          <img
            src={getImageUrl(value)}
            alt="Social Network"
            className={classes.image}
            onClick={handleClick}
          />
        </div>
      )}
    </div>
  );
};

export default SocialNetwork;
