// Function to generate a random ID with a maximum length of 6 characters
export const generateId = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
  
    for (let i = 0; i < 9 + 1; i++) {
      id += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  
    return id;
  };
  
  // Function to generate a random access code with a maximum length of 4 characters
  export const accessCode = () => {
    const characters = '0123456789';
    let code = '';
  
    for (let i = 0; i < 5 + 1; i++) {
      code += characters.charAt(Math.floor(Math.random() * characters.length));
    }
  
    return code;
  };