import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Button, FormControlLabel, Checkbox, IconButton, Modal } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ProductForm from './ProductForm';
import Switch from '@mui/material/Switch';
import { addCategory, deleteCategory, editCategory } from '../../actions/productActions';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import client from '../../api/client';
const CategoryDialog = ({id_type, category={}, onClose, data={},type, onDelete, onAdd, onEdit  }) => {
  const [categoryName, setCategoryName] = useState(category?.nom||'');
  const [showProduct, setShowProduct] = useState(category?.display||false);
  const [showProductForm, setShowProductForm] = useState(false);
  const [displayType, setDisplayType] = useState(category?.dataCat?.position||'horizontal');
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"))

  const handleDisplayChange = (event) => {
    setDisplayType(event.target.value);
  };
  const handleSave = async() => {
    // Ajoutez la logique pour sauvegarder les modifications de la catégorie
    // category: { nom: categoryName, ...autres propriétés }
    const id = category.id
    const nom = categoryName
    const display = showProduct
    console.log("dans position", category)
    if(Object.keys(category).length !== 0){
      await client.post("/edit-subcategory", {
        subcategoryId:category.id,
        nom: nom||'',
        display:display||false,
        position:displayType||'horizontal',
        },
        {
          headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
            Authorization : `JWT ${token}`,
           }
        }
        ).then((res)=>{
          if(!res?.data?.success){
            //navigate('/')
          }else{
            onEdit({type,nom,id:res?.data?.updatedSubcategory._id, displayType, display, idType:id_type})
            onClose();
          }
        })
    }else{
      await client.post("/create-subcategory", {
        nom: nom||'',
        display:display||false,
        position:displayType||'horizontal',
        idType:id_type
        },
        {
          headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
            Authorization : `JWT ${token}`,
           }
        }
        ).then((res)=>{
          console.log('creation de la catégorie ',res.data)
          if(!res?.data?.success){
            //navigate('/')
          }else{
            onAdd({type, nom,id:res?.data?.subcategory._id, displayType, display, idType:id_type})
            onClose();
          }
        })
    }
  };
  const handleDelete = async()=>{
    console.log("dans delete Category", categoryName)
    const nom = categoryName
    const id = category.id
    await client.post("/delete-subcategory", {
      idSubcategory:id,
      id_restaurant:await JSON.parse(localStorage.getItem("restaurant"))?._id||'R.A.S',
      idType:id_type,
      },
      {
        headers: {
          Accept: 'application/json',
          //'content-Type': 'multipart/form-data',
          Authorization : `JWT ${token}`,
         }
      }
      ).then((res)=>{
        if(!res?.data?.success){
          navigate('/')
        }else{
          onDelete({id_type, id})
        }
      })
    onClose();
  }
  const handleToggleProductForm = () => {
    setShowProduct(!showProduct);
  };

  const handleToggleProductFormVisibility = () => {
    setShowProductForm(!showProductForm);
  };

  return (
    <Dialog open={true} onClose={onClose}>
      {Object.keys(category).length !== 0?<DialogTitle>
        Modifier la Catégorie
        <IconButton
          style={{ marginLeft: '90%', backgroundColor: '#242d49', color: 'white' }}
          onClick={handleToggleProductFormVisibility}
        >
          <AddIcon />
        </IconButton>
      </DialogTitle>:null}
      <DialogContent>
        <TextField
          label="Nom de catégorie"
          variant="outlined"
          fullWidth
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
       <div style={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
      <h5>Affichage :</h5>
      <div>
        <input
          type="radio"
          id="horizontal"
          value="horizontal"
          checked={displayType === 'horizontal'}
          onChange={handleDisplayChange}
        />
        <label htmlFor="horizontal">Horizontal</label>
      </div>
      <div>
        <input
          type="radio"
          id="vertical"
          value="vertical"
          checked={displayType === 'vertical'}
          onChange={handleDisplayChange}
        />
        <label htmlFor="vertical">Vertical</label>
      </div>
      </div>
      

      <div style={{ display: 'flex', alignItems: 'center' }}>
          <h5>Publier :</h5>
          <Switch
            checked={showProduct}
            onChange={handleToggleProductForm}
            color="primary"
          />
        </div>

        <Modal open={showProductForm} onClose={handleToggleProductFormVisibility}>
          <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: 16, borderRadius: 8 }}>
            <ProductForm type={type} nom={categoryName} id_subcategory={category.id} onClose={handleToggleProductFormVisibility}/>
          </div>
        </Modal>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
          <Button sx={{backgroundColor:'#17174F', color:'white',  ":hover":{backgroundColor:"#17174F", color:'white'}}} onClick={handleSave}>
            Enregistrer
          </Button>
          {Object.keys(category).length !== 0?<Button 
            sx={{color:"#17174F", borderColor :"#17174F", ":hover":{backgroundColor:"red", borderColor:'#red', color:'white'}}} size="small" variant="outlined"
            onClick={handleDelete}>
            Supprimer
          </Button>:<Button variant="outlined"  onClick={onClose}>
            Annuler
          </Button>}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onDelete: (categoryToDelete) => dispatch(deleteCategory(categoryToDelete)),
  onEdit : (newCategory) => dispatch(editCategory(newCategory)),
  onAdd : (newCategory) => dispatch(addCategory(newCategory)),
});

export default connect(null, mapDispatchToProps)(CategoryDialog);