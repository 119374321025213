import './App.css'
import React, { useState, useEffect } from 'react';
import HomePage from './components/HomePage/HomePage';

import AllComponents from './components/AllComponents';
import { BrowserRouter as Router, Route, Routes,Link } from 'react-router-dom';
import socket from './api/socket';
import { initialState } from './reducers/productReducer';
import Login from './components/Comptoir/Login';
import MainOrder from './components/Comptoir/MainOrder';
import OrdersHistory from './components/Comptoir/OrdersHistory';
import ReactGa from 'react-ga'
function App() {
  socket.connect()
  useEffect(
    ()=>{
      ReactGa.initialize('GTM-MTXHLV5M')
      ReactGa.pageview('/')
    },[])
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/all-components" element={<AllComponents socket={socket} data={initialState.data}/>} />
          <Route path="/comptoir/connexion" element={<Login/>} />
          <Route path="/comptoir/commandes" element={
              <MainOrder socket={socket} />
          } />          
          <Route path="/comptoir/historique-commandes" element={<OrdersHistory socket={socket}/>} />
        </Routes>
      </div>
    </Router>
  );
}

/**
 * function App() {
  socket.connect()
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/all-components" element={<AllComponents socket={socket} data={initialState.data}/>} />
        </Routes>
      </div>
    </Router>
  );
}
 */


export default App;