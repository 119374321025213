// rootReducer.js
import { combineReducers } from 'redux';
import {productReducer} from './reducers/productReducer'; // Assurez-vous de fournir le chemin correct

const rootReducer = combineReducers({
  // Ajoutez d'autres réducteurs si nécessaire
  product: productReducer,
});

export default rootReducer;
