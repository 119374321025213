import React, { useState, useRef } from 'react';
import './ValidEmail.css'; // Assurez-vous d'importer le CSS fourni
import client from '../../api/client';
import {CircularProgress, Backdrop} from '@mui/material';

const ValidEmail = ({ manager, token, updateEmail }) => {
  const [code, setCode] = useState(['', '', '', '']);
  const [errorMessage, setErrorMessage] = useState('');
  const [message, setMessage] = useState('Nous avons envoyé un code de vérification par mail');
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;

    if (value.match(/^\d$/) && index < 3) {
      inputRefs.current[index + 1].focus();
    }

    setCode(newCode);
  };

const handleValidation = async () => {
    const isAllDigits = code.every((digit) => /\d/.test(digit));
    if (isAllDigits && code.length === 4) {
      const accessCode = code.join('');
      try {
        setLoading(true); // Afficher la barre de chargement
        const res = await client.post(
          '/valid-email',
          { managerId: manager?._id, accessCode },
          {
            headers: {
              Accept: 'application/json',
              authorization: `JWT ${token}`,
            },
          }
        );

        if (res.data.success) {
          localStorage.setItem('manager', JSON.stringify(res.data.manager));
          updateEmail();
        } else {
          setErrorMessage(res.data.error);
          setTimeout(() => {
            setErrorMessage('');
          }, 3000);
        }
      } catch (error) {
        console.error('Error validating email:', error);
      }finally {
        setLoading(false); // Cacher la barre de chargement
      }
    } else {
      console.log("isAllDigits............", isAllDigits, code.join(''));
      setErrorMessage('Veuillez entrer un code valide de 4 chiffres');
      setTimeout(() => {
        setErrorMessage('');
      }, 3000);
    }
  };

  const resendMail = async () => {
    try {
      setLoading(true); // Afficher la barre de chargement
      const res = await client.post(
        '/resend-mail',
        {},
        {
          headers: {
            Accept: 'application/json',
            authorization: `JWT ${token}`,
          },
        }
      );

      if (res.data.success) {
        setMessage('Sans soucis, un mail vous a été encore envoyé');
      } else {
        setErrorMessage(res.data.error);
        setTimeout(() => {
          setErrorMessage('');
        }, 3000);
      }
    } catch (error) {
      console.error('Error validating email:', error);
    }finally {
      setLoading(false); // Cacher la barre de chargement
    }
  };

  return (
    <form className="form">
      <div className="title">Code de Verification</div>
      <p className="message">{message}</p>
      <div className="inputs">
        {code.map((digit, index) => (
          <input
            key={index}
            type="text"
            maxLength="1"
            value={digit}
            onChange={(e) => handleChange(index, e.target.value)}
            ref={(el) => (inputRefs.current[index] = el)}
            className="inputField"
          />
        ))}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
        <button type="button" className="action" onClick={() => resendMail()}>
          Renvoyer
        </button>
        <button type="button" className="action" onClick={() => handleValidation()}>
          S'authentifier
        </button>
      </div>
      {errorMessage && <p className="error">{errorMessage}</p>}
      <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </form>
  );
};

export default ValidEmail;
