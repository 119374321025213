import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import client from '../../api/client';
import OrderHistory from './Components/OrderHistory';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation } from 'react-router-dom';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PaymentsIcon from '@mui/icons-material/Payments';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import './OrdersHistory.css'
const OrdersHistory = () => {
  const navigate = useNavigate();
    const location = useLocation();

    const navigateLogin = (location) => {
        if (!location.state) {
            console.log('location null.... ', location);
            navigate('/comptoir/connexion');
        }
    };

    useEffect(() => {
        navigateLogin(location);
    }, [location]);
  const currenciesJson = localStorage.getItem("currencies")
  const restaurantIdJson = localStorage.getItem("restaurantId")
  const [currencies,setCurrencies]  = useState(currenciesJson?JSON.parse(currenciesJson):{code:'',})
  const [restaurantId,setRestaurantId]  = useState(restaurantIdJson?JSON.parse(restaurantIdJson):null)
  const [selectedButton, setSelectedButton] = useState('Tout');
  const [orders, setOrders] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleButtonPress = (buttonName) => {
    setSelectedButton(buttonName);
  };
  console.log("restaurantId....", restaurantId)
  const { orderRestorer} = location.state;

  useEffect(() => {
    const getAllOrders = async () => {
      setIsLoading(true);
      try {
        const token = JSON.parse((localStorage.getItem("tokenRestorer")))
        if(token){
          const res = await client.post('/getHistoryOrders', { restaurantId }, {
            headers: {
              'Cache-Control': 'no-cache',
              Accept: 'application/json',
              authorization: `JWT ${token}`,
            }
          });
          if (res.data.success) {
            setOrders(res.data.ordersHistory);
            setMainData(res.data.mainCardsData);
          } else {
            navigate('/comptoir/connexion');
          }
        }else {
          navigate('/comptoir/connexion');
        }
      } catch (error) {
        console.error("Error fetching orders: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    getAllOrders();
  }, []);

  const filterRows = () => {
    switch (selectedButton) {
      case 'En cours':
        return orders.filter((order) => order.status === 'En cours');
      case 'Terminée':
        return orders.filter((order) => order.status === 'Terminée');
      case 'Refusée':
        return orders.filter((order) => order.status === 'Refusée');
      default:
        return orders;
    }
  };

  const renderOrders = filterRows();
const navigateOrders = ()=>{
    navigate('/comptoir/commandes', { state:{orderRestorer}});
}
const isScreenSmall = () => {
        return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
      };
//width:'90 wh', height : '100 vh'
  return (
    <div className="orders-history-container"  >
      <div style={{padding:isScreenSmall()?'5%':'5%'}}>
      <div style={{ display: 'flex',justifyContent:'flex-start',  alignItems: 'flex-star',}}>
        <ArrowBackIcon onClick={navigateOrders} />
      </div>

      {mainData && mainData?.length > 0 && (
        <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between',  width:isScreenSmall()?'90%':'50%', marginLeft:isScreenSmall()?'5%':'25%' }}>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', justifyContent:'center', alignItems:'center', fontSize:isScreenSmall()?'10px':'16px' }}>
            <PaymentsIcon sx={{fontSize:isScreenSmall()?'20':'50'}} />
            <p style={{fontSize:isScreenSmall()?'10px':'16px'}}>{mainData[0]} {currencies.symbol}</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', justifyContent:'center', alignItems:'center', fontSize:isScreenSmall()?'10px':'16px' }}>
            <PeopleOutlineIcon />
            <p style={{fontSize:isScreenSmall()?'10px':'16px'}}>{mainData[1]}</p>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '10px', justifyContent:'center', alignItems:'center', fontSize:isScreenSmall()?'10px':'16px' }}>
            <FilterFramesIcon />
            <p style={{fontSize:isScreenSmall()?'10px':'16px'}}>{mainData[2]}</p>
          </div>
        </div>
      )}

      <div style={{display:'flex',flexDirection:'row', justifyContent:'space-between',  width:isScreenSmall()?'90%':'50%', marginLeft:isScreenSmall()?'5%':'25%' }}>
      {['Tout', 'En cours', 'Terminée', 'Refusée'].map((buttonName) => (
        <div
          style={{fontSize:isScreenSmall()?'10px':'16px'}}
            key={buttonName}
            onClick={() => handleButtonPress(buttonName)}
            className={`orders-history-button ${selectedButton === buttonName ? 'orders-history-button-selected' : ''}`}
          >
            {buttonName}
          </div>
        ))}
      </div>

      <div style={{ maxHeight: '65vh', overflowY: 'auto', /* Pour Chrome, Safari et Opera */ marginTop:isScreenSmall()?'0.5%':'3%',
       /* Pour Chrome, Safari et Opera */
       }}>
        {renderOrders.map((order) => (
          <OrderHistory key={order.orderId} order={order} currencies={currencies} />
        ))}
      </div>
    </div>
  </div>

  );
};

export default OrdersHistory;
