import React, { useState } from 'react';
import './TableReview.css'; // Assurez-vous de créer ce fichier CSS pour les styles
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography';

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Les mois sont 0-indexés, donc on ajoute 1
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
const questions = [
  'Comment avez-vous entendu parler de nous ?',
  'De quelle tranche d\'âge faites-vous partie ?',
  'Donnez une note a chaque plat :',
  'L’accueil reçu à votre arrivée était-il chaleureux et courtois?',
  'Le délai d’attente était-il adéquat?',
  'À combien estimez-vous votre satisfaction globale vis-à-vis de nos services?',
  'À combien estimez-vous vos chances de revenir à notre restaurant?',
  'Donnez un avis au restaurant',
];
const Reviews = ({ data }) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [answers, setAnswers] = useState([])
  const [review, setReview] = useState('')
  const [rating, setRating] = useState(0)

  const handleRowClick = (index, res, newReview, rate) => {
    setSelectedRow(index);
    console.log("data......", res)
    const answersArray = res ? Object.values(res) : [];
   setAnswers(answersArray)
   setReview(newReview)
   setRating(rate)
    handleOpen()  
  };

  const closeModal = () => {
    handleClose();
  };
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
    <>{isScreenSmall() ?
      (
        <div className="smallScreenTable" style={{width:isScreenSmall()?'100%':null}}>
      {/* En-tête */}
      <div className="smallScreenTable-row header colorHeader">
        <div style={{ }}>Commande</div>
        <div className="table-cell" style={{ marginRight: '2%' }}>Client</div>
        <div className="table-cell">Note</div>
        <div className="table-cell">Avis</div>
        <div className="table-cell">Heure</div>
        <div className="table-cell" style={{ marginRight: '-2%' }}>Date</div>
      </div>

      {/* Données scrollable */}
      <div className="data-container">
        {data?.map((review, index) => (
          <div
            key={index}
            className={`table-row data ${selectedRow === index ? 'selected' : ''}`}
            onClick={() => handleRowClick(index, review?.answers, review?.review, review?.rating)}
          >
            <div className="table-cell">{review.order.substring(0, 20) + '...'}</div>
            <div className="table-cell">{review.name}</div>
            <div className="table-cell">{review.rating.toFixed(1)}</div>
            <div className="table-cell">{review.review ? review.review.substring(0, 5) + '...' : "Pas d'avis"}</div>
            <div className="table-cell">{review.hour}</div>
            <div className="table-cell">{formatDate(review.date)}</div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{}}
      >
        <Fade in={open}>
          <Box sx={style}>
          <div style={{ fontWeight: 'bold', color: 'black', textAlign: 'center', fontSize:50,}}>{rating.toFixed(1)}     <StarIcon sx={{ color: '#1E90FF', fontSize:50 }} />
</div>
          {questions.map((question, index) => (
            <div key={index} style={{ marginTop: '10px' }}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', color:"black" }}>
                {(index != 2&&index != 7) ?question:null}
                {index == 7 ?"Avis":null}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 'normal', color:"black" }}>
              {(index != 2&&index != 7) ?answers[index]?.label || 'Pas de réponse':null}
              {index == 7 ?review:null}
              </Typography>
            </div>
          ))}
          </Box>
        </Fade>
      </Modal>
        </div>
      ):(
    <div className="custom-table" style={{width:isScreenSmall()?'100%':null}}>
      {/* En-tête */}
      <div className="table-row header colorHeader">
        <div style={{ marginRight: '5%' }}>Commande</div>
        <div className="table-cell" style={{ marginRight: '2%' }}>Client</div>
        <div className="table-cell">Note</div>
        <div className="table-cell">Avis</div>
        <div className="table-cell">Heure</div>
        <div className="table-cell" style={{ marginRight: '-2%' }}>Date</div>
      </div>

      {/* Données scrollable */}
      <div className="data-container">
        {data?.map((review, index) => (
          <div
            key={index}
            className={`table-row data ${selectedRow === index ? 'selected' : ''}`}
            onClick={() => handleRowClick(index, review?.answers, review?.review, review?.rating)}
          >
            <div className="table-cell">{review.order.substring(0, 20) + '...'}</div>
            <div className="table-cell">{review.name}</div>
            <div className="table-cell">{review.rating.toFixed(1)}</div>
            <div className="table-cell">{review.review ? review.review.substring(0, 5) + '...' : "Pas d'avis"}</div>
            <div className="table-cell">{review.hour}</div>
            <div className="table-cell">{formatDate(review.date)}</div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        sx={{}}
      >
        <Fade in={open}>
          <Box sx={style}>
          <div style={{ fontWeight: 'bold', color: 'black', textAlign: 'center', fontSize:50,}}>{rating.toFixed(1)}     <StarIcon sx={{ color: '#1E90FF', fontSize:50 }} />
</div>
          {questions.map((question, index) => (
            <div key={index} style={{ marginTop: '10px' }}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold', color:"black" }}>
                {(index != 2&&index != 7) ?question:null}
                {index == 7 ?"Avis":null}
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 'normal', color:"black" }}>
              {(index != 2&&index != 7) ?answers[index]?.label || 'Pas de réponse':null}
              {index == 7 ?review:null}
              </Typography>
            </div>
          ))}
          </Box>
        </Fade>
      </Modal>
    </div>
    )
    }</>
  );
};

export default Reviews;
