import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StarRating from '../StarRating/StarRating';
import { currencies } from '../../actions/currencies';

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)} sx={{ '& > *': { borderBottom: 'unset',}}}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{row.contact}</TableCell>
        <TableCell align="right">{row.visits}</TableCell>
        <TableCell align="right">{row.favoritesPlat}</TableCell>
        <TableCell align="right">{row?.totalSpent || 0} {currencies().symbol}</TableCell>
        <TableCell align="right">
          <StarRating rating={row.restaurantRating} />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
          
          >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Commandes
              </Typography>
              <Table size="small" aria-label="reviews">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Montant Commande</TableCell>
                    <TableCell>Prix Payé</TableCell>
                    <TableCell>Note</TableCell>
                    <TableCell>Commentaire</TableCell>
                    <TableCell>Commandes</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {Array.isArray(row.reviews) && row.reviews.map((review) => (
                    <TableRow onClick={() => setOpen(!open)} key={review.date}>
                      <TableCell>{review.date || 'N/A'}</TableCell>
                      <TableCell>{review.orderAmount} {currencies().symbol}</TableCell>
                      <TableCell>{review.pricePaid} {currencies().symbol}</TableCell>
                      <TableCell>
                        <StarRating rating={review.rating} />
                      </TableCell>
                      <TableCell>{review.comment}</TableCell>
                      <TableCell>{review.order}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    name: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    visits: PropTypes.number.isRequired,
    favoritesPlat : PropTypes.string.isRequired,
    totalSpent: PropTypes.string.isRequired,
    restaurantRating: PropTypes.number.isRequired,
    reviews: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string.isRequired,
        orderAmount: PropTypes.string.isRequired,
        pricePaid: PropTypes.string.isRequired,
        rating: PropTypes.number.isRequired,
        comment: PropTypes.string.isRequired,
        order:PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};



export default function CollapsibleTable({socket, rows}) {
  

  return (
    <TableContainer  className='' component={Paper} sx={{
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        minHeight:'500px',
        maxHeight: '500px',
        display:'flex',
        //marginTop:'15%',
        overflow: 'auto',
        '@media (max-width: 1200px)': {
          maxHeight: 'calc(100vh - 150px)', // Adjust height for smaller screens
          width: 'calc(100vw - 40px)', // Adjust width for smaller screens
          margin: '0 auto', // Center the table horizontally
        },
        '@media (max-width: 768px)': {
          maxHeight: 'calc(100vh - 100px)', // Further adjust height for even smaller screens
          width: 'calc(100vw - 20px)', // Further adjust width for even smaller screens
          margin: '0 auto', // Center the table horizontally
          marginTop:'30%',
        },
      }}>
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead >
          <TableRow >
            <TableCell />
            <TableCell>Nom</TableCell>
            <TableCell>Contact</TableCell>
            <TableCell align="right">Visites</TableCell>
            <TableCell align="right">Plat favoris</TableCell>
            <TableCell align="right">Total Dépensé</TableCell>
            <TableCell align="right">Note Restaurant</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((row) => (
            <Row key={row?.id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
