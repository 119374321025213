import React from "react";
import { Card, CardContent, Typography } from "@mui/material";
import StarIcon from '@mui/icons-material/Star';
import Table from "./Table";

const Cards = ({ numberOfReviews=80, rating=3 }) => {
    const stars = [];
  for (let i = 0; i < Math.floor(rating); i++) {
    stars.push(<StarIcon key={i} sx={{color:'#17174F'}}/>);
  }
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
    <>
      {isScreenSmall()?(
        <div style={{ display: "flex",flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
        <Card style={{marginBottom:'5%', width: "90%", height:'20%',background: "rgb(43, 48, 123, 0.1)",
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Ajuster les valeurs pour obtenir l'effet d'ombre souhaité
         }}>
        <CardContent>
          <Typography variant="h3" component="h2" align="center" sx={{color:'#17174F'}}>
            {numberOfReviews}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center" sx={{color:'#17174F'}}>
            Avis
          </Typography>
        </CardContent>
      </Card>
      <Card style={{ width: "90%", height:'20%',background: "rgb(43, 48, 123, 0.1)",
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', }}>
        <CardContent>
          <Typography variant="h3" component="h2" align="center" sx={{color:'#17174F'}}>
            {rating.toFixed(1)}<span style={{fontSize:16}} >/5</span> 
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {stars}
          </div>
        </CardContent>
      </Card>
    </div>
      ):(
        <div style={{ display: "flex",}}>
        <Card style={{ marginRight: "10px", width: "20%", height:'20%',background: "rgb(43, 48, 123, 0.1)",
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Ajuster les valeurs pour obtenir l'effet d'ombre souhaité
         }}>
        <CardContent>
          <Typography variant="h3" component="h2" align="center" sx={{color:'#17174F'}}>
            {numberOfReviews}
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center" sx={{color:'#17174F'}}>
            Avis
          </Typography>
        </CardContent>
      </Card>
      <Card style={{ width: "20%", height:'20%',background: "rgb(43, 48, 123, 0.1)",
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', }}>
        <CardContent>
          <Typography variant="h3" component="h2" align="center" sx={{color:'#17174F'}}>
            {rating.toFixed(1)}<span style={{fontSize:16}} >/5</span> 
          </Typography>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {stars}
          </div>
        </CardContent>
      </Card>
    </div>)}
    </>
    
  );
};

export default Cards;
