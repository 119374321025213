import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Filter from './Filter';
import StarRating from '../StarRating/StarRating';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Paper from '@mui/material/Paper';
import { currencies } from '../../actions/currencies';
import {CircularProgress, Backdrop} from '@mui/material';

import "./Orders.css";
import {
  UilRefresh
} from "@iconscout/react-unicons";
import client from '../../api/client';
import { removeItems } from '../../actions/deleteItems';
import { useNavigate } from 'react-router-dom';

// Your createData function


const columns = [
  { field: 'order', headerName: 'Commande', width: 130,headerClassName: 'header-bold', },
  { field: 'contact', headerName: 'Contact', width: 130 },
  { field: 'status', headerName: 'Status', width: 100 },
  { field: 'price', headerName: 'Prix', width: 80 },
  { field: 'type', headerName: 'Paiement', width: 100 },
  { field: 'time', headerName: 'Durée service', width: 130 },
  { field: 'date', headerName: 'Date', width: 130 },
  { field: 'hour', headerName: 'Heure', width: 80 },
  { field: 'avis', headerName: 'Avis', width: 130 },
  {
    field: 'rating',
    headerName: 'Etoiles',
    width: 140,
    renderCell: (params) => (
      <span className="status-container">
        <StarRating rating={params.value} />
      </span>
    ),
  },
];

// Your data creation logic


const RowDetailsModal = ({ open, handleClose, selectedRow }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{selectedRow?.row.order}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Contact: {selectedRow?.row.contact}
          <br />
          Status: {selectedRow?.row.status}
          <br />
          Prix: {selectedRow?.row.price} {currencies()?.code}
          <br />
          Paiement: {selectedRow?.row.type}
          <br />
          Durée de service: {selectedRow?.row.time}
          <br />
           {selectedRow?.row.date} à {selectedRow?.row.hour}
          <br />
          {selectedRow?.row.avis}
          {/* Add more details as needed */}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default function Order({socket}) {
  const orders = JSON.parse(localStorage.getItem("orders")) || [{ id: '', order: '', contact: '', status: '', price: '', type: '', hour: '', date: '', avis: '', rating: 0 }];
  const [rows, setRows] = React.useState(orders);
  const [filteredRows, setFilteredRows] = React.useState(rows);
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate()

  
  React.useEffect(() => {
    const refresh = async()=>{
      try {
        const token = JSON.parse((localStorage.getItem("token")))
        await client.post('/get-orders',{token},{
          headers: {
              Accept: 'application/json',
              //'content-Type': 'multipart/form-data',
              authorization : `JWT ${token}`,
            }
          }).then((res)=>{
            console.log("refresh.......", res.data)
            if(res.data.success){
              setRows(res.data.orders)
              localStorage.setItem("orders", JSON.stringify(res.data.orders))
            }else{
              removeItems()
              navigate("/")
            }
          })        
      } catch (error) {
        
      }finally {
        setLoading(false); // Cacher la barre de chargement
      }

    }
    refresh()
  }, []);
  React.useEffect(() => {
    const handleRowOrder = (data) => {
      console.log("order.contact ", data)
      // Assuming data contains order details, including the id
      const updatedOrders = rows.map(order => {
        console.log("order.contact ", order.contact)
        if (order.id === data.order.id) {
          // If the order id matches, update the order
          return {
            id: data.order.id,
            order: data.order.order,
            contact: data.order.contact,
            status: data.order.status,
            price: data.order.price,
            type: data.order.type,
            hour: data.order.hour,
            date: data.order.date,
            avis: data.order.avis,
            rating: data.order.rating,
          };
        }
        return order; // Return the original order if id doesn't match
      });

      setRows(updatedOrders);
      setFilteredRows(updatedOrders);
      localStorage.setItem("orders", JSON.stringify(updatedOrders));
    };
    socket.on('newOrderHistory', handleRowOrder);
  }, [rows, setRows, socket]);
  
  const handleFiltersChange = (date, decimalValue) => {
    // Filter rows based on date and decimalValue
    const filteredData = rows.filter((row) => {
      const isDateMatch = !date || date === row.date;
      const isDecimalMatch = decimalValue === 0 || parseFloat(row.rating) === decimalValue;

      return isDateMatch && isDecimalMatch;
    });
    console.log("filtre date, decimalValue ........", date, decimalValue)
    setFilteredRows(filteredData);
  };

  const handleRowClick = (params) => {
    setSelectedRow(params);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  React.useEffect(()=>{
    console.log("refresh.......",rows)
  }, [rows])
  return (
    <div className="TableOfOrder TableOrder">
      <h1>Commandes</h1>
      {/* Pass the filter function to YourFiltersComponent */}
      <Filter onFiltersChange={handleFiltersChange} />

      <Paper 
      elevation={3} 
      className="table-container" 
      style={{
        maxHeight: '60vh', 
        width: '87%', 
        overflow: 'hidden' ,
      }}
    >
      <DataGrid
        rows={filteredRows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10]}
        isRowSelectable={(params) => !!params.id}
        onRowClick={handleRowClick}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{
          maxHeight: '100%',
          width: '100%',
          
          '& .MuiDataGrid-root': {
            height: '100%',
            width: '100%',
          },
          '@media (max-width: 1350px)': {
            '& .MuiDataGrid-root': {
              height: 'calc(100vh - 150px)', // adjust height for smaller screens
              width: 'calc(100vw - 400px)',  // adjust width for smaller screens
            },
          },
          '@media (max-width: 768px)': {
            '& .MuiDataGrid-root': {
              height: 'calc(100vh - 100px)', // further adjust height for even smaller screens
              width: 'calc(100vw - 20px)',  // further adjust width for even smaller screens
            },
          },
          '& .MuiDataGrid-virtualScroller': {
            overflowX: 'auto',
            overflowY: 'auto',
  scrollbarWidth: 'none', /* Pour Firefox */
  msOverflowStyle: 'none', /* Pour Internet Explorer et Edge */
  '&::-webkit-scrollbar': { display: 'none' }, /* Pour Chrome, Safari et Opera */
          },

        }}
      />
    </Paper>

      {/* Modal */}
      <RowDetailsModal open={openModal} handleClose={handleCloseModal} selectedRow={selectedRow} />
      <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}
