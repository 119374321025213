import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const SearchBar = ({ handleSearch }) => {
  const [searchText, setSearchText] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const handleSearchIconClick = () => {
    setIsSearchOpen(true); // Afficher la barre de recherche lors du clic sur l'icône de recherche
  };

  const handleCloseIconClick = () => {
    setIsSearchOpen(false); // Cacher la barre de recherche lors du clic sur l'icône de fermeture
    setSearchText(''); // Effacer le texte de recherche
    handleSearch(0); // Convertir le texte en nombre entier et effectuer la recherche

  };

  // Déclencher la recherche chaque fois que le texte de recherche change
  useEffect(() => {
    // Effectuer une recherche lorsque la barre de recherche est ouverte et que le texte change
    if (isSearchOpen) {
      handleSearch(parseInt(searchText.trim())); // Convertir le texte en nombre entier et effectuer la recherche
    }
  }, [searchText]);

  // Style conditionnel pour le conteneur principal
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: isSearchOpen ? '#fff' : 'transparent',
    borderRadius: 5,
    padding: '10px',
    margin: '0 10px 10px',
    width: '70%',
    // Condition pour la bordure, l'élévation et la box-shadow
    border: isSearchOpen ? 'none' : '1px solid transparent',
    boxShadow: isSearchOpen ? '0 2px 4px rgba(0, 0, 0, 0.3)' : 'none',
  };

  const inputStyle = {
    flex: 1,
    width: '70%',
    padding: '10px',
    border: 'none',
    outline: 'none',
    background: 'transparent', // Rendre le fond de l'input transparent
  };

  const iconContainerStyle = {
    padding: '10px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      {isSearchOpen ? (
        <>
          <input
            style={inputStyle}
            type="text"
            placeholder="Numéro de table..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div style={iconContainerStyle} onClick={handleCloseIconClick}>
            <CloseIcon />
          </div>
        </>
      ) : (
        <div style={iconContainerStyle} onClick={handleSearchIconClick}>
          <SearchIcon />
        </div>
      )}
    </div>
  );
};

export default SearchBar;
