import React from 'react';
import './NavBar.css';
import {IconButton } from '@mui/material';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Tooltip from '@mui/material/Tooltip';

const NavBar = ({ types, onSelectSection, selectedSection, onContextMenu, handleOpenModal }) => {
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
    <div className='NavBar' style={{ position: 'fixed', top:isScreenSmall()?'15%':'20%',}}
    >
      {types.map((item, index) => (
        <div
          key={index}
          style={{
            marginRight: '20px',
            fontWeight: 500,
            color: selectedSection === item ? '#3F48A8' : '#242d49',
            cursor: 'pointer',
            padding: '8px',
            display: 'inline-block',
            borderBottom: selectedSection === item ? '2px solid #B340FF' : 'none',
          }}
          onClick={() => onSelectSection(item)}
          onContextMenu={(e) => onContextMenu(e, item)}
        >
          {item}
        </div>
      ))}
      <Tooltip title="Prévisualiser">
        <IconButton onClick={handleOpenModal}>
          <PhoneIphoneIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default NavBar;
