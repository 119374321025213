import React, { useState } from 'react';
import { Modal, Button, TextField, Box, Typography } from '@mui/material';

const DeleteConfirmationModal = ({ orderId, onDeleteConfirmed, setModalFalse }) => {
  const [reason, setReason] = useState('');

  const handleDeleteConfirmed = async () => {
    // Appeler la fonction onDeleteConfirmed avec orderId et reason
    onDeleteConfirmed(orderId, reason);
    // Fermer le modal
    setReason('');
    setModalFalse()
  };
  const handleClose = async()=>{
    setReason('');
    setModalFalse()
  }

  return (
    <Modal sx={{display:'flex', justifyContent:'center', alignItems:'center'}} open={true} onClose={() => {}}>
      <Box sx={{ width: 400, bgcolor: 'background.paper', p: 2, borderRadius: 2 }}>
        <Typography sx={{color:'black'}} variant="h6" gutterBottom>
        Voulez-vous vraiment supprimer cette commande ? 
        </Typography>
        <Typography sx={{color:'black'}} variant="body1" gutterBottom>
          Veuillez indiquer la raison :
        </Typography>
        <TextField
          fullWidth
          placeholder="Raison de la suppression"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          sx={{ mb: 2 }}
        />
        <div style={{display:'flex', justifyContent:'space-between'}} >
          <Button onClick={handleDeleteConfirmed} sx={{backgroundColor:'red', color:'white'}}>
          Oui, Supprimer
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Annuler
        </Button>
        </div>
        
      </Box>
    </Modal>
  );
};

export default DeleteConfirmationModal;
