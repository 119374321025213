// src/ContactUs.js
import React, { useEffect,useState } from 'react';
import {CircularProgress, Backdrop } from '@mui/material';

const ContactUs = () => {
  const [loading, setLoading] = useState(false);

  useEffect(()=>{
    setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },5000)
  },[])
  return (
    <div 
    style={{
        background: 'white',
        height: '80vh',
        overflowY: 'auto',
  scrollbarWidth: 'none', /* Pour Firefox */
  msOverflowStyle: 'none', /* Pour Internet Explorer et Edge */
  '&::-webkit-scrollbar': { display: 'none' }, /* Pour Chrome, Safari et Opera */ // Ajout pour activer le défilement vertical lorsque le contenu dépasse la hauteur spécifiée
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Effet d'élévation
        borderRadius: '20px', // Bordures arrondies
        padding: '20px' // Ajout pour un espacement interne
      }}    >
      <iframe 
        className="airtable-embed" 
        src="https://airtable.com/embed/app7VFSl3VQ7Apl5I/pagEfQmQT0Mb0tKGp/form" 
        frameBorder="0" 
        onWheel="" 
        width="100%" 
        height="533" 
        style={{ background: 'transparent', border: '1px solid #ccc' }}
      ></iframe>
      <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default ContactUs;
