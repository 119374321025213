import React, { useState, useEffect } from 'react';
import { Button, Typography, FormControl, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import client from '../../../api/client';

const CardCommandPayment = ({ order, onDeleteItemPayment, restaurantId, onPayment, currencies }) => {
  const navigate = useNavigate();
  const [elapsedMinutes, setElapsedMinutes] = useState(0);
  const [paymentTypes, setPaymentTypes] = useState({
    'Espèces': false,
    'Carte bancaire': false,
    'Ticket restaurant': false
  });
  const [paymentAmounts, setPaymentAmounts] = useState({
    'Espèces': order.price ||'',
    'Carte bancaire': order.price||'',
    'Ticket restaurant': order.price||''
  });
  const [error, setError] = useState(null);

  const handlePaymentTypeChange = (event) => {
    const { name, checked } = event.target;
    setPaymentTypes((prev) => ({ ...prev, [name]: checked }));
  };

  const handlePaymentAmountChange = (event) => {
    const { name, value } = event.target;
    setPaymentAmounts((prev) => ({ ...prev, [name]: value }));
  };

  const handlePay = async () => {

    const selectedPaymentTypes = Object.keys(paymentTypes).filter((type) => paymentTypes[type]);
    const selectedPaymentAmounts = Object.entries(paymentAmounts)
      .filter(([type, amount]) => paymentTypes[type] && amount)
      .map(([type, amount]) => ({ type, amount: parseFloat(amount) }));

    const totalPayment = selectedPaymentAmounts.reduce((sum, { amount }) => sum + amount, 0);

     if (selectedPaymentAmounts.length>1&&totalPayment !== order.price) {
      setError("Le montant total des paiements doit correspondre au prix total de la commande.");
      setTimeout(() => setError(''), 3000);
      return;
    }

    if (selectedPaymentTypes.length > 0) {
      let typePayment = '';

      if (selectedPaymentAmounts.length > 1) {
        typePayment = selectedPaymentAmounts
          .map(({ type, amount }) => `${amount} ${currencies.symbol} ${type}`)
          .join(' / ');
      } else {
        const { type, amount } = selectedPaymentAmounts[0];
        //typePayment = `${amount} ${currencies.symbol} ${type}`;
        typePayment = type
      }
      const token = JSON.parse((localStorage.getItem("tokenRestorer")))
      if(token){
        await client.post('/validatePayment', {
                orderId: order.orderId,
                newStatus: order.status,
                typePayment
              }, {
                headers: {
                  Accept: 'application/json',
                  authorization: `JWT ${token}`,
                }
              }).then((res) => {
                console.log('res.data....', res.data)

                if (res?.data?.success) {
                  onDeleteItemPayment(order.orderId, selectedPaymentTypes);
                  setPaymentTypes({
                    'Espèces': false,
                    'Carte bancaire': false,
                    'Ticket restaurant': false
                  })
                }else {
                  navigate('/comptoir/connexion');
                }
              }).catch((error) => {
                console.error('Error during payment:', error);
              });        
      }else {
        navigate('/comptoir/connexion');
      }
      
    } else {
      setError("Choisissez un type de paiement !");
      setTimeout(() => setError(''), 3000);
    }
  };

  return (
    <div
      key={order.orderId}
      style={{
        minWidth: '200px',
        maxWidth: '300px',
        marginRight: '20px',
        marginBottom: '20px',
        flexDirection: 'column',
        display: 'flex',
        borderRadius: '10px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
        overflow: 'hidden',
        height: '100%',
        padding: '15px',
        backgroundColor:order.status !== 'En attente' ? 'rgba(0, 128, 0, 0.5)' : 'white'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', marginBottom: '10px', textDecoration: 'underline' }}>
        <Typography variant="h9">{order.nom_client} - {`Tab. ${order.numberOfTable}`}</Typography>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', marginBottom: '10px' }}>
        <Typography variant="body2">{order.onPlace ? 'Sur Place' : 'Emporter'}</Typography>
      </div>
      <div style={{ marginBottom: '10px' }}>
        {order.order.map((item, itemIndex) => (
          <div key={itemIndex} style={{ display: 'flex', flexDirection: 'column', marginBottom: '3%' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography variant="body2" fontWeight="bold">
                {item.name}
              </Typography>
              <div style={{ flex: 1, borderBottom: '1px dotted black', margin: '0 10px' }}></div>
              <Typography variant="body2" fontWeight="bold">
                {`x${item.quantity}`}
              </Typography>
            </div>
            {item.unique_selections && item.unique_selections.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                {item.unique_selections.map((sel) => sel.name).join(', ')}
              </Typography>
            )}
            {item.multiple_selections && item.multiple_selections.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                {item.multiple_selections.map((sel) => sel.name).join(', ')}
              </Typography>
            )}
            {item.supplements && item.supplements.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '5px', fontWeight: 400 }}>
                {item.supplements.map((sup) => `${sup.quantity}x ${sup.name}`).join(', ')}
              </Typography>
            )}
            {item.note && item.note.length > 0 && (
              <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                Note : {item.note}
              </Typography>
            )}
          </div>
        ))}
      </div>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
        <Typography variant="body2" fontWeight="bold">Prix Total : {`${order.price} ${currencies.symbol}`}</Typography>
      </div>
      <FormControl component="fieldset" style={{ marginTop: '20px' }}>
        <Typography variant="body2" sx={{ color: 'red' }}>{error}</Typography>
        <Typography sx={{ fontSize: '13px' }} gutterBottom>Choisissez le mode de paiement :</Typography>
        {['Espèces', 'Carte bancaire', 'Ticket restaurant'].map((type) => (
          <div key={type} style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <FormControlLabel
              control={<Checkbox checked={paymentTypes[type]} onChange={handlePaymentTypeChange} name={type} />}
              label={<Typography sx={{ fontSize: '13px' }}>{type}</Typography>}
            />
            {paymentTypes[type] && (
              <TextField
                name={type}
                label="Montant"
                value={paymentAmounts[type]}
                onChange={handlePaymentAmountChange}
                size="small"
                type="number"
                style={{ marginLeft: '10px' }}
              />
            )}
          </div>
        ))}
      </FormControl>
      <div style={{ marginTop: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button variant="contained" color="primary" onClick={handlePay}>
          Payer
        </Button>
      </div>
    </div>
  );
};

export default CardCommandPayment;
