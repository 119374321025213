import * as React from 'react';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';

export default function CardStatic({ name, value}) {
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
    <Card invertedColors sx={{
        width:isScreenSmall()?'77%':null,
        background: "rgb(43, 48, 123, 0.1)",
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Ajuster les valeurs pour obtenir l'effet d'ombre souhaité
      }}>
      <CardContent orientation="horizontal">
        <CardContent>
          <Typography level="body-md"  sx={{color:'#17174F'}} >{name}</Typography>
          <Typography level="h2" sx={{color:'#17174F'}}>{value}</Typography>
        </CardContent>
      </CardContent>
    </Card>
  );
}
