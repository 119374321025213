import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Avatar
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CategoryDialog from './CategoryDialog';
import ProductForm from './ProductForm';
import { connect } from 'react-redux';

import { addProduct, deleteProduct, editProduct, editCategory, deleteCategory, addCategory } from '../../actions/productActions';
import { currencies } from '../../actions/currencies';

const useStyles = makeStyles({
  clickableRow: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#f5f5f5', // Change the background color on hover
    },
  },
});


const DynamicTable = ({ data=[],id_type='', type = '', addProduct, deleteProduct, editProduct,editCategory, deleteCategory, addCategory  }) => {
  const [CategoryEdit, setCategoryEdit] = useState(null);
  const [productEdit, setProductEdit] = useState(null);
  const [nom, setNom] = useState(null)
  const [id, setId] = useState(null)

  const classes = useStyles();

  const handleCategoryEdit = (category) => {
    setCategoryEdit(category);
  };

  const handleProductEdit = (product, nom,id) => {
    setProductEdit(product);
    setNom(nom)
    setId(id)
  };

  const handleCloseDialog = () => {
    setCategoryEdit(null);
    setProductEdit(null);
  };
  const handleAddCategory = () => {
    // Handle the click event for adding a category
    setCategoryEdit({});
  };
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  return (
    <>
      {data?.length > 0 || id_type?.length > 0 ? (
        <>
          <TableContainer sx={{ height: '60%', minHeight:'60%', maxHeight:'60%', top:'30%', position:'fixed', width:"70%",
          '@media (max-width: 768px)': {
          maxHeight: 'calc(100vh - 100px)', // Further adjust height for even smaller screens
          width: 'calc(100vw - 20px)', // Further adjust width for even smaller screens
          margin: '0 auto', // Center the table horizontally
        },
          }}
 component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{color:'#17174F'}}>Catégorie</TableCell>
                  <TableCell style={{color:'#17174F'}}>Image</TableCell>
                  <TableCell style={{color:'#17174F'}}>Nom</TableCell>
                  <TableCell style={{color:'#17174F'}}>Prix</TableCell>
                  <TableCell style={{color:'#17174F'}}>Description</TableCell>
                  <TableCell style={{color:'#17174F'}}>Rating</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {data?.map((category, index) => (
  <React.Fragment key={index}>
    <TableRow className={classes.clickableRow} onClick={() => handleCategoryEdit(category)}>
      <TableCell>{category.nom}</TableCell>
    </TableRow>
    {category.dataProduct.map((product) => (
      <TableRow
        key={product?.id}
        className={classes.clickableRow}
        onClick={() => handleProductEdit(product, category.nom, category.id)}
      >
        <TableCell></TableCell>
        <TableCell>
          {/* Check if the product.image is available */}
          {product?.image && typeof product.image !== 'string' ? (
            // If it's a File or Blob, convert it to a data URL
            <Avatar src={product.image instanceof Blob ? URL.createObjectURL(product.image) : product.image} style={{ width: 100, height: 100 }} />
          ) : (
            // If it's already a URL or base64 string, use it directly
            <Avatar src={product?.image} style={{ width: 100, height: 100 }} />
          )}
        </TableCell>
        <TableCell>{product?.name}</TableCell>
        <TableCell>{product?.price} {currencies().symbol}</TableCell>
        <TableCell>{product?.description}</TableCell>
        <TableCell>{product?.rating}</TableCell>
      </TableRow>
    ))}
  </React.Fragment>
))}

              </TableBody>
            </Table>
          </TableContainer>

          {CategoryEdit && <CategoryDialog category={CategoryEdit} onClose={handleCloseDialog} type={type} id_type={id_type} />}
          {productEdit && (
            <Dialog open={Boolean(productEdit)} onClose={handleCloseDialog}>
              <DialogTitle>Modifier le Produit</DialogTitle>
              <DialogContent>
                {/* Utilisez ProductForm avec les propriétés nécessaires */}
                <ProductForm id_subcategory={id} id_type={id_type} data={productEdit} type={type} nom={nom} onClose={handleCloseDialog}/>
                {/* Ajoutez d'autres champs de formulaire selon vos besoins */}
              </DialogContent>
            </Dialog>
          )}
            <Button
      variant="contained"
      sx={{
        backgroundColor: '#242d49',
        width: '70%',  // Adjust the width as needed
        borderRadius: '50px',  // Add border radius
        top:'93%',
        left:isScreenSmall()?'2%':'20%'
      }}
      onClick={handleAddCategory}
    >
      Ajouter une catégorie
    </Button>
          
        </>
      ) : (
        <h3 style={{ position: 'fixed', top: '50%', left:'30%' }}>Vous n'avez aucune section</h3>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  // Vous pouvez mapper l'état Redux à des props si nécessaire
});

export default connect(mapStateToProps, { addProduct, deleteProduct, editProduct,editCategory, deleteCategory, addCategory })(DynamicTable);
