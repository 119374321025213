import React, { useState, useEffect, useRef } from 'react';
import NavBar from './NavBar';
import { connect } from 'react-redux';
import { addProduct, deleteProduct, editProduct, editCategory, deleteCategory, addCategory } from '../../actions/productActions';
import MainReviews from './MailReviews/MainReviews';
import MailRecall from './MailRecall/MailRecall';
import MailPromo from './MailPromo/MailPromo';
const Main = () => {
  const types = ['Avis client', 'Promotion produit', 'Rappel & Promo'];
  const [selectedSection, setSelectedSection] = useState(types[0]);


  const handleSelectSection = (section) => {
    setSelectedSection(section);
  };

  return (
    <div className='MainDash'>
      <h1 style={{top: '10%' }}>E-mail Marketing</h1>      
      <NavBar
        onSelectSection={handleSelectSection}
        selectedSection={selectedSection}
      />
      <section style={{top:'20%'}}>
        {
          selectedSection=="Rappel & Promo"?<MailRecall />:
          (
            selectedSection=="Promotion produit"?<MailPromo />:<MainReviews />)
        }
        
      </section>
      {/*<DynamicTable
      style={{ position: 'fixed', top: '30%' }}
        data={selectedSection ? data.find((item) => item?.type === selectedSection)?.data : []}
        type={selectedSection ? selectedSection : data[0]?.type}
        addProduct={addProduct}
        deleteProduct={deleteProduct}
        editProduct={editProduct}
        editCategory={editCategory}
        addCategory={addCategory}
        deleteCategory={deleteCategory}
        id_type = {selectedSection ? data.find(item => item.type === selectedSection)?.id : data[0]?.id}
      />*/}
    </div>
  );
};


export default Main;