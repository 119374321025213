import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { imagesType } from '../../Data/Data';

const CardImage = ({ open, onClose, handleSelectImage }) => {
  const [searchTerm, setSearchTerm] = useState('');

  // Filtrer les images en fonction du terme de recherche
  const filteredImages = Object.entries(imagesType).filter(([key, value]) =>
    searchTerm === '' || value.searctTerms.some(term => term.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  // Gérer la sélection d'une image
  const handleImageSelect = (url) => {
    handleSelectImage(url);
    onClose(); // Fermer la boîte de dialogue après la sélection de l'image
  };

  return (
<Dialog open={open} onClose={onClose}>
  <Card>
    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
      <TextField
        label="Rechercher une image"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: '10px' }}
      />
      <List sx={{ width: '100%', maxHeight: '50vh', overflowY: 'auto' }}>
        {filteredImages.map(([key, value]) => (
          <ListItem
            button
            key={key}
            onClick={() => handleImageSelect(value.url)}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <img src={value.url} alt={key} style={{ marginRight: '10px', width: '32px', height: '32px' }} />
            <ListItemText primary={key} />
          </ListItem>
        ))}
      </List>
    </CardContent>
  </Card>
</Dialog>

  );
};

export default CardImage;
