import React, { useState, useEffect } from 'react';
import { Modal, Typography, Button, TextField, IconButton, Card, CardContent, CardMedia, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useNavigate } from 'react-router-dom';
import ProductCard from './ProductCard';
import client from '../../../api/client';

const EditOrderModal = ({ open, onClose, order, onSave, currencies }) => {
  const [items, setItems] = useState(order.order);
  const [totalPrice, setTotalPrice] = useState(order.price);
  const [listProduct, setListProduct] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null);
  const restaurantIdJson = localStorage.getItem("restaurantId");
  const [restaurantId, setRestaurantId] = useState(restaurantIdJson ? JSON.parse(restaurantIdJson) : null);
  const navigate = useNavigate();

  useEffect(() => {
    setItems(order.order);
    setTotalPrice(order.price);
  }, [order]);

  useEffect(() => {
    if (searchText.trim()) {
      fetchProducts(searchText);
    } else {
      setListProduct([]);
    }
  }, [searchText]);

  const fetchProducts = async (query) => {
    try {
      const token = JSON.parse(localStorage.getItem("tokenRestorer"));
      if (token && restaurantId) {
        await client.post('/getProduct', { restaurantId, productName: query }, {
          headers: {
            Accept: 'application/json',
            Authorization: `JWT ${token}`,
          }
        }).then((res) => {
          if (res.data.success) {
            setListProduct(res.data.products);
          } else {
            navigate('/comptoir/connexion');
          }
        });
      } else {
        navigate('/comptoir/connexion');
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des produits :', error);
    }
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedItems = [...items];
    updatedItems[index].quantity = newQuantity;
    setItems(updatedItems);
    updateTotalPrice(updatedItems);
  };

  const updateTotalPrice = (updatedItems) => {
    console.log("updatedItems....", updatedItems)
    const newTotal = updatedItems.reduce((acc, item) => acc + parseFloat(item.price)*item.quantity, 0);
    setTotalPrice(newTotal);
  };

  const handleAddProduct = (product) => {
    if (product.uniqueSelected.length === 0 && product.multipleSelected.length === 0 && product.supplement.length === 0) {
      const existingProductIndex = items.findIndex(item => item.id_product === product.id);
      if (existingProductIndex >= 0) {
        handleQuantityChange(existingProductIndex, items[existingProductIndex].quantity + 1);
      } /*else {
        const updatedItems = [...items, { ...product, quantity: 1 }];
        setItems(updatedItems);
        updateTotalPrice(updatedItems);
      }*/
    } /*else {
      setSelectedProduct(product);
    }*/
    setSelectedProduct(product);
  };

  const handleCloseProductModal = () => {
    setSelectedProduct(null);
  };
  const handleSave = async () => {
    const updatedOrder = {
      ...order,
      items,
      price: totalPrice
    };
    try {
        const token = JSON.parse((localStorage.getItem("tokenRestorer")))
        console.log("items before send....", items)
        if(token){
            await client.post('/editProduct',{restaurantId,orderId:order.orderId,price:totalPrice, cartItems:items},{
                headers: {
                  Accept: 'application/json',
                  //'content-Type': 'multipart/form-data',
                  Authorization : `JWT ${token}`,
                }
              }).then((res)=>{
                console.log("res.data...", res.data)
                if(!res?.data?.success){
                  //navigate('/comptoir/connexion');
                }
                if(res?.data?.success){
                    onSave(updatedOrder);
                }
              })    
        }
    } catch (error) {
        
    }
    onClose();
  };
  const handleSaveProduct = async (cartItem) => {
        const updatedItems = [...items, cartItem];
        setItems(updatedItems);
        updateTotalPrice(updatedItems);
    // Add logic to save the selected product with options and supplements
    handleCloseProductModal();
  };

  const handleRemoveProduct = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    updateTotalPrice(updatedItems);
  };

  

  return (
    <Modal open={open} onClose={onClose}>
      <div style={{ padding: '20px', color: 'black', backgroundColor: 'white', margin: '10% auto', width: '80%', borderRadius: '8px', maxHeight: '70%', overflowY: 'auto', /* Pour Chrome, Safari et Opera */ marginBottom:'10%',
       /* Pour Chrome, Safari et Opera */
       }}>
        <Typography variant="h6">Modifier la commande</Typography>
        <TextField
          label="Rechercher un produit"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          fullWidth
          margin="normal"
        />
        {listProduct.length > 0 && (
          <div style={{ margin: '10px 0', color: 'black' }}>
            {listProduct.map((product) => (
              <Card key={product.id} style={{ display: 'flex', alignItems: 'center', margin: '10px 0', padding: '10px', cursor: 'pointer' }} onClick={() => handleAddProduct(product)}>
                <CardMedia
                  component="img"
                  image={product.image}
                  alt={product.name}
                  style={{ width: 100, height: 100, objectFit: 'cover', marginRight: '10px' }}
                />
                <CardContent style={{ flex: 1 }}>
                  <Typography variant="body1" component="div" fontWeight="bold">
                    {product.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product.allergenes}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {product.price}{currencies.symbol}
                  </Typography>
                </CardContent>
                <IconButton >
                  <AddCircleIcon />
                </IconButton>
              </Card>
            ))}
          </div>
        )}
        <div>
          {items.map((item, index) => (
            <div key={item.id_product} style={{ color: 'black', display: 'flex', alignItems: 'center',justifyContent:'space-between', margin: '10px 0' }}>
                <div key={index} style={{display:'flex', flexDirection:'column', marginBottom:'3%'}}>
                <div style={{display:'flex', flexDirection:'row', alignItems:'center' }} >
                    <Typography variant="body2" fontWeight="bold">
                        {item.name}
                      </Typography>
                      <div style={{ flex: 1, borderBottom: '1px dotted black', margin: '0 10px' }}></div>
                      <Typography variant="body2" fontWeight="bold">
                        {`x${item.quantity}`}
                      </Typography>
                  </div>                  
                  {item.unique_selections && item.unique_selections.length > 0 && (
                    <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                      {item.unique_selections.map((sel) => sel.name).join(', ')}
                    </Typography>
                  )}
                  {item.multiple_selections && item.multiple_selections.length > 0 && (
                    <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                      {item.multiple_selections.map((sel) => sel.name).join(', ')}
                    </Typography>
                  )}
                  {item.supplements && item.supplements.length > 0 && (
                    <Typography variant="body2" style={{ paddingLeft: '5px', fontWeight: 400 }}>
                      {item.supplements.map((sup) => `${sup.quantity}x ${sup.name}`).join(', ')}
                    </Typography>
                  )}
                  {item?.note&&item.note.length > 0 &&(
                    <Typography variant="body2" style={{ paddingLeft: '10px' }}>
                     Note :  {item?.note}
                    </Typography>
                  )}
                </div>
                <div>
                <IconButton onClick={() => handleQuantityChange(index, item.quantity - 1)}>
                <RemoveCircleIcon />
              </IconButton>
              <TextField value={item.quantity} onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))} style={{ width: '50px' }} />
              <IconButton onClick={() => handleQuantityChange(index, item.quantity + 1)}>
                <AddCircleIcon />
              </IconButton>
              <IconButton onClick={() => handleRemoveProduct(index)}>
                <RemoveCircleIcon color="secondary" />
              </IconButton>                    
                </div>
              
            </div>
          ))}
          <Typography variant="body2">Prix Total : {totalPrice}{currencies.symbol}</Typography>
        </div>
        <Button onClick={handleSave} variant="contained" color="primary">Valider la commande</Button>
        {selectedProduct && (
          <Dialog sx={{width:'100%'}} open={!!selectedProduct} onClose={handleCloseProductModal}>
            <ProductCard product={selectedProduct} onAddToCart={handleSaveProduct} onClose={handleCloseProductModal} currencies={currencies} />
          </Dialog>
        )}
      </div>
    </Modal>
  );
};

export default EditOrderModal;
