import React, { useState, useEffect } from 'react';
import { Modal, Typography, Button } from '@mui/material';
import { VolumeUp, VolumeOff, KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const SettingsModal = ({ isVisible, onClose }) => {
  const navigate = useNavigate();

  // Récupérer l'état du son depuis localStorage au démarrage ou accorder true par défaut
  const [isSoundOn, setIsSoundOn] = useState(() => {
    const soundSetting = localStorage.getItem('isSoundOn');
    return soundSetting !== null ? JSON.parse(soundSetting) : true;
  });

  const [selectedCurrency, setSelectedCurrency] = useState('EUR');
  const [isCurrencyDropdownVisible, setIsCurrencyDropdownVisible] = useState(false);

  const toggleSound = () => {
    setIsSoundOn((prevIsSoundOn) => {
      const newSoundSetting = !prevIsSoundOn;
      localStorage.setItem('isSoundOn', JSON.stringify(newSoundSetting)); // Sauvegarder l'état du son
      return newSoundSetting;
    });
  };

  const toggleCurrencyDropdown = () => {
    setIsCurrencyDropdownVisible(!isCurrencyDropdownVisible);
  };

  const changeCurrency = (currency) => {
    setSelectedCurrency(currency);
    setIsCurrencyDropdownVisible(false);
    // Ajoutez ici votre logique pour changer la devise sélectionnée
  };

  const handleLogout = () => {
    // Ajoutez ici votre logique de déconnexion si nécessaire
    navigate('/comptoir/connexion');
  };

  const currencies = ['USD', 'EUR']; // Tableau de devises

  return (
    <Modal open={isVisible} onClose={onClose}>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'white', padding: 20, borderRadius: 10 }}>
        <Typography sx={{color:'black'}} variant="h6" gutterBottom>
          Paramètres
        </Typography>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
          <Typography sx={{color:'black'}} variant="subtitle1">Son</Typography>
          <Button onClick={toggleSound}>
            {isSoundOn ? <VolumeUp /> : <VolumeOff />}
          </Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
          <Typography sx={{color:'black'}} variant="subtitle1">Devise</Typography>
          <Button onClick={toggleCurrencyDropdown}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1">{selectedCurrency}</Typography>
              {isCurrencyDropdownVisible ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </div>
          </Button>
        </div>
        {isCurrencyDropdownVisible && (
          <div style={{ marginTop: 10 }}>
            {currencies.map((currency) => (
              <Button key={currency} onClick={() => changeCurrency(currency)} style={{ textTransform: 'none', marginBottom: 10 }}>
                <Typography variant="body2">{currency}</Typography>
              </Button>
            ))}
          </div>
        )}
        <Button variant="outlined" color="primary" onClick={handleLogout} style={{ alignSelf: 'center', marginTop: 20 }}>
          Se déconnecter
        </Button>
      </div>
    </Modal>
  );
};

export default SettingsModal;
