import React, { useState } from 'react';
import { Button, Modal, Typography } from '@mui/material';
import OrderDetails from './OrderDetails';
import CircleIcon from '@mui/icons-material/Circle';
const OrderHistory = ({ order, currencies }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const renderColorDot = (status) => {
    let color = '#000000'; // Couleur par défaut
    switch (status) {
      case 'En attente':
        color = '#8BD2FF'; // Jaune pour En attente
        break;
      case 'En cours':
        color = 'rgba(255, 202, 113, 0.8)'; // Bleu pour En cours
        break;
      case 'Terminée':
        color = 'rgba(145, 254, 159, 0.8)'; // Vert pour Terminé
        break;
      case 'Refusée':
        color = 'rgba(255, 173, 173, 0.56)'; // Rouge pour Refusé
        break;
      default:
        break;
    }
    return (
      <CircleIcon style={{ color, marginRight: '10px', fontSize: '10px' }} />
    );
  };
  const isScreenSmall = () => {
    return window.innerWidth <= 768; // Utilisez une largeur de 768 pixels comme seuil pour déterminer la taille de l'écran
  };
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div style={{backgroundColor: '#f0f0f0', borderRadius: '10px', marginTop: '10px' }}>
        <div 
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'stretch',padding:'1%',
          cursor: 'pointer',
          boxShadow: isHovered ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none', // Ajout de l'effet d'élévation
          transition: 'box-shadow 0.3s ease-in-out' ,// Ajout de la transition pour l'effet de survol
        
        }} 
        onClick={() => setModalVisible(true)}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
          <div>          {renderColorDot(order.status)}
          </div>
          <div>
            <Typography style={{ flex: 1, fontSize: isScreenSmall() ? '10px' : '16px', fontWeight: '500', textAlign: 'center' }}>
                        {order.numberOfTable}
              </Typography>            
          </div>
          <div>
          <Typography style={{ flex: 1, fontSize: isScreenSmall() ? '10px' : '16px', fontWeight: '500', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'center' }}>
            {order.status}
          </Typography>            
          </div>
          <div>
          <Typography style={{ flex: 1, fontSize: isScreenSmall() ? '10px' : '16px', fontWeight: '500', paddingLeft: '10px', textAlign: 'center' }}>
            {order.price} {currencies.symbol}
          </Typography>            
          </div>
          <div>
          <Typography style={{ flex: 1, fontSize: isScreenSmall() ? '10px' : '16px', fontWeight: '500', paddingLeft: '10px', textAlign: 'center' }}>
            {order.hour}
          </Typography>            
          </div>
          



        </div>
      <Modal
      open={modalVisible}
      onClose={() => setModalVisible(false)}
      aria-labelledby="order-details-modal"
      aria-describedby="order-details"
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <div style={{
        backgroundColor: '#fff',
        padding: '20px',
        textAlign: 'center',
        maxWidth: '90vw',
        margin: '0 auto',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)'
      }}>
        <Typography variant="h5" style={{ fontWeight: 'bold', marginBottom: '10px', color: 'black' }}>
          Détails de la commande
        </Typography>
        <OrderDetails order={order} currencies={currencies} />
        <Button style={{ backgroundColor: '#000000', color: '#fff', marginTop: '20px',  }} onClick={() => setModalVisible(false)}>
          Fermer
        </Button>
      </div>
    </Modal>
    </div>
  );
};

export default OrderHistory;
