import React, { useState,useRef, useEffect } from 'react';
import { Button, TextField, IconButton, Select, MenuItem, InputLabel, FormControl, Avatar, CircularProgress, Backdrop } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import cocktail2 from '../../public/cocktail2.jpg'
import { connect } from 'react-redux';
import { addProduct, deleteProduct, editProduct } from '../../actions/productActions';
import { useNavigate } from 'react-router-dom';
import client from '../../api/client';
import { conversionData } from './conversionData';
import Switch from '@mui/material/Switch';



const ProductForm = ({data={},type,nom, onDelete, onAdd, onEdit, onClose, id_subcategory, id_type}) => {
  const [productName, setProductName] = useState(data.name || '');
  const [image, setImage] = useState(data.image || null);
  const [imageShow, setImageShow] = useState(data.image||null);
  const [allergenes, setAllergenes] = useState(data?.allergenes || []); // État pour stocker les allergènes
  const [newAllergeneName, setNewAllergeneName] = useState(''); // État pour stocker le nom du nouvel allergène

  const [productPrice, setProductPrice] = useState(data.price || 0);
  const [productRating, setProductRating] = useState(data.rating || 0);
  const [productDescription, setProductDescription] = useState(data.description || '');
  const [sections, setSections] = useState(conversionData(data)||[]);
  const [newData, setNewData] = useState({});
  const [display, setDisplay] = useState(data?.display !== undefined ? data.display : true);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("token"))
  const [loading, setLoading] = useState(false);

  
  const handleAddAllergene = () => {
    if (newAllergeneName.trim() !== '') { // Vérifier si le champ de saisie n'est pas vide
      setAllergenes([...allergenes, newAllergeneName]); // Ajouter le nouvel allergène au tableau
      setNewAllergeneName(''); // Réinitialiser le champ de saisie après l'ajout
    }
  };
  const handleRemoveAllergene = (indexToRemove) => {
    setAllergenes(allergenes.filter((_, index) => index !== indexToRemove)); // Filtrer les allergènes pour exclure celui qui a été cliqué
  };
  const imageInputRef = useRef();
  const handleCheckboxChange = (event) => {
    setDisplay(event.target.checked);
  };
  
  
  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    console.log("file ",e.target.files[0])
    setImageShow(URL.createObjectURL(selectedImage));
    setImage(e.target.files[0]);
  };
  
  const handleImageUpload = () => {
    console.log("upload")
    imageInputRef.current.click(); // Ouvre le sélecteur de fichiers
  };  
  
  const handleAddSection = (type) => {
    setSections([...sections, { type, name: '', elements: [] }]);
  };

  const handleDeleteSection = (index) => {
    const updatedSections = [...sections];
    updatedSections.splice(index, 1);
    setSections(updatedSections);
  };

  const handleAddElement = (sectionIndex) => {
    const updatedSections = [...sections];
    const section = updatedSections[sectionIndex];
      section.elements.push({ name: '', price: 0 });
    setSections(updatedSections);
  };

  const handleDeleteElement = (sectionIndex, elementIndex) => {
    const updatedSections = [...sections];
    const section = updatedSections[sectionIndex];

    section.elements.splice(elementIndex, 1);
    setSections(updatedSections);
  };

  const handleSave = async () => {
    const uniqueSelected = [];
    const multipleSelected = [];
    const supplements = [];
    const formData = new FormData();
  
    console.log("allergenes......", allergenes)
    if (image instanceof Blob) {
      formData.append('productImage', image, new Date() + '_image', { type: image.type });
    }
    
    
    console.log("sections", sections)
    sections.forEach((section) => {
      if (section.type === 'unique') {
        uniqueSelected.push({ nameCat: section.nameCat,required:section.required, data: [...section.elements] });
      } else if (section.type === 'multiple') {
        multipleSelected.push({
          nameCat: section.nameCat,
          required:section.required,
          numSelect: section.numSelect,
          data: [...section.elements],
        });
      } else if (section.type === 'supplement') {
        supplements.push({ nameCat: section.nameCat,required:section.required, data: [...section.elements] });
      }
    });
  console.log("uniqueSelected ", uniqueSelected)
  
   
    //const id = updatedNewData?.id;
  
    if (Object.keys(data).length !== 0) {
      console.log('uniqSelections.........', uniqueSelected)
      console.log('uniqSelections.........', supplements)
      formData.append('id_product', data.id);
      // Add other form data
      formData.append('name', productName || '');
      formData.append('display', display || false);
      formData.append('price', productPrice || 0);
      formData.append('description', productDescription || '');
      formData.append('rating', productRating || 0);
      formData.append('service_time', 20);
      formData.append('unique_selections', JSON.stringify(uniqueSelected || []));
      formData.append('multiple_selections', JSON.stringify(multipleSelected || []));
      formData.append('supplements', JSON.stringify(supplements || []));
      formData.append('id_subcategory', id_subcategory || '');
      formData.append('allergenes', JSON.stringify(allergenes || []));
      formData.append(
        'id_restaurant',
        (await JSON.parse(localStorage.getItem('restaurant'))?._id) || 'R.A.S'
      );
      setLoading(true); // Afficher la barre de chargement
      try {
        const res = await client.post('/edit-product', formData, {
          headers: {
            Accept: 'application/json',
            'content-Type': 'multipart/form-data',
            Authorization: `JWT ${token}`,
          },
        });
  
        if (!res?.data?.success) {
         navigate('/');
        } else {
          const updatedNewData = {
            id: res.data.savedProduct._id,
            display: display || false,
            image: res.data.savedProduct.image || '',
            name: productName||'',
            price: productPrice||0,
            description: productDescription||'',
            rating: productRating||0,
            reviews: [], // Vous pouvez ajouter la logique pour obtenir les avis à partir des sections si nécessaire
            uniqueSelected,
            multipleSelected,
            supplements,
            allergenes
            // ... (unchanged)
          };
          const newProduct = { id_type:res.data.idType , id_subcategory, updatedNewData, id: res.data.savedProduct._id};
          onEdit(newProduct);
          setNewData({});
          onClose();
        }
      } catch (error) {
        console.error('Error editing product:', error);
      }finally {
        setLoading(false); // Cacher la barre de chargement
      }
    } else {
      setLoading(true); // Afficher la barre de chargement
      formData.append('name', productName || '');
      formData.append('display', display || false);
      formData.append('price', productPrice || 0);
      formData.append('description', productDescription || '');
      formData.append('rating', productRating || 0);
      formData.append('service_time', 20);
      formData.append('unique_selections', JSON.stringify(uniqueSelected || []));
      formData.append('multiple_selections', JSON.stringify(multipleSelected || []));
      formData.append('supplements', JSON.stringify(supplements || []));
      formData.append('id_subcategory', id_subcategory || '');
      formData.append('allergenes', JSON.stringify(allergenes || []));
      formData.append(
        'id_restaurant',
        (await JSON.parse(localStorage.getItem('restaurant'))?._id) || 'R.A.S'
      );
      console.log("formData ", formData)

      try {
        const res = await client.post('/create-product', formData, {
          headers: {
            Accept: 'application/json',
            'content-Type': 'multipart/form-data',
            Authorization: `JWT ${token}`,
          },
        });
  
        if (!res?.data?.success) {
          navigate('/');
        } else {
          console.log("console type-id ",res.data.idType)
          const updatedNewData = {
            id: res.data.savedProduct._id,
            display: display || false,
            image: res.data.savedProduct.image || '',
            name: productName,
            price: productPrice,
            description: productDescription,
            rating: productRating,
            reviews: [], // Vous pouvez ajouter la logique pour obtenir les avis à partir des sections si nécessaire
            uniqueSelected,
            multipleSelected,
            supplements,
            allergenes
            // ... (unchanged)
          };
          const newProduct = { id_type:res.data.idType , id_subcategory, updatedNewData };
          onAdd(newProduct);
          setNewData({});
          onClose();
        }
      } catch (error) {
        console.error('Error creating product:', error);
      }finally {
        setLoading(false); // Cacher la barre de chargement
      }
    }
  };
  
const handleDelete = async() => {
    const id = data.id
    setLoading(true); // Afficher la barre de chargement
    try {
      await client.post("/delete-product", {
      id_subcategory:id_subcategory,
      id_product: id||'',
      id_restaurant:await JSON.parse(localStorage.getItem("restaurant"))?._id||'R.A.S',
      },
      {
        headers: {
          Accept: 'application/json',
          //'content-Type': 'multipart/form-data',
          Authorization : `JWT ${token}`,
         }
      }
      ).then((res)=>{
        if(!res?.data?.success){
          setNewData({});
          onClose()       
        }else{
          const productToDelete= {id_type, id_subcategory, id}
          onDelete(productToDelete);    // La fonction de suppression ici de la data qui est en paramètre
          console.log("Suppression du produit avec l'ID :", data.id);
          setNewData({});
          onClose()
        }
      })

    } catch (error) {
      
    }finally {
      setLoading(false); // Cacher la barre de chargement
    }
    // Mettez en œuvre la logique de suppression appropriée ici, par exemple, une requête API ou une autre action
  };
  
  return (
    <div style={{
        height: '90vh', // Définissez la hauteur souhaitée
        overflowY: 'auto',
  scrollbarWidth: 'none', /* Pour Firefox */
  msOverflowStyle: 'none', /* Pour Internet Explorer et Edge */
  '&::-webkit-scrollbar': { display: 'none' }, /* Pour Chrome, Safari et Opera */ // Rendez le contenu scrollable verticalement
        // Ajoutez d'autres styles si nécessaire
      }}>
    
      <TextField
        label="Nom du Produit"
        variant="outlined"
        fullWidth
        value={productName||''}
        onChange={(e) => setProductName(e.target.value)}
      />
        <input
    type="file"
    accept="image/*"
    onChange={handleImageChange}
    style={{ display: 'none' }} // pour cacher l'élément input file
    ref={imageInputRef}
    />
    <Button variant="outlined" onClick={handleImageUpload} style={{ marginTop: 10, marginBottom:10 }}>
     {/* Check if the product.image is available */}
     {image && typeof image !== 'string' ? (
                        // If it's a File, convert it to a data URL
                        <Avatar src={URL.createObjectURL(image)} style={{ width: 100, height: 100 }} />
                      ) : (
                        // If it's already a URL or base64 string, use it directly
                        <Avatar src={image} style={{ width: 100, height: 100 }} />
                      )}
    </Button>

      <TextField
        label="Prix du Produit"
        variant="outlined"
        fullWidth
        type="number"
        value={productPrice == undefined || productPrice < 0 ? 0 : productPrice}
        onChange={(e) => setProductPrice(Math.max(0, parseFloat(e.target.value)))}
      />

      <TextField
        label="Description du Produit"
        variant="filled"        
        multiline
        rows={4}
        value={productDescription || ''}
        onChange={(e) => setProductDescription(e.target.value)}
        sx={{width:"90%", marginTop:'5%' ,paddingBottom:'5%'}}
      />
      <div style={{ marginBottom: '5%' }}>
      {/* Champ de saisie pour le nom du nouvel allergène */}
      <TextField
        label="Nom de l'allergène"
        value={newAllergeneName}
        onChange={(e) => setNewAllergeneName(e.target.value)}
        style={{ marginRight: '10px' }}
      />
      {/* Bouton pour ajouter l'allergène */}
      <Button onClick={handleAddAllergene} variant="contained" color="primary">
        Ajouter
      </Button>
      {/* Afficher les allergènes dans des rectangles */}
      <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '10px' }}>
        {allergenes.map((allergene, index) => (
          <div
            key={index}
            style={{
              backgroundColor: '#e0e0e0',
              border: '1px solid #bdbdbd',
              borderRadius: '5px',
              padding: '5px 10px',
              margin: '5px',
              cursor: 'pointer', // Ajouter un curseur pour indiquer que c'est cliquable
            }}
            onClick={() => handleRemoveAllergene(index)} // Gérer la suppression de l'allergène lorsqu'il est cliqué
          >
            {allergene}
          </div>
        ))}
      </div>
    </div>
      <TextField
        label="Note du Produit"
        variant="outlined"
        fullWidth
        type="number"
        value={productRating == undefined || productRating < 0 || productRating > 5 ? 0 : productRating}
        onChange={(e) => setProductRating(Math.max(0, parseFloat(e.target.value)))}
      />
    <FormControlLabel
  control={<Checkbox checked={display} onChange={handleCheckboxChange} />}
  label="Afficher le produit"
  style={{ color: 'black' }} // Utilisation de la propriété style pour changer la couleur du texte
/>
     
     {sections.map((section, sectionIndex) => (
      <React.Fragment key={sectionIndex}>
          <div  style={{ border: '1px solid #ccc', borderRadius: 10, padding: 10, marginTop: 10 }}>
            <TextField
              label={`Nom de la ${section.type === 'supplement' ? 'Catégorie' : 'Sélection'}`}
              variant="outlined"
              fullWidth
              value={section.nameCat||''}
              onChange={(e) => {
                const updatedSections = [...sections];
                updatedSections[sectionIndex] = { ...section, nameCat: e.target.value };
                setSections(updatedSections);
              }}
            />
            <div style={{ display: 'flex', alignItems: 'center' }}>
          <h5 style={{color:'black'}}>Requis :</h5>
          <Switch
              checked={section?.required?section?.required:false}
              onChange={(e) => {
                  const updatedSections = [...sections];
                  updatedSections[sectionIndex] = { ...section, required: e.target.checked }; // Utiliser e.target.checked
                  setSections(updatedSections);
              }}
              color="primary"
          />
        </div>
            {section.elements.map((element, elementIndex) => (
              <div key={elementIndex} style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                {section.type !== 'supplement' && (
                  <input type={section.type === 'unique' ? 'radio' : 'checkbox'} style={{ marginRight: 5 }} disabled />
                )}

                <TextField
                  label="Ajout"
                  variant="outlined"
                  fullWidth
                  value={element.name||''}
                  onChange={(e) => {
                    const updatedSections = [...sections];
                    updatedSections[sectionIndex].elements[elementIndex].name = e.target.value;
                    setSections(updatedSections);
                  }}
                />

                <TextField
                  label="Prix optionnel"
                  variant="outlined"
                  fullWidth
                  type="number"
                  value={element.price !== undefined ? element.price : 0}
                  onChange={(e) => {
                    const updatedSections = [...sections];
                    updatedSections[sectionIndex].elements[elementIndex].price = Math.max(0, parseFloat(e.target.value));
                    setSections(updatedSections);
                  }}
                />

                <IconButton onClick={() => handleDeleteElement(sectionIndex, elementIndex)}>
                  <DeleteIcon />
                </IconButton>
              </div>
            ))}

            <Button onClick={() => handleAddElement(sectionIndex)} style={{ marginTop: 10 }}>
              <AddIcon /> Ajouter un Élément
            </Button>

            <IconButton onClick={() => handleDeleteSection(sectionIndex)} style={{ marginTop: 10 }}>
              <DeleteIcon />
            </IconButton>
          </div>
      </React.Fragment>
       
      ))}

      <FormControl fullWidth variant="outlined" style={{ marginTop: 10 }}>
        <InputLabel htmlFor="section-type">Ajouter une Section</InputLabel>
        <Select
            label="Ajouter une Section"
            //sections.length > 0 ? sections[0].type : 'unique'
            value={''}
            onChange={(e) => handleAddSection(e.target.value)}
          >
            <MenuItem value="unique">Ajouter une sélection unique</MenuItem>
            <MenuItem value="multiple">Ajouter une sélection multiple</MenuItem>
            <MenuItem value="supplement">Ajouter un supplément</MenuItem>
          </Select>
      </FormControl>
      <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      
      {data ? (<>
        <Button variant="contained"  onClick={handleSave} style={{ marginTop: 20, left:'10%' }}>
        Enregistrer
      </Button>
        <Button variant="outlined"  onClick={handleDelete} style={{ marginTop: 20, left:'50%' }}>
        Supprimer
      </Button>
      </>
        ): (sections.length>0?<Button variant="contained"  onClick={handleSave} style={{ marginTop: 20, left:'10%' }}>
        Ajouter
      </Button>:null)}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  onDelete: (productToDelete) => dispatch(deleteProduct(productToDelete)),
  onEdit : (newProduct) => dispatch(editProduct(newProduct)),
  onAdd : (newProduct) => dispatch(addProduct(newProduct)),
});

export default connect(null, mapDispatchToProps)(ProductForm);