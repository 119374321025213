import React, { useState } from 'react';

const EmailEditor = ({variables}) => {
  const [emailContent, setEmailContent] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailTemplate, setEmailTemplate] = useState('Bonjour {{nom}}. Je veux {{quantite}} {{produit}} à {{heure}}.');
  const [selectedVariable, setSelectedVariable] = useState('');
  const [file, setFile] = useState(null);

  const handleVariableSelection = (variable) => {
    // Créer un nouveau template en ajoutant la variable sélectionnée à la fin du template existant
    const updatedTemplate = emailTemplate + ` {{${variable}}}`;
    // Mettre à jour le state avec le nouveau template
    setEmailTemplate(updatedTemplate);
  };

  const handleSubjectChange = (event) => {
    setEmailSubject(event.target.value);
  };

  const handleInsertVariable = (variable) => {
    // Insérer la variable sélectionnée dans l'objet du mail (emailSubject)
    const updatedSubject = emailSubject + ` {{${variable}}}`;
    setEmailSubject(updatedSubject);
  };
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };
  const handleTest = ()=>{
    
  }
  //const variables = ['nom', 'Lien questionnaire', 'produit', 'heure'];

  return (
    <div style={{}}>
      <div style={{ display: 'flex',}}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <div>Objet</div>
          <input
            type='text'
            value={emailSubject}
            onChange={handleSubjectChange}
            placeholder="Saisissez l'objet du mail..."
            style={{ padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }}
          />
          <div>Contenu mail</div>
          <textarea
            value={emailTemplate}
            onChange={(e) => setEmailTemplate(e.target.value)}
            style={{ width: '100%', minHeight: '150px', padding: '8px', border: '1px solid #ccc', borderRadius: '4px', marginBottom: '10px' }}
          />
          <div style={{ marginTop: '3%' }}>
            <label>Sélectionner une Variable :</label>
            <select value={selectedVariable} onChange={(e) => setSelectedVariable(e.target.value)}>
              <option value="">-- Sélectionner --</option>
              {variables.map((variable) => (
                <option key={variable} value={variable}>{variable}</option>
              ))}
            </select>
            <button onClick={() => handleVariableSelection(selectedVariable)}>Ajouter Variable (Contenu)</button>
            <button onClick={() => handleInsertVariable(selectedVariable)}>Ajouter Variable (Objet)</button>
          </div>
          <div style={{ marginTop: '3%' }}>
            <label>Sélectionner un Fichier :</label>
            <input type="file" onChange={handleFileChange} style={{ marginTop: '8px' }} />
          </div>
          <button onClick={() => handleTest()}>Tester</button>
        </div>
      </div>
    </div>
  );
};

export default EmailEditor;
