import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import client from '../../../api/client';
import TextField from '@mui/material/TextField';
import {CircularProgress, Backdrop } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import './Restaurant.css';
import Tooltip from '@mui/material/Tooltip';

import {
  UilImageUpload,
  UilImage,
  UilPicture
} from "@iconscout/react-unicons";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import SocialNetwork from './SocialNetwork';

const Restaurant = () => {
  const [name, setName] = useState('');
  const [numberOfTable, setNumberOfTable] = useState(0);
  const [images, setImages] = useState([]);
  const [logo, setLogo] = useState('');
  const [logoFile, setLogoFile] = useState(null)
  const [description, setDescription] = useState(''); // État pour la description
  const [socialsNetworks, setSocialsNetworks] = useState([])
  const [showAddNetwork, setShowAddNetwork] = useState(false);
  const [watingTime, setWatingTime] = useState(15)
  const [loading, setLoading] = useState(false);

  const handleLogoChange = async(file)=>{
    try {
      setLoading(true); // Afficher la barre de chargement
        setLogoFile(file)
        console.log("file........", file)
        const formData = new FormData();
        formData.append('logo',file, new Date() + '_image', { type: file.type })
      const token = JSON.parse(localStorage.getItem('token'));
      await client.post('set-logo', formData, {
          headers: {
              Accept: 'application/json',
              'content-Type': 'multipart/form-data',
              authorization : `JWT ${token}`,
            }
      }).then((res)=>{
        console.log('res.data....', res.data)
          if(res.data.success){
              setLogo(res.data.url)
          }
      })
    } catch (error) {
      
    }finally {
      setLoading(false); // Cacher la barre de chargement
    }
    
  }
const handleDeleteImage = async(image)=>{
  try {
    console.log('image.......', image)
  const token = JSON.parse(localStorage.getItem('token'));
  await client.post('/delete-image', {imageUrl:image}, {
      headers: {
          Accept: 'application/json',
          //'content-Type': 'multipart/form-data',
          authorization : `JWT ${token}`,
        }
  }).then((res)=>{
      if(res.data.success){
          setImages(res.data.images)
      }
  })
  } catch (error) {
    
  }
}
const handleAddImage = async(file, index, imageUrl)=>{
  try {
    console.log("file........", file)
    const formData = new FormData();
    formData.append('image',file, new Date() + '_image', { type: file.type })
    if(imageUrl){
      formData.append('imageUrl', imageUrl)
    }else{
      formData.append('imageUrl', '')
    }
    formData.append('index', index)
  const token = JSON.parse(localStorage.getItem('token'));
  await client.post('/add-image', formData, {
      headers: {
          Accept: 'application/json',
          'content-Type': 'multipart/form-data',
          authorization : `JWT ${token}`,
        }
  }).then((res)=>{
    console.log('res.data....', res.data)
      if(res.data.success){
        setImages(res.data.images)
      }
  })
} catch (error) {
  
}
}
  useEffect(() => {
    const fetch = async () => {
      const token = JSON.parse(localStorage.getItem('token'));
      await client.post('get-restaurant', { token }, {
          headers: {
            Accept: 'application/json',
            authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            setName(res.data.restaurant.name);
            setNumberOfTable(res.data.restaurant?.tables?.length);
            setImages(res.data.restaurant.images);
            setSocialsNetworks(res.data.restaurant.socialLinks || [])
            setDescription(res.data.restaurant.description)
            const newWatingTime = res.data.waitingTime ? res.data.waitingTime : 15
            setWatingTime(newWatingTime)
            if (res.data.restaurant?.logo) setLogo(res.data.restaurant?.logo);
          }
        });
    };
    fetch();
  }, []);

  useEffect(() => {
    console.log('logo........', logo);
  }, [name, images, logo, numberOfTable]);

  const handleSave = async() => {
    try{
    const token = JSON.parse(localStorage.getItem('token'));
    setLoading(true); // Afficher la barre de chargement
    await client.post('/edit-restaurant', {name, numberOfTable, description, networks:socialsNetworks, watingTime}, {
        headers: {
            Accept: 'application/json',
            //'content-Type': 'multipart/form-data',
            authorization : `JWT ${token}`,
          }
    }).then((res)=>{
      console.log('res.data....', res.data)
          if (res.data.success) {
            setName(res.data.restaurant.name);
            setNumberOfTable(res.data.restaurant?.tables?.length);
            setImages(res.data.restaurant.images);
            setDescription(res.data.description)
            const newWatingTime = res.data.waitingTime ? res.data.waitingTime : 15
            setWatingTime(newWatingTime)
            if (res.data.restaurant?.logo) setLogo(res.data.restaurant?.logo);
          }
    })
  } catch (error) {
    
  }finally {
    setLoading(false); // Cacher la barre de chargement
  }
  };
  
  const handleSaveNetwork = (network) => {
    const newNetwork = { icon:network.icon, url: network.url, name:network.name };
      setSocialsNetworks(prev => [...prev, newNetwork]);
      setShowAddNetwork(false);
  };
  
  const deleteNetwork = (network) => {
    setSocialsNetworks(prev => prev.filter(item => item.url !== network));
  };
  const handleAddNetwork = () => {
    setShowAddNetwork(true);
  };
  return (
    <Card elevation={3} style={{ margin: 'auto', marginBottom: 10 }}>
      <CardContent>
        {/* Nom du restaurant */}
        <Typography variant="h5" style={{ fontWeight: 'bold'}} gutterBottom>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            style={{ fontSize: 'inherit', fontWeight: 'inherit', border: 'none', width: '100%', textAlign: 'center' }}
          />
        </Typography>

        {/* Logo */}
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
  {logo && logo.length > 0 ? (
    <label htmlFor="logoInput">
      <div className="image-container-logo" style={{ borderRadius: '50%', overflow: 'hidden', width: '90px', height: '90px' }}>
        <img src={logo} alt="Logo du restaurant" className="imageLogo" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </div>
      <input
        id="logoInput"
        type="file"
        accept="image/*"
        style={{ display: 'none' }}
        onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              handleLogoChange(file);
            }
        }} />
    </label>
  ) : (
    <div className="placeholder-image-container image-container" style={{ borderRadius: '50%', overflow: 'hidden', width: '90px', height: '90px', border: '2px dashed #ccc', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
      <label htmlFor="logoInput" style={{ cursor: 'pointer' }}>
        Télécharger un nouveau logo
        <input
          id="logoInput"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              handleLogoChange(file);
            }
          }} />
      </label>
    </div>
  )}
        </div>
        <div style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
          <div>Cliquer sur l'icon pour ajouter un lien ou réseau social</div>
          <Grid container spacing={2} justifyContent="center">
      {socialsNetworks?.slice(0, 3)?.map((network, index) => (
        <Grid item key={index}>
          <SocialNetwork
            network={network.url}
            handleSaveNetwork={handleSaveNetwork}
            deleteNetwork={deleteNetwork}
          />
        </Grid>
      ))}
      {showAddNetwork ? (
        <Grid item>
          <SocialNetwork
            handleSaveNetwork={handleSaveNetwork}
            deleteNetwork={() => setShowAddNetwork(false)}
          />
        </Grid>
      ) : (
        <Grid item>
          <IconButton onClick={handleAddNetwork}>
            <AddIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
        </div>
        
        <div style={{ marginBottom: 20 }}>
          <TextField
            label="Description du restaurant"
            multiline
            rows={4}
            value={description}
            onChange={(e) => setDescription(e.target.value.slice(0, 500))}
            variant="outlined"
            fullWidth
          />
        </div>
       
          

        {/* Images */}
        {/* Images */}
<Grid container justifyContent="center">
  {images.slice(0, 6).map((image, index) => (
    <Grid item key={index}>
              <label htmlFor={`fileInput-${index}`}>
                <div className="image-container">
                  <img src={image} alt={`Image ${index + 1}`} className="image" />
                  <DeleteOutlinedIcon
                    className="delete-icon"
                    style={{ position: 'absolute', top: 0, right: 0, cursor: 'pointer', color: 'red' }}
                    onClick={() => handleDeleteImage(image)}
                  />
                </div>
                <input
                  id={`fileInput-${index}`}
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  onChange={(e) => handleAddImage(e.target.files[0], index, image)}
                />
              </label>
            </Grid>

  ))}
  {[...Array(Math.min(1, 6 - images.length))].map((_, index) => (
    <Grid item key={index} style={{ position: 'relative' }}>
  <label htmlFor={`fileInput-${index + images.length}`} style={{ cursor: 'pointer' }}>
    <div className="image-container" style={{ position: 'relative' }}>
      <UilPicture className="image-placeholder" size="120" />
    </div>
    <input
      id={`fileInput-${index + images.length}`}
      type="file"
      accept="image/*"
      style={{ display: 'none' }}
      onChange={(e) => handleAddImage(e.target.files[0], index)}
    />
  </label>
</Grid>

))}
</Grid>


        {/* Nombre de tables */}
        <Typography variant="body1" style={{ marginTop: 20 }}>
          Nombre de tables : {numberOfTable}
          <input
            type="number"
            value={numberOfTable}
            onChange={(e) => setNumberOfTable(e.target.value)}
            style={{ marginLeft: 10 }}
          />
        </Typography>
        <Typography variant="body1" style={{ marginTop: 20 }}>
          Temps moyen de service :<Tooltip title="Le temps d'attente d'un client pour être servi"> {watingTime} minutes</Tooltip>
          <input
            type="number"
            value={watingTime}
            onChange={(e) => setWatingTime(e.target.value)}
            style={{ marginLeft: 10 }}
          />
        </Typography>
        
        

        {/* Bouton Enregistrer */}
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Enregistrer
          </Button>
        </div>
      </CardContent>
      <Backdrop style={{ color: '#fff', zIndex: 1000 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      
    </Card>
  );
};

export default Restaurant;
