import React from 'react';

const CGU = () => {
  return (
<div style={{
  background: 'white',
  height: '80vh',
  overflowY: 'auto',
  scrollbarWidth: 'none', /* Pour Firefox */
  msOverflowStyle: 'none', /* Pour Internet Explorer et Edge */
  '&::-webkit-scrollbar': { display: 'none' }, /* Pour Chrome, Safari et Opera */ // Ajout pour activer le défilement vertical lorsque le contenu dépasse la hauteur spécifiée
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', // Effet d'élévation
  borderRadius: '20px', // Bordures arrondies
  padding: '20px' // Ajout pour un espacement interne
}}>      <h2 style={{textAlign:'center'}}>Conditions Générales d'Utilisation de Yumz</h2>
      <p><strong>1. Introduction</strong></p>
      <p>Les présentes Conditions Générales d'Utilisation (ci-après dénommées les "CGU") régissent l'utilisation de la plateforme logicielle en tant que service (SaaS) fournie par Yumz (ci-après dénommée "Yumz" ou "nous"), accessible via le site web yum-z.com (ci-après dénommé le "Site").</p>
      
      <p><strong>2. Objet</strong></p>
      <p>Yumz propose une plateforme SaaS destinée à aider les restaurants et restaurateurs à gérer les commandes à partir du scan de codes QR sur les tables. Cette plateforme comprend des outils de gestion pour les restaurateurs et permet aux clients de passer des commandes facilement.</p>
      
      <p><strong>3. Services Proposés</strong></p>
      <p><strong>3.1 Plateforme SaaS de gestion des commandes :</strong> Yumz offre une plateforme en ligne permettant aux restaurants de configurer leur menu, leurs options de commande et leurs informations de contact. Les clients peuvent scanner un code QR sur leur table pour accéder au menu et passer des commandes directement depuis leur appareil mobile.</p>
      <p><strong>3.2 Outils de gestion pour les restaurateurs :</strong> Yumz fournit des outils de gestion complets permettant aux restaurateurs de gérer leur menu, leurs stocks, leurs commandes en cours et leur historique, ainsi que de générer des rapports et des analyses pour évaluer les performances de leur restaurant.</p>
      
      <p><strong>4. Abonnement</strong></p>
      <p><strong>4.1 Modèle économique :</strong> L'accès à la plateforme et à ses fonctionnalités est soumis à un abonnement mensuel ou annuel payé par les restaurateurs.</p>
      <p><strong>4.2 Paiement :</strong> Les restaurateurs sont tenus de payer les frais d'abonnement conformément aux modalités de paiement spécifiées par Yumz.</p>
      
      <p><strong>5. Responsabilités de l'Utilisateur</strong></p>
      <p><strong>5.1 Utilisation conforme :</strong> L'utilisateur s'engage à utiliser la plateforme uniquement conformément aux lois applicables et aux présentes CGU.</p>
      <p><strong>5.2 Protection des identifiants :</strong> L'utilisateur est responsable de la confidentialité de ses identifiants de connexion et s'engage à ne pas les partager avec des tiers.</p>
      <p><strong>5.3 Contenu :</strong> L'utilisateur est seul responsable du contenu publié sur la plateforme et garantit détenir tous les droits nécessaires sur ce contenu.</p>
      
      <p><strong>6. Propriété Intellectuelle</strong></p>
      <p><strong>6.1 Droits de propriété :</strong> Yumz détient tous les droits de propriété intellectuelle sur la plateforme et son contenu.</p>
      <p><strong>6.2 Licence d'utilisation :</strong> Yumz accorde à l'utilisateur une licence limitée, non exclusive et non transférable pour utiliser la plateforme conformément aux présentes CGU.</p>
      
      <p><strong>7. Limitation de Responsabilité</strong></p>
      <p>Dans les limites autorisées par la loi, Yumz ne peut en aucun cas être tenu responsable des dommages indirects, accessoires, spéciaux ou consécutifs découlant de l'utilisation ou de l'incapacité d'utiliser la plateforme.</p>
      
      <p><strong>8. Modification des CGU</strong></p>
      <p>Yumz se réserve le droit de modifier les présentes CGU à tout moment. Les utilisateurs seront informés de toute modification via le Site ou par tout autre moyen jugé approprié.</p>
      
      <p><strong>9. Droit Applicable et Juridiction Compétente</strong></p>
      <p>Les présentes CGU sont régies par le droit applicable en France . Tout litige relatif à l'interprétation ou à l'exécution des présentes CGU sera soumis à la compétence exclusive des tribunaux français.</p>
      
      <p>En acceptant les présentes CGU, l'utilisateur reconnaît avoir lu, compris et accepté l'ensemble des dispositions qui y sont contenues.</p>
    </div>
  );
};

export default CGU;
