import React, { useState,useRef } from 'react';
import { Button, Typography, Modal } from '@mui/material';
import { ArrowLeft, ArrowRight } from '@mui/icons-material';
import PaymentModal from './PaymentModal'; // Importer le composant du modal de paiement
import CardCommandSmallPayments from './CardCommandSmallPayments';
import { useNavigate } from 'react-router-dom';
import client from '../../../api/client';
const OrderPayments = ({ orderRestorer, currencies, onDeleteItemPayment }) => {
  const navigate = useNavigate();
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const containerRef = useRef(null);

  const handleCardClick = (order) => {
    setSelectedOrder(order);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handlePayment = async(paymentType) => {
    const token = JSON.parse((localStorage.getItem("tokenRestorer")))
    if(token){
      await client.post('/validatePayment',{orderId:selectedOrder.orderId, newStatus:selectedOrder.status, typePayment:paymentType},{
      headers: {
        Accept: 'application/json',
        //'content-Type': 'multipart/form-data',
        Authorization : `JWT ${token}`,
      }
    }).then((res)=>{
      console.log('res.data....', res.data)
      if(!res?.data?.success){
        navigate('/comptoir/connexion');
      }
      if(res?.data?.success){
        onDeleteItemPayment(selectedOrder.orderId, paymentType);
        
        setTimeout(() => {
          setModalOpen(false);
          setSelectedOrder(null);
        }, 3000);
      }
    })
    }else {
        navigate('/comptoir/connexion');
      }
    
  };

  
  return (
<div style={{ position: 'relative', overflow: 'hidden', height: '90vh',overflowY: 'auto', /* Pour Chrome, Safari et Opera */
    width:'100%'
  }}>
        {orderRestorer.map((order) => (
          <div  onClick={() => handleCardClick(order)}>
            <CardCommandSmallPayments order={order} currencies={currencies} />
          </div>
        ))}
      {selectedOrder && (
        <Modal open={modalOpen} onClose={handleCloseModal}>
          <PaymentModal
            order={selectedOrder}
            onClose={handleCloseModal}
            onPayment={handlePayment}
            currencies={currencies}
          />
        </Modal>
      )}
    </div>
  );
};

export default OrderPayments;
