// HomePage.jsx

import React,{useEffect, useState} from 'react';
import './HomePage.css';
import { Button } from '@mui/material';
import {
  UilMobileAndroid,
  UilAnalysis,
  UilUsersAlt,
  UilCheckCircle,
  UilPanelAdd,
  UilStore,
  UilHistory
} from "@iconscout/react-unicons";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Register from './Register';
import SignIn from './SignIn';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import CGU from './CGU';
import PrivacyPolicy from './PrivacyPolycy';
import logo from './logo.png'
import img2 from './img2.png'
import img3 from './img3.png'
import img1BG from './img1BG.png'
import cover from './cover.gif'
import scanQR from './scanCodeQR.png'
import ContactUs from './ContactUs';
import Pricing from './Pricing';
import ImageSlider from './ImageSlider';
import Banner from './Banner'; // Import the Banner component


const HomePage = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [register, setRegister] = useState(0)
  const navigate = useNavigate()
  const text = "Le digital à votre service";

  const openRegister =()=>{
    setRegister(1)
    openModal()
  }
  const openSign = ()=>{
    setRegister(2)
    openModal()
  }
  const openCGU = ()=>{
    setRegister(3)
    openModal()
  }
  const openPP = ()=>{
    setRegister(4)
    openModal()
  }
  const openModal = () => {
    setModalOpen(true);
  };
  const contactUs = () => {
    setRegister(5)
    openModal()
  };
  const showPricing = ()=>{
    setRegister(6)
    openModal()
  }

  const closeModal = () => {
    setRegister(0)
    setModalOpen(false);
  };
  const handleOverlayClick = (e) => {
    // Check if the click was outside the modal content
    if (e.target.classList.contains('modal-overlay')) {
      closeModal();
    }
  };
  useEffect(() => {
    const handleScroll = () => {
        const elements = document.querySelectorAll('.image-paragraph-section, .paragraph-image-section');
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        // Ajuster le seuil en fonction de la taille de l'écran
        const threshold = windowWidth < 768 ? windowHeight / 1.1 : windowHeight / 1.2;

        elements.forEach((element) => {
            const elementPosition = element.getBoundingClientRect().top;
            if (elementPosition < threshold) {
                element.classList.add('show');
            } else {
                element.classList.remove('show');
            }
        });
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Appelez la fonction une fois pour afficher les éléments initialement visibles

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);


  const imageStyle = {
    // Appliquer le filtre drop-shadow pour l'élévation
    filter: "drop-shadow(0 8px 16px rgba(255, 255, 255, 0.2)) drop-shadow(0 8px 16px rgba(46, 178, 254, 0.3)) drop-shadow(0 8px 16px rgba(148, 72, 255, 0.3)) drop-shadow(0 8px 16px rgba(238, 48, 255, 0.2))",
  };
  const calendlyReservation = () => {
    const calendlyUrl = 'https://calendly.com/joyce-anagbla/presentation-de-yumz';
    window.open(calendlyUrl, '_blank');
  };
  
  return (
    <div className='home'>
    <Banner /> {/* Add the Banner component here */}
    <nav className="navbar">
    <h1 className="logo-section">
        <img src={logo} alt="Logo" 
        />
      <span style={{ verticalAlign: "sub", marginTop:"15%", marginLeft:'-25%', color:"#17174F" }}>umz</span>
    </h1>
        <ul className="navbar-list">
          <li className="navbar-item"><a href="#about">À propos</a></li>
          <li className="navbar-item"><a href="#pricing" onClick={showPricing} >Prix</a></li>
          <li className="navbar-item"><a href="#contact" onClick={contactUs}>Nous Contacter</a></li>
          <li className="navbar-item" ><a href="#login" onClick={openSign}>Connexion</a></li>
          <li className="navbar-item" ><a href="#signup" onClick={openRegister}>Inscription</a></li>
        </ul>
      </nav>
       <div className="home-page">
      <section className='allSection'>
        {/* Slogan et boutons */}
        
      
        <section className="slogan-section">
          <div className="content">
            <div className="text">
              <h1 className="Slogan">
                {text.split("").map((el, i) => (
                  <motion.span
                    key={i}
                    initial={{ opacity: 0, y: 10, textShadow: "0 0 10px rgba(0, 0, 0, 0.5)" }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{
                      duration: 0.5,
                      delay: i * 0.05
                    }}
                  >
                    {el}
                  </motion.span>
                ))}
              </h1>
              <p style={{ color: '#525278' }}>
                Transformez l'expérience de votre restaurant avec Yumz, l'outil ultime pour la gestion des commandes via QR code et service à table, l'analyse des performances et la gestion des retours clients. Découvrez comment Yumz peut optimiser votre restaurant dès aujourd'hui.</p>
              <div className="button-middle">
                <Button
                  onClick={openRegister}
                  size="small"
                  variant="contained"
                  sx={{ background: "#4231fe", ":hover": { backgroundColor: "#776CFB" } }}
                  style={{ marginRight: '10px' }}
                >
                  <span className="btnConnexion" style={{ color: 'whitesmoke' }}>Essai gratuit</span>
                </Button>
                <Button
                  sx={{
                    color: "#776CFB",
                    borderColor: "#776CFB",
                    ":hover": { backgroundColor: "#4231fe", borderColor: '#4231fe', color: 'white' },
                  }}
                  size="small"
                  variant="outlined"
                  onClick={calendlyReservation}
                >
                  <span className="btnConnexion">En savoir plus</span>
                </Button>
              </div>
            </div>
            <div className="images">
                <img src={cover} alt="Description of GIF" className="gif-image" />
            </div>
          </div>
        </section>

      
      
      {/*<section className="video-section" style={{ paddingLeft: '16%', position: 'relative' }}>
        <div className="effet-lumiere">
           <a href="URL_DE_VOTRE_VIDEO" target="_blank" rel="noopener noreferrer" download>
           <img
            src={img3}
            alt="Cliquez pour lire la vidéo"
            style={{
              width: "100%",
              height: "50%",
              borderRadius: "8px",
              cursor: "pointer",
              boxShadow: "0 50px 100px rgba(66, 49, 254, 0.4)", // Ajustement maximal de la largeur (de 40px à 100px) et de l'opacité (de 0.6 à 1)
              position: 'relative',
            }}
          />
          <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="80"
                height="80"
                fill="#17174F"
                className="icone"
                
              >
              <path d="M8 5v14l11-7z" />
            </svg>
          </a>
        </div>
         
      </section>*/}
      {/* Vidéo */}
      

      {/* Fonctionnalités */}
      <section className='Section-features'>
        <h2 className="section-title">Explorez nos fonctionnalités clés ci-dessous</h2>
        <section className="features-section">
        <div className="feature-card">
          <UilMobileAndroid className="icon" color='#776CFB' size= '50'
          />
          <h3 >Commande depuis la Table</h3>
          <p style={{color:'#525278'}} className='btnConnexion'>Permettez à vos clients de passer des commandes<br/> directement depuis leur table via un dispositif mobile,<br/> améliorant ainsi leur expérience<br/> et accélérant le processus de service.</p>
        </div>

        <div className="feature-card">
          <UilCheckCircle className="icon" color='#776CFB' size= '50'/>
          <h3>Validation des Commandes par les Cuisiniers</h3>
          <p style={{color:'#525278'}} className='btnConnexion'>Les cuisiniers peuvent visualiser et valider les commandes en temps réel, assurant une coordination efficace entre le service en salle et la cuisine</p>
        </div>

        <div className="feature-card">
        <UilPanelAdd className="icon" color='#776CFB' size= '50'/>
          <h3>Dashboard en Temps Réel </h3>
          <p style={{color:'#525278'}} className='btnConnexion'>Accédez à un tableau de bord en temps réel qui vous fournit des informations cruciales, telles que le nombre de clients présents, les commandes en cours, et les avis du jour.</p>
        </div>

        {/* Ajoutez trois autres blocs similaires pour les autres fonctionnalités */}
      </section>
      <section className="features-section">
        <div className="feature-card">
          <UilStore className="icon" color='#776CFB' size= '50'/>
          <h3>Gestion des Produits Simplifiée</h3>
          <p style={{color:'#525278'}} className='btnConnexion'>Ajoutez, supprimez et gérez facilement vos produits, mettez à jour les prix et les descriptions en quelques clics pour maintenir votre menu à jour.</p>
        </div>

        <div className="feature-card">
          <UilUsersAlt className="icon" color='#776CFB' size= '50'/>
          <h3>Vue d'Ensemble des Clients</h3>
          <p style={{color:'#525278'}} className='btnConnexion'>Obtenez une vue d'ensemble des clients présents, de leurs commandes, et de leurs avis pour offrir un service plus personnalisé.</p>
        </div>

        <div className="feature-card">
          <UilAnalysis className="icon" color='#776CFB' size= '50'/>
          <h3>Statistiques sur les Produits</h3>
          <p style={{color:'#525278'}} className='btnConnexion'> Explorez des statistiques détaillées sur les plats, menus, boissons et desserts les plus populaires pour prendre des décisions éclairées sur votre offre.</p>
        </div>
        
        <div className="feature-card">
          <UilHistory className="icon" color='#776CFB' size= '50'/>
          <h3>Historique des Commandes </h3>
          <p style={{color:'#525278'}} className='btnConnexion'>Accédez à un historique complet de toutes les commandes passées pour une gestion efficace et la possibilité d'analyser les tendances au fil du temps.</p>
        </div>
        {/* Ajoutez trois autres blocs similaires pour les autres fonctionnalités */}
      </section>
      <section className="features-section">
      

        {/* Ajoutez trois autres blocs similaires pour les autres fonctionnalités */}
      </section>
      </section>
      <section className='line'>
      <span></span>

    </section>
      <section className='page-section'>
          <section className="product-section">
          <h2 className="section-title">Donnez un coup de jeune à votre restaurant avec Yumz</h2>
          <p className='paragraph'  style={{color:'#525278'}}>Simplifiez les commandes, suivez la performance en temps réel et optimisez votre menu pour une gestion sans faille. </p>
        </section>

        <section className="paragraph-image-section">
          <div className='text-gauche'>
            <h3 className='paragraph' style={{color:'#17174F'}}>Commandes Simplifiées : QR Code ou Service à Table</h3>
            <p className='paragraph' style={{color:'#525278'}}>
            Révolutionnez l'expérience de vos clients avec deux méthodes de commande innovantes : scannez le menu via QR code ou commandez directement auprès du serveur. Notre solution moderne et intuitive permet une consultation rapide et interactive du menu, tout en offrant la flexibilité de choisir la méthode de commande la plus adaptée.            </p>
          </div>
          <div>
          <img src={scanQR} style={imageStyle} alt="Logo" width="600px" height="400px" />
          </div>
        </section>
        {/* Image et paragraphe */}
        <section className="image-paragraph-section">
          <div>
            <img src={img1BG} style={imageStyle} alt="Logo" width="600px" height="400px" />
          </div>
          <div className='text-droit'>
            <h3 className='paragraph' style={{color:'#17174F'}}>Simplifiez votre Menu, Boostez votre Efficacité</h3>
            <p className='paragraph' style={{color:'#525278'}}>Ajoutez, modifiez ou retirez facilement des produits avec notre interface conviviale. Gardez votre menu toujours à jour, offrant ainsi à vos clients une expérience toujours fraîche et innovante.</p>
          </div>
        </section>


        {/* Paragraphe et image */}
        <section className="paragraph-image-section">
          <div className='text-gauche'>
            <h3 className='paragraph' style={{color:'#17174F'}}>Optimisez votre Menu avec des Données Puissantes</h3>
            <p className='paragraph' style={{color:'#525278'}}>Explorez les tendances et les performances de vos plats, menus, boissons et desserts. Prenez des décisions éclairées pour maximiser votre rentabilité et satisfaire les préférences de vos clients.</p>
          </div>
          <div>
          <img src={img2} style={imageStyle} alt="Logo" width="600px" height="400px" />
          </div>
        </section>

        <section className="image-paragraph-section">
          <div>
            <img src={img3} style={imageStyle} alt="Logo" width="600px" height="400px" />
          </div>
          <div className='text-droit'>
            <h3 className='paragraph' style={{color:'#17174F'}}>Pilotez votre Restaurant avec Précision</h3>
            <p className='paragraph' style={{color:'#525278'}}>Visualisez l'activité en temps réel grâce à notre Tableau de Bord intuitif. Découvrez qui est présent, quels plats sont commandés, et assurez-vous que chaque opération est orchestrée avec précision.</p>
          </div>
        </section>
      </section>
      {/* Produit - Plusieurs solutions */}
      

      {/* Avis en ligne */}
      <section className='reviews'>
        <h2 className='review-title'>Passez à l'étape supérieure </h2>
        <div className='button-middle'>
            <Button onClick={openRegister} size="small" variant="contained" sx={{background:"#17174F", ":hover":{backgroundColor:"#FF4500"}}} style={{ marginRight: '10px' }}>
            <span className='btnConnexion'>Essai gratuit</span> 
        </Button>
        <Button onClick={calendlyReservation}  sx={{color:"#17174F", borderColor :"#17174F", ":hover":{backgroundColor:"#FF4500;", borderColor:'#FF4500;', color:'white'},}} size="small" variant="outlined" >
        <span className='btnConnexion'>En savoir plus</span> 
        </Button>
        </div>
        <section className="reviews-section">
          {/* Trois avis en ligne, vous pouvez répéter cette structure pour chaque avis */}
          <div className="review">
          </div>
          {/* Répétez pour les deux autres avis */}
      </section>
      </section>
      
      </section>

      

      {/* Informations de pied de page */}
      <footer className="footer">
  <div className='footerDesign'>
    <div className="footer-links">
      <a href="#" onClick={openCGU}>CGU</a>
      <span>|</span>
      <a href="#" onClick={openPP}>Politique de confidentialité</a>
      <span>|</span>
      <a href="#contact" onClick={contactUs}>Nous contacter</a>
    </div>
    <p className='btnConnexion'>Informations de contact, copyright © Yumz 2024 </p>
  </div>
  </footer>

      {isModalOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick} >
          {register == 1 || register == 2 ? <div className="modal-content">
          {register==1?<Register />:null}
          {register == 2 ? <SignIn navigate={navigate}/> : null}
          </div>:
          <div className="centered-container-footer">
        <div className="container-footer">
        <IconButton
              aria-label="close"
              onClick={closeModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
            >
              <CloseIcon />
          </IconButton>
        {register == 3 ? <CGU />: null}
        {register == 4 ? <PrivacyPolicy />: null}
        {register == 5 ? <ContactUs/>:null}
        {register == 6 ? <Pricing/>:null}
        </div>
  </div> 
          }
        </div>
      )}
      </div>
    </div>
  );
};

export default HomePage;
