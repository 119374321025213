import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react'; // Importer le composant QRCode correctement
import Button from '@mui/material/Button';
import client from '../../../api/client';
import {
    UilDownloadAlt
} from "@iconscout/react-unicons";

const QRCodeGenerator = () => {
  const [urls, setUrls] = useState({});
  const [logo, setLogo] = useState(null)

  useEffect(() => {
    const fetch = async () => {
      const token = JSON.parse(localStorage.getItem('token'));
      await client.post('get-restaurant', { token }, {
          headers: {
            Accept: 'application/json',
            authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          if (res.data.success) {
            const restaurant = res.data.restaurant;
            const newUrls = {};
            for (let i = 1; i <= restaurant.tables?.length; i++) {
              newUrls[i] = `https://yumz-client.com/restaurant/${restaurant._id}/${i}`;
            }
            setUrls(newUrls);
          }
        });
    };
    fetch();
  }, []);

  const handleDownloadAll = () => {
    // Logique pour télécharger tous les QR codes
    // Crée un lien de téléchargement pour chaque QR code et les déclenche
    Object.entries(urls).forEach(([index, url]) => {
      const qrCodeCanvas = document.getElementById(`qr-code-${index}`)
      const qrCodeImage = qrCodeCanvas.toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
      const downloadLink = document.createElement('a');
      downloadLink.href = qrCodeImage;
      downloadLink.download = `qr-code-${index}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  };

  const handleDownloadSingle = (index) => {
    const qrCodeCanvas = document.getElementById(`qr-code-${index}`)
    const qrCodeImage = qrCodeCanvas.toDataURL("image/png")
    .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement('a');
    downloadLink.href = qrCodeImage;
    downloadLink.download = `qr-code-${index}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center',}}>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center',}}>
        {Object.entries(urls).map(([index, url]) => (
        <div key={index} style={{ margin: '10px', textAlign: 'center' }}>
          <div>Table {index}</div>
          <Button onClick={() => handleDownloadSingle(index)} style={{ marginBottom: '10px' }}>          
            {logo ? ( // Si un logo est défini, utilisez-le comme arrière-plan
              <QRCode
                id={`qr-code-${index}`}
                value={url}
                size={50} // Taille du QR code
                qrStyle={{ backgroundColor: 'transparent' }}
                imageSettings={{
                  src: logo,
                  height: 50, // Hauteur de l'image
                  width: 50, // Largeur de l'image
                  excavate: true // Retirer les coins pour intégrer l'image dans le QR code
                }}
              />
            ) : ( // Sinon, utilisez simplement le QR code sans arrière-plan
            <QRCode id={`qr-code-${index}`} value={url} size={50} qrStyle={{ backgroundColor: 'transparent' }} />

            )}
          </Button>
        </div>
      ))}
      </div>
      <div style={{ marginTop: '20px', left:'50%' }}>
        <Button variant="contained" onClick={handleDownloadAll}>Télécharger tous</Button>
      </div>
    </div>
  );
};

export default QRCodeGenerator;
