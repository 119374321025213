import React, {useState, useEffect} from "react";
import CreditCardForm from "./Payment";
import client from "../../../api/client";
import { currencies } from "../../../actions/currencies";
function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
export default function Subscribe({price, date}){
    console.log("price....",currencies().symbol)
    return(
        <div>
        <h2 style={{textAlign:'center'}} >Mettre à niveau mon abonnement mensuel</h2>
        <p style={{textAlign:'center'}}>Confirmez votre abonnement mensuel pour continuer à profiter de notre service ✨</p>
        <div style={{display:'flex', justifyContent:'space-between', marginBottom:'5%'}}>
        <p>Abonnement</p>
        <p>{price} <span style={{fontSize:15}}>€</span></p>
        </div>
        <CreditCardForm />
        </div>
        
    )
}